import { FC } from "react";
import { Tooltip } from "antd";
import { ISection } from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../../store";

const AddStageCTA: FC<{
  sectionDetails: ISection;
}> = ({ sectionDetails }) => {
  const { sections, setSections } = useTemplateStore();

  const addNewStage = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              stage_details: eachSection.grid_list_details?.stage_details
                ? [
                    ...eachSection.grid_list_details.stage_details,
                    {
                      stage_id: 99,
                      stage_name: "",
                      stage_rank: 99,
                      section_id: +sectionDetails.section_id,
                      isNew: true,
                    },
                  ]
                : [
                    {
                      stage_id: 99,
                      stage_name: "",
                      stage_rank: 99,
                      section_id: +sectionDetails.section_id,
                      isNew: true,
                    },
                  ],
            },
          };
        }
        return eachSection;
      })
    );
  };

  return (
    <div className="stageItemLast">
      <div className="addItem item1">
        <Tooltip title="Add new stage" color={"#2E364C"} placement="right">
          <a href={void 0} aria-label="add task" onClick={() => addNewStage()}>
            <span className="addplus"></span>
          </a>
        </Tooltip>
      </div>
    </div>
  );
};

export default AddStageCTA;
