import { RoutesEnum } from "../../models/enums/apiRoutes";
import apiService from "../Api.service";
import {
  ApiAddNewSection,
  ApiAddNewTask,
  ApiAllMasterData,
  ApiAllTemplateData,
  ApiCreateNewGridStage,
  ApiCreateNewGridTask,
  ApiMessageResponse,
  ApiReorderGridStage,
  ApiReorderGridTask,
  ApiSaveTemplateAsDraft,
  ApiUpdateGridBlock,
  ApiUpdateGridStage,
  ApiUpdateGridTask,
  ApiUploadThumbnail,
  ISaveTemplateAsDraft,
  ICreateTemplate,
  ApiTemplateData,
} from "modules/models/interfaces/template.interface";

class TemplateService {
  public getAllMasterData = async () => {
    return apiService.get<ApiAllMasterData>(RoutesEnum.ALL_MASTER_DATA);
  };

  public saveTemplate = async (data: ISaveTemplateAsDraft) => {
    return apiService.post<ApiSaveTemplateAsDraft>(
      RoutesEnum.SAVE_TEMPLATE,
      data
    );
  };

  public uploadThumbnail = async (data: {
    dataURL: string;
    template_name: string;
  }) => {
    return apiService.post<ApiUploadThumbnail>(
      RoutesEnum.UPLOAD_THUMBNAIL,
      data
    );
  };

  public getAllTemplates = async () => {
    return apiService.get<ApiAllTemplateData>(RoutesEnum.GET_ALL_TEMPLATES);
  };

  public createNewSection = async (data: any) => {
    return apiService.post<ApiAddNewSection>(RoutesEnum.ADD_NEW_SECTION, data);
  };

  public updateSection = async (data: any) => {
    return apiService.post<ApiMessageResponse>(RoutesEnum.UPDATE_SECTION, data);
  };

  public createNewTask = async (data: any) => {
    return apiService.post<ApiAddNewTask>(RoutesEnum.ADD_NEW_TASK, data);
  };

  public updateTask = async (data: any) => {
    return apiService.post<ApiMessageResponse>(RoutesEnum.UPDATE_TASK, data);
  };

  public reOrderGridTask = async (data: any) => {
    return apiService.post<ApiReorderGridTask>(
      RoutesEnum.REORDER_GRID_TASK,
      data
    );
  };

  public reOrderGridStage = async (data: any) => {
    return apiService.post<ApiReorderGridStage>(
      RoutesEnum.REORDER_GRID_STAGE,
      data
    );
  };

  public createNewGridStage = async (data: any) => {
    return apiService.post<ApiCreateNewGridStage>(
      RoutesEnum.ADD_NEW_GRID_STAGE,
      data
    );
  };

  public createNewGridTask = async (data: any) => {
    return apiService.post<ApiCreateNewGridTask>(
      RoutesEnum.ADD_NEW_GRID_TASK,
      data
    );
  };

  public updateGridStage = async (data: any) => {
    return apiService.post<ApiUpdateGridStage>(
      RoutesEnum.UPDATE_GRID_STAGE,
      data
    );
  };

  public updateGridTask = async (data: any) => {
    return apiService.post<ApiUpdateGridTask>(
      RoutesEnum.UPDATE_GRID_TASK,
      data
    );
  };

  public updateGridBlock = async (data: any) => {
    return apiService.post<ApiUpdateGridBlock>(
      RoutesEnum.UPDATE_GRID_BLOCK,
      data
    );
  };

  public deleteGridStage = async (data: any) => {
    return apiService.post<ApiMessageResponse>(
      RoutesEnum.DELETE_GRID_STAGE,
      data
    );
  };

  public deleteGridTask = async (data: any) => {
    return apiService.post<ApiMessageResponse>(
      RoutesEnum.DELETE_GRID_TASK,
      data
    );
  };

  public isUniqueTemplateName = async (data: { template_name: string }) => {
    return apiService.get<ApiMessageResponse>(
      RoutesEnum.IS_UNIQUE_TEMPLATE_NAME,
      data
    );
  };

  public changeStatusPublishTemplate = async (data: {
    template_id: number;
    is_active: boolean;
  }) => {
    return apiService.post<ApiMessageResponse>(
      RoutesEnum.CHANGE_STATUS_PUBLISH_TEMPLATE,
      data
    );
  };

  public deleteTemplate = async (data: {
    template_id: number;
    is_delete: boolean;
  }) => {
    return apiService.post<ApiMessageResponse>(
      RoutesEnum.DELETE_TEMPLATE,
      data
    );
  };

  public getTemplateById = async (template_id: number) => {
    return apiService.get<ApiTemplateData>(
      `${RoutesEnum.GET_TEMPLATE_BY_ID}?template_id=${template_id}`
    );
  };

  public getWebsiteMeta = async (url: string) => {
    return apiService.get<any>(
      RoutesEnum.GET_WEBSITE_METADATA,
      {url},
  )
  };
}

const templateService = new TemplateService();
export default templateService;
