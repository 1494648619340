import { FC, Fragment } from "react";
import { useRbac, RBAC } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { useTemplateStore } from "../../../store";
import { ISection } from "modules/models/interfaces/template.interface";

const EmptyStage: FC<{
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  setStageName: React.Dispatch<React.SetStateAction<string>>;
  stageName: string;
  sectionDetails: ISection;
}> = ({ isEdit, setIsEdit, submitChange, stageName, setStageName, sectionDetails }) => {
  const { hasPermissions } = useRbac();
  const { sections, setSections } = useTemplateStore();
  const addNewStage = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              stage_details: eachSection.grid_list_details?.stage_details
                ? [
                    ...eachSection.grid_list_details.stage_details,
                    {
                      stage_id: 99,
                      stage_name: "",
                      stage_rank: 99,
                      section_id: +sectionDetails.section_id,
                      isNew: true,
                    },
                  ]
                : [
                    {
                      stage_id: 99,
                      stage_name: "",
                      stage_rank: 99,
                      section_id: +sectionDetails.section_id,
                      isNew: true,
                    },
                  ],
            },
          };
        }
        return eachSection;
      })
    );
  };

  return (
    <div className="stageItem">
      <div className="rightProgressHeader">
        <li
          className={"progressCardTop emptyStateAddStage"}
          onClick={() => addNewStage()}
          onKeyDown={() => {}}
        >
            <Fragment>
              <RBAC
                allowedPermissions={[
                  ERbacPermissions.TEMPLATE_VIEW,
                ]}
              >
                <div className="addItem item1">
                  <a
                    href={void 0}
                    aria-label="add task"
                    onClick={() => addNewStage()}
                  >
                    <span className="addplus"></span>
                  </a>
                </div>
              </RBAC>
              <h5>Add Stage</h5>
            </Fragment>
        </li>
      </div>
    </div>
  );
};

export default EmptyStage;
