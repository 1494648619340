import { FC, useEffect, useState } from "react";
import Sections from "./Sections";
import { useTemplateStore } from "../store";
import cryptoRandomString from "crypto-random-string";
import { SectionType, TemplateStep } from "modules/models/enums/templateEnums";
import templateService from "modules/services/TemplateService/Template.service";
import { MessageInstance } from "antd/es/message/interface";
import OutsideClickHandler from "modules/sharedComponents/components/OutsideClickHandler";
import { useNavigate } from "react-router-dom";

const createSectionOptions = [
  {
    label: "Task List",
    value: SectionType.TASKLIST,
    className: "cmnIcon manage-task",
  },
  {
    label: "Grid List",
    value: SectionType.GRIDLIST,
    className: "cmnIcon grid-list",
  },
  {
    label: "Memo Pad",
    value: SectionType.MEMOPAD,
    className: "cmnIcon memo-pad",
  },
];

const TemplateConfig: FC<{ messageApi: MessageInstance }> = ({
  messageApi,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { templateDetails, setStep, sections, setSections } = useTemplateStore();
  const navigate = useNavigate();

  const onDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    setSections(sections || templateDetails.sections || []);
  }, [templateDetails.template_id]);

  const handleSuccess = () => {
    messageApi.open({
      type: "success",
      content: "Template published successfully",
    });
  };

  const handleError = () => {
    messageApi.open({
      type: "error",
      content: "Some error occurred. Please try it again!",
    });
  };

  const getNewEmptySection = () => {
    return {
      section_id: cryptoRandomString({ length: 10 }),
      section_type_id: 1,
      section_name: "",
      section_rank: (sections?.length ?? 0) + 1,
      section_details: [
        {
          task_id: cryptoRandomString({ length: 5 }),
          task_rank: 1,
          task_name: "",
          due_date: "",
          assignee_dp: "",
          assignee_id: null,
          assignee_name: "",
          assignee_email: "",
          task_status_id: 1,
          task_priority_id: 2,
          task_status_name: "",
          task_priority_name: "",
        },
      ],
      task_list_view: "card",
    };
  };

  const newSectionCtaClickHandler = (selectedOption: string) => {
    switch (selectedOption) {
      case "task_list":
        if (!sections || sections?.length === 0) {
          setSections([{ ...getNewEmptySection() }]);
        } else {
          setSections([...sections, getNewEmptySection()]);
        }

        break;
      case "grid_list":
        if (!sections || sections?.length === 0) {
          setSections([
            {
              ...getNewEmptySection(),
              section_type_id: 2,
              grid_list_details: {
                task_details: null,
                stage_details: null,
                block_details: null,
              },
            },
          ]);
        } else {
          setSections([
            ...sections,
            {
              ...getNewEmptySection(),
              section_type_id: 2,
              grid_list_details: {
                task_details: null,
                stage_details: null,
                block_details: null,
              },
            },
          ]);
        }
        break;
      case "memo_pad":
        if (!sections || sections?.length === 0) {
          setSections([{ ...getNewEmptySection(), section_type_id: 3 }]);
        } else {
          setSections([
            ...sections,
            { ...getNewEmptySection(), section_type_id: 3 },
          ]);
        }
        break;
    }
    setShowDropdown(false);
  };

  const handlePublishTemplate = async () => {
    try {
      const response = await templateService.changeStatusPublishTemplate({
        template_id: templateDetails?.template_id,
        is_active: true,
      });
      if (response.messageId === 1) {
        handleSuccess();
        setTimeout(() => {
          navigate("../templates");
        }, 1000);
      } else {
        handleError();
      }
    } catch (error) {
      handleError();
      console.log(error);
    }
  };

  return (
    <>
      <div className="contentHeadingPanel">
        <div className="CNPScreen">
          <h2>
            Configure Template Components for {templateDetails.template_name}
          </h2>
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowDropdown(false);
            }}
          >
            <div className="contentHeadingRightPart">
              <button
                className="hdrDropdownToggle btnStyle1"
                type="button"
                data-toggle="dropdown"
                onClick={onDropdownClick}
              >
                New Section
                <span className="cmnIcon arrow-down-nav" />
              </button>
              <ul className={`hdrDropdownMenu ${showDropdown ? "show" : ""}`}>
                {createSectionOptions.map((eachOption) => (
                  <li
                    key={eachOption.label}
                    onClick={() => newSectionCtaClickHandler(eachOption.value)}
                  >
                    <div>
                      <span className={eachOption.className}></span>{" "}
                      {eachOption.label}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
      <Sections getNewEmptySection={getNewEmptySection} />
      <div className="newTemplateWrapper">
        <div
          className="btnPart multipleBtn"
          style={{ position: "unset", marginRight: "24px" }}
        >
          <div className="cancelCreate">
            <div
              className="noStyle"
              onClick={() => setStep(TemplateStep.CREATE)}
            >
              Back
            </div>
            <button
              className="btnStyle1"
              onClick={async (e) => {
                e.preventDefault();
                handlePublishTemplate();
              }}
            >
              Publish
            </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default TemplateConfig;
