import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import React, { Fragment } from "react";
import LeftNav from "../../Dashboard/LeftNav/LeftNav";
import InviteUserForm from "./InviteUserForm";
import { useStore } from './store';
import UserListTable from "./UserListTable";

interface EmailNameRoles {
    name: string;
    email: string;
    roles: string[];
    user_id: number;
    status: boolean;
    invite_accepted: boolean;
}

const UserManagement: React.FC<{}> = () => {
    const { isDrawer, setIsDrawer } = useStore();
    const { iuser, setIUser } = useStore();

    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };

    const blankUserData = {
        name: '',
        email: '',
        roles: [],
        user_id: 0,
        status: false,
        invite_accepted: false,
    }
    const editUserDrawer = (_email_name_roles: EmailNameRoles) => {
        setIsDrawer(!isDrawer);
        setIUser(_email_name_roles);

    };



    return (
        <RBAC allowedPermissions={[ERbacPermissions.USER_VIEW]}>
            <div className={`adminPanelMainWrapper mainWrapper ${isDrawer ? "body-overlay" : ""}`}>
                <LeftNav />
                <div className="adminPanelMainContent mainContent">
                    <Fragment>
                        <div className="contentHeadingPanel">
                            <h2>User Management</h2>
                        </div>
                        <div className="inner-container">
                            <div className="manageUsersTableHeading">
                                <span className="itemName"> </span>
                                <button className="btnStyle3" type="button" id="inviteUser" onClick={() => editUserDrawer(blankUserData)}>Invite User</button>
                            </div>
                            <div className="userManagementTable">

                                <UserListTable />

                            </div>

                        </div>

                        <div className={`addUserDrawerWrap createNewProjectForm ${isDrawer ? "addToggle" : ""}`} id="AdmincreateNewProjectForm1">
                            <div className="header">
                                {iuser.email ? (
                                    <h2>Edit User</h2>
                                ) : (
                                    <h2>Invite a user</h2>
                                )}
                                <span className="material-icons-outlined close" onClick={handleDrawer}>close</span>
                            </div>
                            <InviteUserForm />
                        </div>
                    </Fragment>
                </div>
            </div>
        </RBAC>
    )
}

export default withRbac(UserManagement, {allowedPermissions : [ERbacPermissions.USER_VIEW], isAccessFromPage: true});