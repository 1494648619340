import { message } from "antd";
import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { useTemplateStore } from "./store";
import LeftNav from "../../Dashboard/LeftNav/LeftNav";
import { TemplateStep } from "modules/models/enums/templateEnums";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import BasicInfo from "./BasicInfo/BasicInfo";
import TemplateConfig from "./Configuration/TemplateConfig";
import templateService from "modules/services/TemplateService/Template.service";
import {
  Industry,
  MasterData,
} from "modules/models/interfaces/template.interface";

const Template = () => {
  const step = useTemplateStore((state) => state.step);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    setTemplateDetails,
    setLoading,
    setStep,
    setLanguageOption,
    setCountryOption,
    setTeamOption,
    setIndustryOption,
    setOrganizationFunctionOption,
    setSections,
  } = useTemplateStore();
  const location = useLocation();

  const handleError = useCallback(() => {
    messageApi.open({
      type: "error",
      content: "Some error occurred. Please try it again!",
    });
  }, [messageApi]);

  const initializeDataCallback = useCallback(
    async (id: string) => {
      setLoading(true);
      setStep(TemplateStep.CREATE);

      let masterData: MasterData = {
        organization: [],
        languages: [],
        industries: [],
        countries: [],
        organization_functions: [],
      };

      try {
        const response = await templateService.getAllMasterData();
        masterData = response.data;
      } catch (err) {
        handleError();
        console.log(err);
      }

      const {
        organization,
        languages,
        industries,
        countries,
        organization_functions,
      } = masterData;

      setLanguageOption(
        languages.map((lang) => ({
          label: lang.language,
          value: lang.id,
        }))
      );

      setCountryOption(
        countries.map((country) => ({
          label: country.country,
          value: country.id,
        }))
      );

      setTeamOption([
        {
          label: "All Team",
          value: "all",
        },
        ...organization.map((organization) => ({
          label: organization.name,
          value: organization.key,
        })),
      ]);

      setIndustryOption(
        industries
          ?.sort((a: Industry, b: Industry) =>
            a.industry?.localeCompare(b.industry)
          )
          .map((industry) => ({
            label: industry.industry,
            value: industry.id,
          }))
      );

      setOrganizationFunctionOption(
        organization_functions.map((organizationFunction) => ({
          label: organizationFunction.organization_function,
          value: organizationFunction.id,
        }))
      );
      if (!isNaN(+id)) {
        try {
          const res = await templateService.getTemplateById(+id);
          if (res.messageId === 1) {
            setTemplateDetails(res.data);
            setSections(res.data.sections || []);
          } else {
            handleError();
          }
        } catch (error) {
          handleError();
          console.log(error);
        }
      }

      if (id === "create") {
        setTemplateDetails({
          template_id: 0,
          template_name: "",
          template_description: "",
          template_thumbnails: [],
          preview_url: "",
          is_active: false,
          part_of_all_organization: true,
          tags: [],
          teams: [],
          languages: [],
          industries: [],
          organization_functions: [],
          created_on: "",
          updated_on: "",
          sections: [],
          banner_title: "",
          banner_description: "",
          work_type: 0,
          banner: null,
        });

        setSections([]);
      }
      setLoading(false);
    },
    [
      setCountryOption,
      setIndustryOption,
      setLanguageOption,
      setLoading,
      setOrganizationFunctionOption,
      setTeamOption,
      setTemplateDetails,
      handleError,
      setSections,
      setStep
    ]
  );

  useEffect(() => {
    let isCanceled = false;
    const id = location.pathname.split("/").pop() as string;

    if (!isCanceled) {
      initializeDataCallback(id);
    }

    return () => {
      isCanceled = true;
    };
  }, []);

  return (
    <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
      {contextHolder}
      <div className={`adminPanelMainWrapper mainWrapper `}>
        <LeftNav />
        <div className="adminPanelMainContent mainContent templateDetailPage addtaskComponent">
          {step === TemplateStep.CREATE && (
            <BasicInfo messageApi={messageApi} id={location.pathname.split("/").pop() as string} />
          )}
          {step === TemplateStep.CONFIGURE && (
            <TemplateConfig messageApi={messageApi} />
          )}
        </div>
      </div>
    </RBAC>
  );
};

export default withRbac(Template, {
  allowedPermissions: [ERbacPermissions.TEMPLATE_VIEW],
  isAccessFromPage: true,
});
