import { FC } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { message } from "antd";
import TaskListWrapper from "./Task/TaskListWrapper";
import StageListWrapper from "./Stage/StageListWrapper";
import BlockWrapper from "./Block/BlockWrapper";
import templateService from "modules/services/TemplateService/Template.service";
import { useTemplateStore } from "../../store";
import {
  ISection,
  IStageDetail,
  ITaskDetail,
} from "modules/models/interfaces/template.interface";

const GridListDetails: FC<{
  sectionDetails: ISection;
  sectionToggleState: "collapsed" | "expanded";
}> = ({ sectionDetails, sectionToggleState }) => {
  const { templateDetails } = useTemplateStore();
  const { sections, setSections } = useTemplateStore();

  const checkStageList = (sectionDetails: ISection) => {
    if (!sectionDetails.grid_list_details) {
      return false;
    } else {
      if (sectionDetails?.grid_list_details?.stage_details === null) {
        return false;
      }
      return true;
    }
  };

  const reOrderTask = async (
    sourceTaskId: number,
    destinationTaskId: number
  ) => {
    try {
      let response = await templateService.reOrderGridTask({
        template_id: templateDetails?.template_id!,
        section_id: +sectionDetails.section_id,
        source_task_id: sourceTaskId,
        destination_task_id: destinationTaskId,
      });

      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                task_details: response.data.grid_list_details.task_details,
                block_details: response.data.grid_list_details.block_details,
              },
            };
          }
          return eachSection;
        })
      );
    } catch (err) {
      message.error("Error", 3);
    }
  };

  const reOrderStage = async (
    sourceStageId: number,
    destinationStageId: number
  ) => {
    try {
      let response = await templateService.reOrderGridStage({
        template_id: templateDetails?.template_id!,
        section_id: +sectionDetails.section_id,
        source_stage_id: sourceStageId,
        destination_stage_id: destinationStageId,
      });

      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                stage_details: response.data.grid_list_details.stage_details,
                block_details: response.data.grid_list_details.block_details,
              },
            };
          }
          return eachSection;
        })
      );
    } catch (err) {
      message.error("Error", 3);
    }
  };

  const onDragEndTask = (result: DropResult) => {
    let source = result.source;
    let destination = result.destination!;

    if (source.index !== destination.index) {
      let sourceTask =
        sectionDetails.grid_list_details?.task_details![source.index];

      let destinationTask =
        sectionDetails.grid_list_details?.task_details![destination.index];

      let otherTaskList =
        sectionDetails.grid_list_details?.task_details?.filter(
          (ele: ITaskDetail) => ele.task_id !== sourceTask?.task_id
        );

      otherTaskList?.splice(
        destination.index,
        0,
        sourceTask || {
          task_id: 0,
          task_name: "",
          task_rank: 0,
          section_id: 0,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
          isEdit: false,
          backupName: "",
        }
      );

      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                task_details: otherTaskList!,
              },
            };
          }
          return eachSection;
        })
      );

      (async () => {
        await reOrderTask(
          sourceTask?.task_id || 0,
          destinationTask?.task_id || 0
        );
      })();
    }
  };

  const onDragEndStage = (result: DropResult) => {
    let source = result.source;
    let destination = result.destination!;

    if (source.index !== destination.index) {
      let sourceStage =
        sectionDetails.grid_list_details?.stage_details![source.index];

      let destinationStage =
        sectionDetails.grid_list_details?.stage_details![destination.index];

      let otherStageList =
        sectionDetails.grid_list_details?.stage_details?.filter(
          (ele: IStageDetail) => ele.stage_id !== sourceStage?.stage_id
        );

      otherStageList?.splice(
        destination.index,
        0,
        sourceStage || {
          stage_id: 0,
          section_id: 0,
          stage_name: "",
          stage_rank: 0,
          isNew: true,
          isEdit: false,
          backupName: "",
        }
      );

      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                stage_details: otherStageList!,
              },
            };
          }
          return eachSection;
        })
      );

      (async () => {
        await reOrderStage(
          sourceStage?.stage_id || 0,
          destinationStage?.stage_id || 0
        );
      })();
    }
  };

  return (
    <div
      className={`gridWrapperWhole ${
        sectionToggleState === "collapsed" ? "toggle-tasktable" : ""
      }`}
    >
      <div
        className={`gridWrapper ${
          !checkStageList(sectionDetails) ? "emptyStateGrid" : ""
        }`}
      >
        <div className="stagesWrapper">
          <div className="stageHeader">
            <p className="taskheading">TASKS</p>
            <p className="stageheading">STAGES</p>
          </div>

          <DragDropContext onDragEnd={onDragEndTask}>
            <Droppable droppableId="droppableTask" direction="vertical">
              {(provided: any) => {
                return (
                  <ul
                    className="taskInnerWrap"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TaskListWrapper sectionDetails={sectionDetails} />
                    {provided.placeholder}
                  </ul>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="taskOuterWrap">
          <DragDropContext onDragEnd={onDragEndStage}>
            <Droppable droppableId="droppableStage" direction="horizontal">
              {(provided: any) => {
                return (
                  <ul
                    className="stageItemList"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <StageListWrapper
                      sectionDetails={sectionDetails}
                      checkStageList={checkStageList}
                    />
                  </ul>
                );
              }}
            </Droppable>
          </DragDropContext>
          <div className="subtaskWrapperWhole">
            <BlockWrapper sectionDetails={sectionDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridListDetails;
