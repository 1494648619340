import { FC, useState } from "react";
import EmptyTask from "./EmptyTask";
import TaskList from "./TaskList";
import { useTemplateStore } from "../../../store";
import templateService from "modules/services/TemplateService/Template.service";
import { eventTypes } from "modules/models/enums/templateEnums";
import {
  IBlockDetail,
  ISection,
  ITaskDetail,
} from "modules/models/interfaces/template.interface";

const TaskListWrapper: FC<{
  sectionDetails: ISection;
}> = ({ sectionDetails }) => {
  const { sections, setSections } = useTemplateStore();
  const { templateDetails } = useTemplateStore();

  const [isTaskEdit, setIsTaskEdit] = useState<boolean>(false);
  const [taskName, setTaskName] = useState<string>("");

  const checkTaskList = (sectionDetails: ISection) => {
    if (!sectionDetails.grid_list_details) {
      return false;
    } else {
      if (sectionDetails?.grid_list_details?.task_details === null) {
        return false;
      }
      return true;
    }
  };

  const submitChange = async (taskName: string, eventType: string) => {
    if (templateDetails?.template_id) {
      if (taskName.trim() === "") {
        setTaskName("");

        if (checkTaskList(sectionDetails)) {
          removeEmptyTask();
        } else {
          setIsTaskEdit(false);
        }
      } else {
        try {
          const response = await templateService.createNewGridTask({
            template_id: templateDetails?.template_id,
            section_id: sectionDetails.section_id,
            task_name: taskName.trim(),
          });

          if (response.messageId === 1) {
            updateProjectState(response.data, eventType);
            setIsTaskEdit(false);
            setTaskName("");
          } else {
            handleFailedOperation();
          }
        } catch (error) {
          setTaskName("");
          setIsTaskEdit(false);
        }
      }
    }
  };

  const updateTask = async (taskName: string, taskId: number) => {
    if (templateDetails?.template_id) {
      try {
        const response = await templateService.updateGridTask({
          template_id: templateDetails?.template_id,
          section_id: sectionDetails.section_id,
          task_id: taskId,
          task_name: taskName.trim(),
        });

        if (response.messageId === 1) {
          updateProjectStateForTaskUpdate(response.data);
        } else {
          revertChange(taskId, "Blur");
        }
      } catch (error) {
        revertChange(taskId, "Blur");
      }
    }
  };

  const updateProjectState = (
    data: {
      new_task_details: ITaskDetail[];
      new_block_details: IBlockDetail[];
    },
    eventType: string
  ) => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return generateGridListDetails(eachSection, eventType, data);
        }
        return eachSection;
      })
    );
  };

  const generateGridListDetails = (
    eachSection: ISection,
    eventType: string,
    data: {
      new_task_details: ITaskDetail[];
      new_block_details: IBlockDetail[];
    }
  ) => {
    if (eachSection.grid_list_details?.task_details) {
      return {
        ...eachSection,
        grid_list_details: {
          ...eachSection.grid_list_details,
          task_details:
            eachSection.grid_list_details.task_details !== null
              ? taskListBasedOnEvent(eachSection, eventType, data)
              : data.new_task_details,
          block_details:
            eachSection.grid_list_details.block_details !== null &&
            eachSection.grid_list_details.block_details !== undefined
              ? ([
                  ...eachSection.grid_list_details.block_details,
                  ...data.new_block_details,
                ] as Array<IBlockDetail[]>)
              : data.new_block_details,
          stage_details: eachSection.grid_list_details?.stage_details
            ? eachSection.grid_list_details?.stage_details
            : null,
        },
      };
    } else {
      return {
        ...eachSection,
        grid_list_details: {
          task_details: taskListBasedOnEvent(eachSection, eventType, data),
          block_details: data.new_block_details,
          stage_details: eachSection.grid_list_details?.stage_details
            ? eachSection.grid_list_details?.stage_details
            : null,
        },
      };
    }
  };

  const updateProjectStateForTaskUpdate = (data: {
    task_details: ITaskDetail[];
  }) => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              task_details: getUpdateTaskDetails(
                eachSection,
                data.task_details
              )!,
            },
          };
        }
        return eachSection;
      })
    );
  };

  const taskListBasedOnEvent = (
    eachSection: ISection,
    eventType: string,
    data: {
      new_task_details: ITaskDetail[];
      new_block_details: IBlockDetail[];
    }
  ) => {
    let taskList: any[] = [];

    if (eachSection.grid_list_details?.task_details) {
      if (eventType === eventTypes.ENTER) {
        taskList = [
          ...eachSection.grid_list_details.task_details,
          ...data.new_task_details,
        ].filter((ele) => !ele.isNew);
        taskList.push({
          task_id: 99,
          task_name: "",
          task_rank: 99,
          section_id: 99,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
        });
      } else {
        taskList = [
          ...eachSection.grid_list_details.task_details,
          ...data.new_task_details,
        ].filter((ele) => !ele.isNew);
      }
    } else {
      if (eventType === eventTypes.ENTER) {
        taskList = [...data.new_task_details];
        taskList.push({
          task_id: 99,
          task_name: "",
          task_rank: 99,
          section_id: 99,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
        });
      } else {
        taskList = [...data.new_task_details];
      }
    }

    return taskList;
  };

  const removeEmptyTask = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              task_details: eachSection.grid_list_details?.task_details
                ? filterOutTask(eachSection.grid_list_details.task_details)
                : null,
            },
          };
        }
        return eachSection;
      })
    );
  };

  const filterOutTask = (taskDetails: ITaskDetail[]) => {
    let filteredList = taskDetails.filter((ele: any) => !ele.isNew);

    return filteredList.length > 0 ? filteredList : null;
  };

  const handleFailedOperation = () => {
    if (checkTaskList(sectionDetails)) {
      removeEmptyTask();
    }
  };

  const revertChange = (taskId: number, event: string) => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          let target = eachSection.grid_list_details?.task_details?.filter(
            (ele) => ele.task_id === taskId
          )!;
          let others = eachSection.grid_list_details?.task_details?.filter(
            (ele) => ele.task_id !== taskId
          )!;

          target[0].task_name = target[0].backupName!;
          if (event === eventTypes.BLUR) delete target[0].backupName;

          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              task_details: [...others, ...target].sort(
                (a, b) => a.task_rank - b.task_rank
              ),
            },
          };
        }
        return eachSection;
      })
    );
  };

  const getUpdateTaskDetails = (
    section: ISection,
    taskDetails: ITaskDetail[]
  ) => {
    if (section.grid_list_details?.task_details) {
      let task = [...taskDetails];
      let existingTask = section.grid_list_details.task_details.filter(
        (ele: ITaskDetail) => ele.task_id !== task[0].task_id
      );
      return [...existingTask, ...task].sort(
        (a, b) => a.task_rank - b.task_rank
      );
    }
  };

  const getTaskListBodyJsx = () => {
    return checkTaskList(sectionDetails) ? (
      <TaskList
        sectionDetails={sectionDetails}
        taskName={taskName}
        setTaskName={setTaskName}
        submitChange={async (stageName, eventType) =>
          await submitChange(stageName, eventType)
        }
        updateTask={async (taskName, taskId) =>
          await updateTask(taskName, taskId)
        }
        revertChange={revertChange}
      />
    ) : (
      <EmptyTask
        isEdit={isTaskEdit}
        setIsEdit={setIsTaskEdit}
        taskName={taskName}
        setTaskName={setTaskName}
        submitChange={async (stageName, eventType) =>
          await submitChange(stageName, eventType)
        }
        sectionDetails={sectionDetails}
      />
    );
  };

  return getTaskListBodyJsx();
};

export default TaskListWrapper;
