import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import cryptoRandomString from "crypto-random-string";

export const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: any) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.split("?")[2], label: item.split("?")[0] });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: (event: any) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items mentionedPersonInfo">
      {props.items.length ? (
        props.items.map((item: any, index: number) => (
          <button
            className={`item ${index === selectedIndex ? "is-selected" : ""}`}
            key={`item_${cryptoRandomString({ length: 5 })}`}
            onClick={() => selectItem(index)}
          >
            {item.split("?")[0]}
            <span className="mentionEmail">{`(${item.split("?")[1]})`}</span>
          </button>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
