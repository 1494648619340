import { FC } from "react";
import EmptyBlock from "./EmptyBlock";
import Block from "./Block";
import { ISection } from "modules/models/interfaces/template.interface";

const BlockWrapper: FC<{
  sectionDetails: ISection;
}> = ({ sectionDetails }) => {
  const checkTaskStageList = (sectionDetails: ISection) => {
    if (
      sectionDetails?.grid_list_details?.task_details?.filter(
        (item) => !item.task_name
      )?.length &&
      sectionDetails?.grid_list_details?.stage_details?.filter(
        (item) => !item.stage_name
      )?.length
    ) {
      return false;
    }
    return true;
  };

  return checkTaskStageList(sectionDetails) ? (
    <Block sectionDetails={sectionDetails} />
  ) : null;
};

export default BlockWrapper;
