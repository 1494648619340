import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import cryptoRandomString from "crypto-random-string";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { RBAC } from "auth/rbac/rbac";
import { useTemplateStore } from "../../store";
import templateService from "modules/services/TemplateService/Template.service";
import {
  ISection,
  ISectionDetail,
  IUpdatedTasks,
} from "modules/models/interfaces/template.interface";
import TaskDueDate from "./TaskDueDate";
import TaskAssignee from "./TaskAssignee";
import TaskPriority from "./TaskPriority";
import TaskStatus from "./TaskStatus";
import MessageThread from "../MessageThread";
import { SectionTypeMessages } from "modules/models/enums/messages";

const Task: React.FC<{
  eachTaskDetail: ISectionDetail;
  sectionId: string | number;
  section?: ISection;
}> = ({ eachTaskDetail, sectionId, section }) => {
  const [taskName, setTaskName] = useState(eachTaskDetail.task_name);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { sections, setSections } = useTemplateStore();
  const { templateDetails } = useTemplateStore();

  const taskNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.target?.value;
    setTaskName(value);
  };

  const removeBlankNewTask = () => {
    setSections(
      sections?.map((eachSection: any) => {
        if (
          eachSection.section_id === sectionId &&
          eachSection.section_details?.length &&
          eachSection.section_details?.length > 1
        ) {
          return {
            ...eachSection,
            section_details: eachSection.section_details.filter(
              (eachTask: any) => eachTask.task_name !== ""
            ),
          };
        }
        return eachSection;
      })
    );
  };

  const saveNewTask = async (taskId: string) => {
    if (templateDetails?.template_id && typeof sectionId === "number") {
      try {
        const response = await templateService.createNewTask({
          template_id: templateDetails.template_id,
          section_id: sectionId,
          task_name: taskName?.trim() ?? "",
          task_rank: eachTaskDetail?.task_rank ?? 999,
          task_status_id: 1,
          task_priority_id: 2,
        });
        if (response.messageId === 1) {
          setSections(
            sections?.map((eachSection: any) => {
              if (
                eachSection.section_id === sectionId &&
                eachSection.section_details?.length
              ) {
                return {
                  ...eachSection,
                  section_details: eachSection.section_details.map(
                    (eachTask: any) => {
                      if (eachTask.task_id === taskId) {
                        eachTask.task_name = taskName?.trim();
                        eachTask.task_id = response.data.task_id;
                      }
                      return eachTask;
                    }
                  ),
                };
              }
              return eachSection;
            })
          );
        }
      } catch (e) {
        removeBlankNewTask();
        message.error("Error", 3);
      }
    }
  };

  const updateExistingTask = async (taskData: IUpdatedTasks[]) => {
    if (templateDetails?.template_id && typeof sectionId === "number") {
      try {
        if (taskData[0].is_deleted === true) {
          setSections(
            sections?.map((eachSection: any) => {
              if (
                eachSection.section_id === sectionId &&
                eachSection.section_details?.length
              ) {
                const _eachSection = {
                  ...eachSection,
                  section_details: eachSection.section_details.filter(
                    (eachTask: any) => eachTask.task_id !== taskData[0].task_id
                  ),
                };
                if (_eachSection.section_details.length === 0) {
                  let newTask = {
                    task_id: cryptoRandomString({ length: 5 }),
                    task_rank: 1,
                    task_name: "",
                    due_date: "",
                    assignee_dp: "",
                    assignee_id: null,
                    assignee_name: "",
                    assignee_email: "",
                    task_status_id: 1,
                    task_priority_id: 2,
                    task_status_name: "",
                    task_priority_name: "",
                  };
                  _eachSection.section_details?.push(newTask);
                }
                return _eachSection;
              }
              return eachSection;
            })
          );
        } else {
          setSections(
            sections?.map((eachSection: any) => {
              if (
                eachSection.section_id === sectionId &&
                eachSection.section_details?.length
              ) {
                return {
                  ...eachSection,
                  section_details: eachSection.section_details.map(
                    (eachTask: any) => {
                      const taskDatum = taskData.find(
                        (eachTaskDatum) =>
                          eachTaskDatum.task_id === eachTask.task_id
                      );
                      if (eachTask.task_id === taskDatum?.task_id) {
                        return {
                          ...eachTask,
                          ...taskDatum,
                          assignee_dp: undefined,
                          assignee_id: taskDatum?.assignee_id,
                          assignee_email: undefined,
                          assignee_name: undefined,
                        };
                      }
                      return eachTask;
                    }
                  ),
                };
              }
              return eachSection;
            })
          );
        }
        const response = await templateService.updateTask({
          template_id: templateDetails.template_id,
          section_id: sectionId,
          section_details: taskData,
        });
      } catch (error) {
        console.log(error);
        message.error("Error", 3);
      }
    }
  };

  const addNewTaskOnEnter = (
    taskId: string | number,
    event?: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event?.key === "Enter") {
      setTaskName((prev: any) => prev?.trim());
      const task_id = cryptoRandomString({ length: 5 });
      let section = sections?.find((ele: any) => ele.section_id === sectionId);
      if (
        section?.section_details &&
        section.section_details.findIndex(
          (eachTask: any) => eachTask.task_id === taskId
        ) ===
          section.section_details.length - 1 &&
        section.section_details.length > 0
      ) {
        let max = section.section_details?.reduce(function (
          prev: any,
          current: any
        ) {
          return prev?.task_rank > current?.task_rank ? prev : current;
        }).task_rank;
        let newTask = {
          task_id: task_id,
          task_rank: max + 1,
          task_name: "",
          due_date: "",
          assignee_dp: "",
          assignee_id: null,
          assignee_name: "",
          assignee_email: "",
          task_status_id: 1,
          task_priority_id: 2,
          task_status_name: "",
          task_priority_name: "",
        };
        section.section_details?.push(newTask);
        setSections(
          sections.map((eachSection) => {
            if (eachSection.section_id === sectionId) {
              return section || eachSection;
            }
            return eachSection;
          })
        );
      } else {
        event.currentTarget.blur();
      }
    }
  };

  const taskNameOnBlurHandler = (
    taskId: string | number,
    event?: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    setTaskName((prev: any) => prev?.trim());
    const oldTask = sections
      ?.find((eachSection: any) => eachSection.section_id === sectionId)
      ?.section_details?.find((eachTask: any) => eachTask.task_id === taskId);
    if (typeof taskId === "string" && taskName === "") {
      removeBlankNewTask();
    } else if (typeof taskId === "number" && taskName === "" && oldTask) {
      setTaskName(oldTask.task_name);
    } else if (
      typeof taskId === "string" &&
      taskName !== "" &&
      event?.target.value
    ) {
      saveNewTask(taskId);
    } else if (
      typeof taskId === "number" &&
      oldTask &&
      oldTask?.task_name !== taskName
    ) {
      updateExistingTask([
        { ...eachTaskDetail, task_id: taskId, task_name: taskName },
      ]);
    }
    setIsEditMode(false);
  };

  const deleteTaskHandler = () => {
    if (typeof eachTaskDetail.task_id === "number") {
      updateExistingTask([
        {
          ...eachTaskDetail,
          task_id: eachTaskDetail.task_id,
          is_deleted: true,
        },
      ]);
    }
  };

  const renderTaskName = () => {
    if (!isEditMode) {
      return (
        <div
          className="customDefaultTaskName"
          onClick={() => setIsEditMode(true)}
        >
          <span className="simpleTaskListTaskName">{taskName}</span>
        </div>
      );
    }
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div>
          <input
            ref={inputRef}
            type="text"
            id={`text-input-${sectionId}-${eachTaskDetail.task_id?.toString()}`}
            className="text-input-box"
            placeholder="Give your task a name"
            value={taskName}
            aria-label="task name"
            onChange={taskNameChangeHandler}
            onBlur={(e) => {
              taskNameOnBlurHandler(eachTaskDetail.task_id, e);
            }}
            onKeyDown={(e) => addNewTaskOnEnter(eachTaskDetail.task_id, e)}
            maxLength={255}
          />
        </div>
      </form>
    );
  };

  useEffect(() => {
    if (isEditMode) {
      inputRef.current?.focus();
    }
  }, [isEditMode]);

  const getEachTaskJsx = () => {
    if (typeof eachTaskDetail.task_id === "number") {
      return (
        <tr className="not-empty">
          <TaskStatus
            eachTaskDetail={eachTaskDetail}
            taskStatusChangeHandler={() => {}}
            section={section}
          />
          <td className="withMsg">
            <TaskPriority
              eachTaskDetail={eachTaskDetail}
              taskPriorityChangeHandler={() => {}}
            />
            {renderTaskName()}
            <MessageThread
              sectionId={sectionId}
              taskId={eachTaskDetail.task_id}
              sectionType={SectionTypeMessages.SIMPLE_LIST_TASK}
              threadName={eachTaskDetail.task_name!}
              sectionName={section!.section_name}
              taskDetails={eachTaskDetail}
            />
          </td>
          <TaskDueDate
            eachTaskDetail={eachTaskDetail}
            taskDueDateChangeHandler={() => {}}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
          />
          <TaskAssignee
            assigneeDetails={{
              assignee_id: eachTaskDetail.assignee_id,
              assignee_name: eachTaskDetail.assignee_name,
              assignee_email: eachTaskDetail.assignee_email,
              assignee_dp: eachTaskDetail.assignee_dp,
            }}
            taskAssigneeChangeHandler={() => {}}
            showAssigneeDropdown={showAssigneeDropdown}
            setShowAssigneeDropdown={setShowAssigneeDropdown}
          />
          <td>
            <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
              <div className="taskDeleteCta">
                <span
                  className="cmnIcon deleteBin"
                  onClick={() => deleteTaskHandler()}
                />
              </div>
            </RBAC>
          </td>
        </tr>
      );
    } else if (typeof eachTaskDetail.task_id === "string") {
      return (
        <tr className="not-empty">
          <TaskStatus taskStatusChangeHandler={() => {}} />
          <td>
            <TaskPriority taskPriorityChangeHandler={() => {}} />
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                autoFocus
                type="text"
                id={`text-input-${sectionId}-${eachTaskDetail.task_id?.toString()}`}
                className="text-input-box"
                placeholder="Give your task a name"
                value={taskName}
                aria-label="task name"
                onChange={taskNameChangeHandler}
                onBlur={(e) => {
                  taskNameOnBlurHandler(eachTaskDetail.task_id, e);
                  setIsEditMode(false);
                }}
                onKeyDown={(e) => addNewTaskOnEnter(eachTaskDetail.task_id, e)}
                maxLength={255}
              />
            </form>
          </td>
          <TaskDueDate
            taskDueDateChangeHandler={() => {}}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
          />
          <TaskAssignee
            taskAssigneeChangeHandler={() => {}}
            showAssigneeDropdown={showAssigneeDropdown}
            setShowAssigneeDropdown={setShowAssigneeDropdown}
          />
          <td></td>
        </tr>
      );
    }
  };

  return <>{getEachTaskJsx()}</>;
};

export default Task;
