import React, { Fragment, useEffect, useState } from 'react';
import { MenuProps, Checkbox, Col, Row, Dropdown, Space, DatePicker } from 'antd';
import { useTeamsStore } from './teamsStore';
import dayjs from "dayjs";

const TeamFilterButton: React.FC = () => {
    const { RangePicker } = DatePicker;
    const { setTableData } = useTeamsStore();
    const [status, setStatus] = useState<string[]>([]);
    const [teamsType, setTeamsType] = useState<string[]>([]);
    const { btableData } = useTeamsStore();
    const [dates, setDates] = useState<any>([]);
    const [, setDfDate] = useState<[any, any]>([null, null]);

    const {setFilterStatus} = useTeamsStore();
    const {setFilterTeamsType} = useTeamsStore();
    const {setFilterStartDate} = useTeamsStore();
    const {setFilterEndDate} = useTeamsStore();

    const filterData = (() => {
        const startDate = ((dates[0] != null) ? dates[0] + 'T00:00:00.864892' : '2000-08-01T00:10:18.864892');
        const endDate = ((dates[1] != null) ? dates[1] + 'T23:59:59.864892' : '2090-08-01T00:10:18.864892');

        if(status.length > 0){
            const boolStatus = status.map((data)=>{
                if(data === 'Active'){return true}
                else if(data === 'In-active'){return false}
                else {return true}
            })
            if (boolStatus.length > 0) {
                setFilterStatus(boolStatus);
            }
        }else{
            setFilterStatus([]);
        }

        setFilterTeamsType(teamsType);
        setFilterStartDate(startDate);
        setFilterEndDate(endDate);
    })

    useEffect(() => {
        filterData();
    }, [status,teamsType,dates]);

    const onChangeStatus = (checkedValues: any) => {
        setStatus(checkedValues);
    };

    const onChangeType = (checkedValues: any) => {
        setTeamsType(checkedValues);
    };

    const clearAll = (() => {
        setStatus([]);
        setTeamsType([]);
        setDates([null, null]);
        setDfDate([true, true]);
        setTableData(btableData);
    });

    const items: MenuProps['items'] = [

        {
            key: '1',
            label: <p onClick={clearAll}>Clear All</p>
        },

        {
            key: '2',
            label: <p>Account status</p>,
            children: [
                {
                    key: '2-1',
                    label:
                        <Space>
                            <Checkbox.Group style={{ width: '200px' }} value={status} onChange={onChangeStatus}>
                                <Row>
                                    <Col span={15}>
                                        <Checkbox value="Active">Active</Checkbox>
                                    </Col>
                                    <br />
                                    <Col span={15}>
                                        <Checkbox value="In-active">In-active</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    ,
                },
            ]
        },

        {
            key: '3',
            label: <p>Team type</p>,
            children: [
                {
                    key: '3-1',
                    label:
                        <Checkbox.Group style={{ width: '200px' }} value={teamsType} onChange={onChangeType} >
                            <Row>
                                <Col span={15}>
                                    <Checkbox value="School">School</Checkbox>
                                </Col>
                                <br />
                                <Col span={15}>
                                    <Checkbox value="Personal">Personal</Checkbox>
                                </Col>
                                <br />
                                <Col span={15}>
                                    <Checkbox value="Work">Work</Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>
                    ,
                },
            ]
        },
        {
            key: '4',
            label: 'Date range : ',
        }
    ];

    const MenuDropdown = React.useCallback((menu: React.ReactNode) => (
        <div>
            {React.cloneElement(menu as React.ReactElement)}
            <Space direction="vertical" size={10}>
                <RangePicker
                    allowClear={false}
                    onCalendarChange={(val) => {
                        if (val) {
                            const date1 = val[0] ? dayjs(val[0]).format('YYYY-MM-DD').toString() : '1900-08-20';
                            const date2 = val[1] ? dayjs(val[1]).format('YYYY-MM-DD').toString() : '2100-08-30';
                            const date = [date1, date2]
                            setDates(date);
                        }
                    }}
                    changeOnBlur
                />
            </Space>
        </div>
    ), [RangePicker]);

    return (
        <Fragment>
            <Dropdown menu={{ items }}
                dropdownRender={MenuDropdown}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space >
                        <span className="cmnIcon filter"></span>
                    </Space>
                </a>
            </Dropdown>

        </Fragment >
    )
};

export default TeamFilterButton;
