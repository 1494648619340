import { ILoaderState } from "modules/models/interfaces";
import { useLoaderStore } from "modules/store";
import { FC } from "react";

const FullPageLoader: FC<{}> = () => {
  const { loaderState } = useLoaderStore(
    (state: ILoaderState) => state
  );

  return (
    <div className={`spinner ${loaderState}`}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
      <div className="bounce4"></div>
    </div>

  );
};

export default FullPageLoader;
