import { FC } from "react";
import { Tooltip } from "antd";
import { ISection } from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../../store";

const AddTaskCTA: FC<{
  sectionDetails: ISection;
}> = ({ sectionDetails }) => {
  const { sections, setSections } = useTemplateStore();

  const addNewTask = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              task_details: eachSection.grid_list_details?.task_details
                ? [
                    ...eachSection.grid_list_details.task_details,
                    {
                      task_id: 99,
                      task_name: "",
                      task_rank: 99,
                      section_id: +sectionDetails.section_id,
                      total_block_count: 0,
                      completed_block_count: 0,
                      isNew: true,
                    },
                  ]
                : [
                    {
                      task_id: 99,
                      task_name: "",
                      task_rank: 99,
                      section_id: +sectionDetails.section_id,
                      total_block_count: 0,
                      completed_block_count: 0,
                      isNew: true,
                    },
                  ],
            },
          };
        }
        return eachSection;
      })
    );
  };

  return (
    <div className="taskItemLast">
      <div className="addItem item1">
        <Tooltip title="Add Task" color={"#2E364C"} placement="right">
          <a href={void 0} aria-label="add task" onClick={() => addNewTask()}>
            <span className="addplus"></span>
          </a>
        </Tooltip>
      </div>
    </div>
  );
};

export default AddTaskCTA;
