import { FC, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import AddStageCTA from "./AddStageCTA";
import StageDelete from "./StageDelete";
import StageNameInputField from "./StageNameInputField";
import { RBAC } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import {
  ISection,
  IStageDetail,
} from "modules/models/interfaces/template.interface";
import MessageThread from "../../MessageThread";
import { SectionTypeMessages } from "modules/models/enums/messages";

const TaskList: FC<{
  sectionDetails: ISection;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateStage: (stageName: any, stageId: any) => Promise<void>;
  setStageName: React.Dispatch<React.SetStateAction<string>>;
  revertChange: (stageId: number, event: string) => void;
  stageName: string;
}> = ({
  sectionDetails,
  submitChange,
  stageName,
  setStageName,
  updateStage,
  revertChange,
}) => {
  return (
    <Fragment>
      {sectionDetails.grid_list_details?.stage_details?.map(
        (ele: IStageDetail, index: number) => (
          <Draggable
            key={ele.stage_id}
            draggableId={`${ele.stage_id}`}
            index={index}
            isDragDisabled={
              sectionDetails.grid_list_details?.stage_details?.length === 1
            }
          >
            {(provided: any) => {
              return (
                <li
                  // className="stageItem"
                  key={ele.stage_id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <StageNameInputField
                    stageDetails={ele}
                    stageName={stageName}
                    setStageName={setStageName}
                    submitChange={submitChange}
                    updateStage={updateStage}
                    sectionDetails={sectionDetails}
                    revertChange={revertChange}
                  />
                  {!ele?.isNew && (
                    <MessageThread
                      sectionId={sectionDetails.section_id}
                      taskId={ele.stage_id}
                      sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                      threadName={ele.stage_name}
                      sectionName={sectionDetails.section_name!}
                      taskDetails={ele}
                    />
                  )}
                  <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
                    <StageDelete
                      stageDetails={ele}
                      sectionDetails={sectionDetails}
                    />
                  </RBAC>
                </li>
              );
            }}
          </Draggable>
        )
      )}
      <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
        <AddStageCTA sectionDetails={sectionDetails} />
      </RBAC>
    </Fragment>
  );
};

export default TaskList;
