import { Fragment, useEffect } from "react";
import { EditorContent, JSONContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Paragraph from "@tiptap/extension-paragraph";
import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
import Placeholder from '@tiptap/extension-placeholder';
import EditorControls from "./EditorControls";
import suggestion from "./suggestion";
import { IAllowedExtensions } from "modules/models/interfaces";

interface ITipTapEditor {
  sendMessage?: any;
  extensionPosition?: "top" | "bottom";
  allowedExtensions?: IAllowedExtensions;
  editorClassName?: string;
  threadId?: string;
  showEmojiPicker?: boolean;
  setShowEmojiPicker?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditorData?: React.Dispatch<React.SetStateAction<JSONContent | null>>;
  messageContent?: string;
  setMessageId?: React.Dispatch<React.SetStateAction<string>>;
  setMessageContent?: React.Dispatch<React.SetStateAction<string>>;
  pageCmsData?: any;
}
const Editor: React.FC<ITipTapEditor> = ({
  sendMessage,
  extensionPosition,
  allowedExtensions,
  editorClassName,
  threadId,
  showEmojiPicker,
  setShowEmojiPicker,
  setEditorData,
  messageContent,
  setMessageId,
  setMessageContent,
  pageCmsData
}) => {

  console.log(pageCmsData)

  useEffect(() => {
    if (setEditorData) {
      setEditorData(null);
    }
  }, []);

  const editor = useEditor({
    editable: true,
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion,
      }),
      Link.configure({
        autolink: true,
        linkOnPaste: true,
        HTMLAttributes: {
          target: "_blank",
        },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
      }),
      Image.configure({
        HTMLAttributes: {
          class: "tiptapExtensionImage",
        },
      }),
      TaskList.configure({
        HTMLAttributes: {
          class: "tiptapExtensionCheckListParent",
        },
      }),
      TaskItem.configure({
        HTMLAttributes: {
          class: "tiptapExtensionCheckListChild",
        },
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: "tiptapExtensionParagraph",
        },
      }),
      Placeholder.configure({
        placeholder: "Type a new message",
      })
    ],
    onUpdate({ editor }) {
      if (setEditorData) {
        setEditorData(editor.getJSON());
      }
      if (
        setMessageId &&
        setMessageContent &&
        editor.getHTML() === `<p class="tiptapExtensionParagraph"></p>`
      ) {
        setMessageId("");
        setMessageContent("");
      }
    },
    autofocus: true,
    content: ``,
  });

  useEffect(() => {
    if (editor && messageContent) {
      editor.commands.setContent(messageContent);
      editor.commands.focus("end");
    }
  }, [editor, messageContent]);

  return extensionPosition === "top" ? (
    <Fragment>
      <EditorControls
        editor={editor}
        sendMessage={sendMessage}
        allowedExtensions={allowedExtensions}
        threadId={threadId}
        showEmojiPicker={showEmojiPicker}
        setShowEmojiPicker={setShowEmojiPicker}
      />
      <EditorContent className={editorClassName} editor={editor} />
    </Fragment>
  ) : (
    <Fragment>
      <EditorContent className={editorClassName} editor={editor} />
      <EditorControls
        editor={editor}
        sendMessage={sendMessage}
        allowedExtensions={allowedExtensions}
        threadId={threadId}
        showEmojiPicker={showEmojiPicker}
        setShowEmojiPicker={setShowEmojiPicker}
        pageCmsData={pageCmsData}
      />
    </Fragment>
  );
};

export default Editor;
