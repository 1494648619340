import { Tooltip } from "antd";
import {
  ISection,
  ISectionDetail,
} from "modules/models/interfaces/template.interface";

const TaskStatus: React.FC<{
  eachTaskDetail?: ISectionDetail;
  taskStatusChangeHandler: (statusId: number) => void;
  section?: ISection;
}> = ({ eachTaskDetail, taskStatusChangeHandler, section }) => {
  return (
    <td className="statusWrap">
      <div className="content">
        <Tooltip title="To Do" placement="bottom">
          <img
            // onClick={() =>
            //   hasPermissions(
            //     [ERbacPermissions.PROJECT_SECTION_TASK_STATUS_EDIT],
            //     projectDetails?.associated_role_id
            //   ) && taskStatusChangeHandler(eachTaskDetail?.task_status_id ?? 1)
            // }
            src={`https://assets-qa.cleversort.com/sticker_pack/default/sticker-default-todo.svg`}
            alt="Todo"
          />
        </Tooltip>
      </div>
    </td>
  );
};

export default TaskStatus;
