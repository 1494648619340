import {Color} from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import {EditorContent, BubbleMenu, useCurrentEditor, useEditor} from '@tiptap/react'
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import StarterKit from '@tiptap/starter-kit'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import React, {FC, useEffect, useState} from "react";
import {Dropdown, MenuProps, Tooltip, Input, Popover} from "antd";
import insertTable from '../../../../../../assets/images/insert-table.png';
import addColumnBefore from '../../../../../../assets/images/add-column-before.png';
import addColumnAfter from '../../../../../../assets/images/add-column-after.png';
import deleteColumn from '../../../../../../assets/images/delete-column-before.png';
import addRowBefore from '../../../../../../assets/images/add-row-before.png';
import addRowAfter from '../../../../../../assets/images/add-row-after.png';
import deleteRow from '../../../../../../assets/images/delete-row.png';
import deleteTable from '../../../../../../assets/images/delete-table.png';
import mergeCells from '../../../../../../assets/images/merge-cells.png';
import splitCell from '../../../../../../assets/images/split-cells.png';
import toggleHeaderColumn from '../../../../../../assets/images/toggle-header-column.png';
import toggleHeaderRow from '../../../../../../assets/images/toggle-header-row.png';
import toggleHeaderCell from '../../../../../../assets/images/toggle-header-cell.png';
import mergeOrSplit from '../../../../../../assets/images/merge-or-split.png';
import setCellAttribute from '../../../../../../assets/images/set-cell-attribute.png';
import fixTables from '../../../../../../assets/images/fix-tables.png';
import goToNextCell from '../../../../../../assets/images/go-to-next-cell.png';
import goToPreviousCell from '../../../../../../assets/images/go-to-previous-cell.png';
import toggleTaskList from '../../../../../../assets/images/toggle-task-list.png';
import splitListItem from '../../../../../../assets/images/split-task-list.png';
import sinkListItem from '../../../../../../assets/images/sink-list.png';
import liftListItem from '../../../../../../assets/images/lift-list.png';
import {
    BoldOutlined,
    ItalicOutlined, LinkOutlined,
    OrderedListOutlined, RedoOutlined,
    StrikethroughOutlined, UnderlineOutlined,
    UndoOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import FileUploader from "./FileUploader";
import {Link} from "@tiptap/extension-link";
import MemopadLink from "./MemopadLink";
import classNames from "classnames";


export enum ContentType {
    TEXT,
    TABLE,
    LIST
}

enum ContentControl {
    MOVE_UP = "MOVE_UP",
    REMOVE = "REMOVE",
    MOVE_DOWN = "MOVE_DOWN"
}

const extensions = [
    Document,
    Text,
    Table.configure({
        resizable: true
    }),
    TableRow,
    TableHeader,
    TableCell,
    Link.extend({inclusive: false}).configure({
        openOnClick: true,
        validate: href => /^https?:\/\//.test(href),
        HTMLAttributes: {
            class: 'memopadLink',
            rel: 'noopener noreferrer',
            target: "_blank",
        },
    }),
    Color.configure({types: [TextStyle.name, ListItem.name]}),
    Underline,
    TaskList.configure({
        HTMLAttributes: {
            class: 'memopadTasklist',
        },
    }),
    TaskItem.configure({
        nested: true,
    }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
]


const TiptapEditor: FC<{
    content: { [key: string]: any };
    index: number;
    onUpdate: (data: Object, index: number) => void;
    onMoveUp: (index: number) => void;
    onMoveDown: (index: number) => void;
    onRemove: (index: number) => void;
    maxIndex: number;
    templateId: number;
}> = ({content, index, onUpdate, maxIndex, onMoveUp, onMoveDown, onRemove, templateId}) => {
    const [isNew, setIsNew] = useState<boolean>(content.is_new ?? false)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [showLinkPopover, setShowLinkPopover] = useState<boolean>(false)
    const [defaultLink, setDefaultLink] = useState<string>("")
    const [rangeSelection, setRangeSelection] = useState<{ from: number; to: number } | null>(null)

    const editor = useEditor({
        // @ts-ignore
        extensions: extensions,
        content: content,
        autofocus: isNew ? "start" : null,
    })
    const items: MenuProps['items'] = [
        {
            key: ContentControl.MOVE_UP,
            label: 'Move up',
            disabled: index === 0
        },
        {
            key: ContentControl.REMOVE,
            label: 'Remove',
        },
        {
            key: ContentControl.MOVE_DOWN,
            label: 'Move down',
            disabled: index === maxIndex
        }
    ];

    const editorType = (contentType: string) => {
        switch (contentType) {
            case "text":
                return ContentType.TEXT
            case "table":
                return ContentType.TABLE
            case "list":
                return ContentType.LIST
            default:
                return ContentType.TEXT
        }
    }



    const onClick: MenuProps["onClick"] = ({key}) => {
        switch (key) {
            case ContentControl.MOVE_UP:
                onMoveUp(index)
                return
            case ContentControl.REMOVE:
                onRemove(index)
                return
            case ContentControl.MOVE_DOWN:
                onMoveDown(index)
                return
            default:
                return
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setIsNew(false)
        }, 1000)
    }, [])

    if (!editor) {
        return null
    }


    const setLink = (url: string) => {
        setShowLinkPopover(false)
        if (!rangeSelection) return;

        if (url === null)  return;

        if (url === '') return;
        
        if (!(/^https?:\/\//.test(url))) return;

        editor.chain().setTextSelection({
            from: rangeSelection.from,
            to: rangeSelection.to
        }).extendMarkRange('link').setLink({href: url, target: '_blank'}).run()
        setRangeSelection(null)
    }

    const handleHeaderChange = (value: string) => {
        switch (value) {
            case "h1":
                editor.chain().focus().toggleHeading({level: 1}).run()
                break
            case "h2":
                editor.chain().focus().toggleHeading({level: 2}).run()
                break
            case "h3":
                editor.chain().focus().toggleHeading({level: 3}).run()
                break
            default:
                if (editor.isActive('heading', {level: 1})) {
                    editor.chain().focus().toggleHeading({level: 1}).run()
                }

                if (editor.isActive('heading', {level: 2})) {
                    editor.chain().focus().toggleHeading({level: 2}).run()
                }
                if (editor.isActive('heading', {level: 3})) {
                    editor.chain().focus().toggleHeading({level: 3}).run()
                }
                break
        }
    }

    const LinkPopoverContent = (<>
        <Input placeholder='"Put link here' defaultValue={defaultLink} onBlur={(evt) => setLink(evt.target.value)}/>
    </>)

    return (
        <div className={classNames('memopadElement', content["content-type"], content["docId"])}>
            <>
                <Dropdown placement="bottomLeft" menu={{items, onClick}} trigger={["click"]}>
                    <span className={"cmnIcon blueMore"}/>
                </Dropdown>
            </>

            {content["content-type"] === "text" || content["content-type"] === "table" || content["content-type"] === "list" ?
                <div className="editorContainer" onMouseLeave={() => setIsEditMode(false)}>
                    <EditorContent 
                        editor={editor}
                        onClick={() => setIsEditMode(true)}
                        onBlur={evt => onUpdate({
                            ...editor!.getJSON(),
                            "content-type": content["content-type"],
                            "docId": content["docId"]
                        }, index)}
                    />
                    {isEditMode ? <div className={classNames("tiptapEditorButtonWrapper", {
                            focused: editor.isFocused
                        })}>
                            {editorType(content["content-type"]) === ContentType.TEXT ? <>
                                <Tooltip title={'Bold'}>
                                    <button
                                        onClick={() => {
                                            editor?.commands.focus()
                                            editor.chain().focus().toggleBold().run()
                                        }}
                                        disabled={
                                            !editor.can()
                                                .chain()
                                                .focus()
                                                .toggleBold()
                                                .run()
                                        }
                                        className={editor.isActive('bold') ? 'is-active' : ''}
                                    >
                                        <BoldOutlined/>
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Italic'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleItalic().run()}
                                        disabled={
                                            !editor.can()
                                                .chain()
                                                .focus()
                                                .toggleItalic()
                                                .run()
                                        }
                                        className={editor.isActive('italic') ? 'is-active' : ''}
                                    >
                                        <ItalicOutlined/>
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Underline'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleUnderline().run()}
                                        className={editor.isActive('underline') ? 'is-active' : ''}
                                    >
                                        <UnderlineOutlined/>
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Strike through'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleStrike().run()}
                                        disabled={
                                            !editor.can()
                                                .chain()
                                                .focus()
                                                .toggleStrike()
                                                .run()
                                        }
                                        className={editor.isActive('strike') ? 'is-active' : ''}
                                    >
                                        <StrikethroughOutlined/>
                                    </button>
                                </Tooltip>
                                <select onChange={(evt) => handleHeaderChange(evt.target.value)}>
                                    <option value="normal">Normal</option>
                                    <option value="h1" selected={editor.isActive('heading', {level: 1})}>H1</option>
                                    <option value="h2" selected={editor.isActive('heading', {level: 2})}>H2</option>
                                    <option value="h3" selected={editor.isActive('heading', {level: 3})}>H3</option>
                                </select>
                                <Tooltip title={'Bullet list'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                                        className={editor.isActive('bulletList') ? 'is-active' : ''}
                                    >
                                        <UnorderedListOutlined/>
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Order list'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                                        className={editor.isActive('orderedList') ? 'is-active' : ''}
                                    >
                                        <OrderedListOutlined/>
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Link'}>
                                    <Popover open={showLinkPopover} content={LinkPopoverContent}>
                                        <button
                                            onClick={() => {
                                                const state = editor.state
                                                const {from, to} = state.selection
                                                setDefaultLink(editor.getAttributes('link').href)
                                                setRangeSelection({
                                                    from, to
                                                })
                                                setShowLinkPopover(!showLinkPopover)
                                                editor.commands.unsetLink()
                                            }}
                                            className={editor.isActive('link') ? 'is-active' : ''}
                                        >
                                            <LinkOutlined/>
                                        </button>
                                    </Popover>
                                </Tooltip>
                            </> : null}


                            {editorType(content["content-type"]) === ContentType.TABLE ? <>
                                <Tooltip title={'Insert table'}>
                                    <button
                                        onClick={() =>
                                            editor
                                                .chain()
                                                .focus()
                                                .insertTable({rows: 3, cols: 3, withHeaderRow: true})
                                                .run()
                                        }
                                    >
                                        <img src={insertTable} alt="insert table"/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Add column before'}>
                                    <button onClick={() => editor.chain().focus().addColumnBefore().run()}>
                                        <img src={addColumnBefore} alt=""/>
                                    </button>
                                </Tooltip>
                                <Tooltip title={'Add column after'}>
                                    <button onClick={() => editor.chain().focus().addColumnAfter().run()}>
                                        <img src={addColumnAfter} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Delete column'}>
                                    <button onClick={() => editor.chain().focus().deleteColumn().run()}>
                                        <img src={deleteColumn} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Add row above'}>
                                    <button onClick={() => editor.chain().focus().addRowBefore().run()}>
                                        <img src={addRowBefore} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Add row below'}>
                                    <button onClick={() => editor.chain().focus().addRowAfter().run()}>
                                        <img src={addRowAfter} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Delete row'}>
                                    <button onClick={() => editor.chain().focus().deleteRow().run()}>
                                        <img src={deleteRow} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Delete table'}>
                                    <button onClick={() => editor.chain().focus().deleteTable().run()}>
                                        <img src={deleteTable} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Merge cell'}>
                                    <button onClick={() => editor.chain().focus().mergeCells().run()}>
                                        <img src={mergeCells} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Split cell'}>
                                    <button onClick={() => editor.chain().focus().splitCell().run()}>
                                        <img src={splitCell} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Toggle header column'}>
                                    <button onClick={() => editor.chain().focus().toggleHeaderColumn().run()}>
                                        <img src={toggleHeaderColumn} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Toggle header row'}>
                                    <button onClick={() => editor.chain().focus().toggleHeaderRow().run()}>
                                        <img src={toggleHeaderRow} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Toggle header cell'}>
                                    <button onClick={() => editor.chain().focus().toggleHeaderCell().run()}>
                                        <img src={toggleHeaderCell} alt=""/>
                                    </button>
                                </Tooltip>

                                {/*<button onClick={() => editor.chain().focus().mergeOrSplit().run()}>*/}

                                {/*  <img src={mergeOrSplit} alt="" />*/}

                                {/*</button>*/}

                                {/*<button*/}

                                {/*  onClick={() =>*/}

                                {/*    editor.chain().focus().setCellAttribute("colspan", 2).run()*/}

                                {/*  }*/}

                                {/*>*/}

                                {/*  <img src={setCellAttribute} alt="" />*/}

                                {/*</button>*/}

                                {/*<button onClick={() => editor.chain().focus().fixTables().run()}>*/}

                                {/*  <img src={fixTables} alt="" />*/}

                                {/*</button>*/}

                                {/*<button onClick={() => editor.chain().focus().goToNextCell().run()}>*/}

                                {/*  <img src={goToNextCell} alt="" />*/}

                                {/*</button>*/}

                                {/*<button onClick={() => editor.chain().focus().goToPreviousCell().run()}>*/}

                                {/*  <img src={goToPreviousCell} alt="" />*/}

                                {/*</button>*/}

                            </> : null}


                            {ContentType.LIST === editorType(content["content-type"]) ? <>
                                <Tooltip title={'Toggle task list'}>
                                    <button
                                        onClick={() => editor.chain().focus().toggleTaskList().run()}
                                        className={editor.isActive('taskList') ? 'is-active' : ''}
                                    >
                                        <img src={toggleTaskList} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Split list item'}>
                                    <button
                                        onClick={() => editor.chain().focus().splitListItem('taskItem').run()}
                                        disabled={!editor.can().splitListItem('taskItem')}
                                    >
                                        <img src={splitListItem} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Sink list item'}>
                                    <button
                                        onClick={() => editor.chain().focus().sinkListItem('taskItem').run()}
                                        disabled={!editor.can().sinkListItem('taskItem')}
                                    >
                                        <img src={sinkListItem} alt=""/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Lift list item'}>
                                    <button
                                        onClick={() => editor.chain().focus().liftListItem('taskItem').run()}
                                        disabled={!editor.can().liftListItem('taskItem')}
                                    >
                                        <img src={liftListItem} alt=""/>
                                    </button>
                                </Tooltip>
                            </> : null}

                            <Tooltip title={'Undo'}>
                                <button
                                    onClick={() => editor.chain().focus().undo().run()}
                                    disabled={
                                        !editor.can()
                                            .chain()
                                            .focus()
                                            .undo()
                                            .run()
                                    }
                                >
                                    <UndoOutlined/>
                                </button>
                            </Tooltip>
                            <Tooltip title={'Redo'}>
                                <button
                                    onClick={() => editor.chain().focus().redo().run()}
                                    disabled={
                                        !editor.can()
                                            .chain()
                                            .focus()
                                            .redo()
                                            .run()
                                    }
                                >
                                    <RedoOutlined/>
                                </button>
                            </Tooltip>

                            {/*<button*/}

                            {/*  onClick={() => editor.chain().focus().setColor('#958DF1').run()}*/}

                            {/*  className={editor.isActive('textStyle', {color: '#958DF1'}) ? 'is-active' : ''}*/}

                            {/*>*/}

                            {/*  purple*/}

                            {/*</button>*/}

                        </div> : null}
                    
                </div> : null}
            {content["content-type"] === "file" ? <FileUploader onChange={(evt) => onUpdate({
                ...content,
                "content": evt
            }, index)} content={content} templateId={templateId}/> : null}
            {content["content-type"] === "link" ? <MemopadLink onChange={(evt) => onUpdate({
                ...content,
                "content": evt
            }, index)} content={content}/> : null}
        </div>
    )
}

export default TiptapEditor