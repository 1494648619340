import cryptoRandomString from "crypto-random-string";
import { useTemplateStore } from "../store";

const AddTask: React.FC<{ sectionId: string | number }> = ({ sectionId }) => {
  const { sections, setSections } = useTemplateStore();

  const addTask = () => {
    let section = sections?.find((ele) => ele.section_id === sectionId);
    if (section?.section_details) {
      let emptyTask = section.section_details.findIndex(
        (ele) => ele?.task_name?.trim() === ""
      );
      if (emptyTask && emptyTask < 0) {
        let max = 0;
        section.section_details.forEach((ele) => {
          if (ele.task_rank > max) {
            max = ele.task_rank;
          }
        });

        let newTask = {
          task_id: cryptoRandomString({ length: 5 }),
          task_rank: max + 1,
          task_name: "",
          due_date: "",
          assignee_dp: "",
          assignee_id: null,
          assignee_name: "",
          assignee_email: "",
          task_status_id: 1,
          task_priority_id: 2,
          task_status_name: "",
          task_priority_name: "",
        };
        section.section_details?.push(newTask);
        setSections([
          ...sections.map((eachSection) => {
            if (eachSection.section_id === sectionId) {
              return section || eachSection;
            }
            return eachSection;
          }),
        ]);
      }
    }
  };

  return (
    <div className="addTask">
      <div className="itemNameBlk addFolder" onClick={() => addTask()}>
        <span className="addplus"></span>
        <span className="itemName">Add Task</span>
      </div>
    </div>
  );
};

export default AddTask;
