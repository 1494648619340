import { Fragment, useEffect, useRef, useState } from "react";
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "utils/firebase";
import { Dropdown, MenuProps, Popconfirm, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import cryptoRandomString from "crypto-random-string";
import {
  generateUserAvatar,
} from "utils/commonFunctions";
import { deleteObject, ref } from "@firebase/storage";
import Editor from "./Editor/Editor";
import { useTemplateStore } from "../store";
import { IUserDetails } from "modules/models/interfaces";

enum MessageThreadOption {
  EDIT_MESSAGE = "edit_message",
  DELETE_MESSAGE = "delete_message",
}

const lbl_message_modification_option = [
  {
    key: "edit_message",
    label: "Edit",
    className: "cmnIcon edit",
  },
  {
    key: "delete_message",
    label: "Delete",
    className: "actionIons cmnIcon deleteBin",
  },
];

const lbl_delete_message_popover_config = {
  body: "The message will not appear to anyone",
  cancel: "Cancel",
  header: "Delete the message",
  confirm: "Delete",
};

const cleversortUser = {
  full_name: process.env.REACT_APP_CLEVER_SORT_USER_NAME,
  user_id: Number(process.env.REACT_APP_CLEVER_SORT_USER_ID) || -1,
  org_id: process.env.REACT_APP_CLEVER_SORT_ORG_ID,
};

const MessageThread: React.FC<{
  sectionType: string;
  sectionId: number | string;
  taskId: number | string;
  threadName: string;
  sectionName: string;
  taskDetails: any;
}> = ({
  sectionType,
  sectionId,
  taskId,
  threadName,
  sectionName,
  taskDetails,
}) => {
  const refDiv = useRef<HTMLDivElement>(null);
  const [showMessagesPopup, setShowMessagesPopup] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageId, setMessageId] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [messages, setMessages] = useState([]);
  // const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = useState({
    is_visible: false,
    count: 0,
  });

  const { templateDetails } = useTemplateStore((state) => state);

  const messageThreadId = `${sectionType}-${process.env.REACT_APP_STAGE}-${cleversortUser.org_id}-${templateDetails?.template_id}-${sectionId}-${taskId}`;

  const getTaggedUserId = (data: any[]) => data;

  const sendMessage = async (
    data: string,
    parsedData: any,
    attachmentUrls: string[]
  ) => {
    const docRef = doc(db, "message-thread-admin", messageThreadId);
    const docSnap = await getDoc(docRef);

    if (messageId === "") {
      if (!docSnap.exists()) {
        await setDoc(doc(db, "message-thread-admin", messageThreadId), {
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          project_id: templateDetails?.template_id,
          section_id: sectionId,
          member_id: [cleversortUser.user_id],
          messages: arrayUnion({
            message_id: cryptoRandomString({ length: 10 }),
            attachments: attachmentUrls,
            tagged_user_id: getTaggedUserId(parsedData),
            user_id: cleversortUser.user_id,
            content: data.replaceAll(
              '<p class="tiptapExtensionParagraph"></p>',
              ""
            ),
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            is_delete: false,
            is_edited: false,
            is_archive: false,
          }),
        });
      } else {
        await updateDoc(doc(db, "message-thread-admin", messageThreadId), {
          messages: arrayUnion({
            message_id: cryptoRandomString({ length: 10 }),
            attachments: attachmentUrls,
            tagged_user_id: getTaggedUserId(parsedData),
            user_id: cleversortUser.user_id,
            content: data.replaceAll(
              '<p class="tiptapExtensionParagraph"></p>',
              ""
            ),
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            is_delete: false,
            is_edited: false,
            is_archive: false,
          }),
          updated_at: Timestamp.now(),
        });
      }
    } else {
      let document = await getDoc(
        doc(db, "message-thread-admin", messageThreadId)
      );
      let messages = document.data()!.messages;

      let props = messages.find((ele: any) => ele.message_id === messageId);

      let existingAttachments = [...props.attachments];

      props.content = data.replaceAll(
        '<p class="tiptapExtensionParagraph"></p>',
        ""
      );

      props.updated_at = Timestamp.now();
      props.tagged_user_id = getTaggedUserId(parsedData);
      props.attachments = attachmentUrls;
      props.is_edited = true;

      await updateDoc(doc(db, "message-thread-admin", messageThreadId), {
        messages: messages,
        updated_at: Timestamp.now(),
      });

      existingAttachments
        .filter((ele: string) => !attachmentUrls.includes(ele))
        .forEach((ele) => {
          const desertRef = ref(storage, ele);
          deleteObject(desertRef);
        });

      setMessageId("");
      setMessageContent("");
      localStorage.removeItem("currentMessageId");
    }
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === MessageThreadOption.EDIT_MESSAGE) {
      setMessageId(localStorage.getItem("currentMessageId")!);
      editMessage(localStorage.getItem("currentMessageId")!);
    }
  };

  const editMessage = async (messageId: string) => {
    let data = await getDoc(doc(db, "message-thread-admin", messageThreadId));
    let messages = data.data()!.messages;

    let text = messages.find(
      (ele: any) => ele.message_id === messageId
    ).content;
    setMessageContent(text);
  };

  const deleteMessage = async (messageId: string) => {
    let data = await getDoc(doc(db, "message-thread-admin", messageThreadId));
    let messages = data.data()!.messages;

    let props = messages.find((ele: any) => ele.message_id === messageId);

    props.updated_at = Timestamp.now();
    props.is_delete = true;

    await updateDoc(doc(db, "message-thread-admin", messageThreadId), {
      messages: messages,
      updated_at: Timestamp.now(),
    });
    // setOpenPopover(false);
  };

  const showUnreadIndicator = (messages: any) => {
    return false;
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowMessagesPopup(false);
        setShowEmojiPicker(false);
        setMessageId("");
        setMessageContent("");
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    const unSub = onSnapshot(
      doc(db, "message-thread-admin", messageThreadId),
      (doc) => {
        if (doc.exists()) {
          setMessages(doc.data().messages);
          setMessageInfo({
            is_visible: showUnreadIndicator(doc.data().messages),
            count: doc.data().messages.length,
          });
        }
      }
    );

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
      unSub();
    };
  }, []);

  useEffect(() => {
    if (messages.length) {
      refDiv.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages.length]);

  useEffect(() => {
    if (showMessagesPopup) {
      setMessageInfo({
        ...messageInfo,
        is_visible: false,
      });
    }
  }, [showMessagesPopup]);

  const content = (
    <div className={`messageBox show`} id="msgBoxPopup">
      <div className="msgBoxTop d-flex">
        <div className="msgHeading">
          <span> {threadName} </span>
        </div>
        <div
          className="closeMsgBox"
          onClick={() => {
            setShowMessagesPopup(false);
            setShowEmojiPicker(false);
            setMessageId("");
            setMessageContent("");
            localStorage.removeItem("currentMessageId");
          }}
          onKeyDown={() => {}}
        >
          <span
            className="material-icons-outlined modal-close"
            id="msgBoxClose"
          >
            close
          </span>
        </div>
      </div>
      <div className="msgBody">
        {messages.map((ele: any) => {
          let items: MenuProps["items"] = [];

          if (ele.user_id === cleversortUser.user_id) {
            items = lbl_message_modification_option?.map((ele: any) => {
              return ele.key === MessageThreadOption.DELETE_MESSAGE
                ? {
                    key: ele.key,
                    label: (
                      <Popconfirm
                        title={lbl_delete_message_popover_config.header}
                        description={lbl_delete_message_popover_config.body}
                        okText={lbl_delete_message_popover_config.confirm}
                        cancelText={lbl_delete_message_popover_config.cancel}
                        placement="leftTop"
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        arrow={false}
                        // open={openPopover}
                        onConfirm={() =>
                          deleteMessage(
                            localStorage.getItem("currentMessageId")!
                          )
                        }
                        onCancel={() => {
                          localStorage.removeItem("currentMessageId");
                          // setOpenPopover(false);
                        }}
                        overlayClassName="customAntDeletePopover"
                      >
                        <div
                          className="optionWrap"
                          // onClick={() => setOpenPopover(true)}
                          onKeyDown={() => {}}
                        >
                          <span className={ele.className}></span>
                          <span>{ele.label}</span>
                        </div>
                      </Popconfirm>
                    ),
                  }
                : {
                    key: ele.key,
                    label: (
                      <div className="optionWrap">
                        <span className={ele.className}></span>
                        <span>{ele.label}</span>
                      </div>
                    ),
                  };
            });
          }

          return (
            <div className="masseuse userBlkWrap" key={ele.message_id}>
              {generateUserAvatar(cleversortUser as unknown as IUserDetails)
                .key === "string" ? (
                <div className="userAvtr">
                  {
                    generateUserAvatar(
                      cleversortUser as unknown as IUserDetails
                    ).value
                  }
                </div>
              ) : (
                <div className="userAvtr">
                  <img
                    src={
                      generateUserAvatar(
                        cleversortUser as unknown as IUserDetails
                      ).value
                    }
                  />
                </div>
              )}
              <div className="msgArea">
                <div className="userName">
                  <span className="userNameElement">
                    {cleversortUser.full_name}
                  </span>
                  {ele.is_edited ? (
                    <span className="editedMessageMarker">Edited</span>
                  ) : null}
                </div>
                {!ele?.is_delete ? (
                  <div
                    className="msgText"
                    dangerouslySetInnerHTML={{ __html: ele.content }}
                  ></div>
                ) : (
                  <div className="msgTextDeleted">
                    <span className="deletedTxtFormatting">
                      This message was deleted
                    </span>
                  </div>
                )}
              </div>
              {ele.user_id === cleversortUser.user_id && !ele.is_delete ? (
                <Dropdown
                  menu={{ items, onClick }}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <span
                    className="msgTextMore cmnIcon more"
                    onClick={() =>
                      localStorage.setItem("currentMessageId", ele.message_id)
                    }
                    onKeyDown={() => {}}
                  ></span>
                </Dropdown>
              ) : null}
            </div>
          );
        })}
        <div ref={refDiv} />
      </div>
      <div className="replyMsg">
        <Editor
          sendMessage={sendMessage}
          editorClassName="messageThread"
          threadId={messageThreadId}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          messageContent={messageContent}
          setMessageId={setMessageId}
          setMessageContent={setMessageContent}
          allowedExtensions={{
            showBoldOutlined: true,
            showItalicOutlined: true,
            showUnorderedListOutlined: true,
            showEmojiControl: true,
            showUploadOutlined: true,
            showLinkOutlined: true,
            showSend: true,
            showCheckList: true,
          }}
        />
      </div>
    </div>
  );

  const classNameForMessageThread = (count: number) =>
    count === 0 ? "emptyMessageThread" : "";

  return (
    <Fragment>
      {!showMessagesPopup ? (
        <div className="messageWrap">
          <span
            className={`cmnIcon message ${classNameForMessageThread(
              messages.length
            )} ${messageInfo.is_visible ? "unReadMessageIndicator" : ""}`}
            onClick={() => setShowMessagesPopup(!showMessagesPopup)}
            onKeyDown={() => {}}
          ></span>
        </div>
      ) : (
        <Popover
          placement="bottomRight"
          trigger={"click"}
          content={content}
          open={true}
          overlayClassName={"messageThreadClass"}
          arrow={false}
        >
          <div className="messageWrap">
            <span
              className={`cmnIcon message`}
              onClick={() => setShowMessagesPopup(!showMessagesPopup)}
              onKeyDown={() => {}}
            ></span>
          </div>
        </Popover>
      )}
    </Fragment>
  );
};

export default MessageThread;
