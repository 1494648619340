import React, { Fragment, useEffect, useState } from 'react';
import { Table, Tooltip, message, Spin, Input, Space, Tag } from 'antd';
import { ComponentLoader } from 'modules/components';
import { useStore } from './store';
import { StickersPacksData, StickersPacksTable, TeamsData } from 'modules/models/interfaces';
import { checkStatus, dateConvert } from 'utils/commonFunctions';
import { ColumnsType } from 'antd/es/table';
import { useAuth0 } from '@auth0/auth0-react';
import apiService from 'modules/services/Api.service';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useRbac } from 'auth/rbac/rbac';
import StickerService from 'modules/services/StickerService/Sticker.service';
import { UploadStickersconfig } from 'modules/models/interfaces/stickers.interface';
import StickersFilter from './StickersFilter';


const StickersTable: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const { isDrawer, setIsDrawer } = useStore();
    const [, contextHolder] = message.useMessage();
    const { tloading } = useStore();
    const { setStickerPack } = useStore();
    const { hasPermissions } = useRbac();
    const [searchText, setSearchText] = useState('');
    const { bStickersTable, setBStickersTable } = useStore();
    const { stickersTable, setStickersTable } = useStore();

    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };

    const editStickerPack = (stickerPackInfo: StickersPacksData) => {
        handleDrawer();
        setStickerPack(stickerPackInfo);
    }

    const teamsArray = (data: TeamsData[]) => {
        const teamsarr = data.map((item: TeamsData) => {
            const teamName: string = `${item.organization_name.trim()}(${item.org_key})`;
            return teamName;
        });

        return teamsarr;
    };

    const stickerPackSize = (name: any) => {
        const packs = stickersTable.find(({ pack_name }) => pack_name == name);
        return (packs?.pack_configuration.length)
    }

    const defautStickerImg = (packConfig: UploadStickersconfig[]) => {
        if (packConfig) {
            const imgpack = packConfig.find(({ is_dafault }) => is_dafault === true);

            return (imgpack?.sticker_url)
        }
    }


    const getStickersPacks = async (): Promise<void> => {
        try {
            const response = await StickerService.getStickersPacks();

            const mapItem = (item: any) => {
                const teams: any = item.teams || '';
                const teamsArr: string[] = teams ? teamsArray(teams).sort((a: any, b: any) => a - b) : (item.part_of_all_organization ? ['All'] : ['']);
                const mergeTeams: string = teams ? teamsArray(teams).sort((a: any, b: any) => a - b).join(', ') : (item.part_of_all_organization ? 'All' : '');
                const languages: string = item.languages || '';
                const mergeLanguages: string = languages ? item.languages.sort((a: any, b: any) => a - b).join(', ') : '';
                const featuresArr: string[] = item.features ? item.features.sort((a: any, b: any) => a - b) : [''];
                const mergeFeatures: string = item.features ? item.features.sort((a: any, b: any) => a - b).join(',') : '';
                const packStatus: string = checkStatus(item.is_active);
                const updated_on: string = item.updated_on || item.created_on;
                const editStickerInfo: any = { ...item };

                return {
                    teams,
                    teamsArr,
                    mergeTeams,
                    languages,
                    mergeLanguages,
                    featuresArr,
                    mergeFeatures,
                    pack_id: item.pack_id,
                    is_active: item.is_active,
                    packStatus,
                    pack_name: item.pack_name.trim(),
                    created_on: item.created_on,
                    pack_created: dateConvert(item.created_on),
                    is_default: item.is_default,
                    updated_on,
                    pack_updated: dateConvert(updated_on),
                    bucket_path_suffix: item.bucket_path_suffix,
                    pack_configuration: item.pack_configuration,
                    part_of_all_organization: item.part_of_all_organization,
                    editStickerInfo,
                };
            };

            const data: any = (response?.data || []).map(mapItem);
            setStickersTable(data);
            setBStickersTable(data);

            if (response) {
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };


    const addNewStickerPack = () => {
        handleDrawer();
        setStickerPack({
            teams: [],
            languages: [],
            pack_id: undefined,
            is_active: true,
            pack_name: '',
            created_on: '',
            is_default: false,
            updated_on: '',
            bucket_path_suffix: '',
            pack_configuration: [],
            part_of_all_organization: false,
        })
    }

    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (hasPermissions([ERbacPermissions.TASK_STICKER_VIEW])) {
            getStickersPacks();
        }
    }, []);

    useEffect(() => {
        getStickersPacks();
    }, [tloading]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value.toLowerCase().trim();
        const filteredData = bStickersTable.filter((record) => {
            const pack_name = record.pack_name.toLowerCase();
            const packStatus = record.packStatus.toLowerCase();
            const pack_created = record.pack_created.toLowerCase();
            const pack_updated = record.pack_updated.toLowerCase();
            const mergeLanguages = record.mergeLanguages.toLowerCase();
            const mergeTeams = record.mergeTeams.toLowerCase();
            const mergeFeatures = record.mergeFeatures.toLowerCase();
            return (
                pack_name.includes(searchTerm) ||
                packStatus.includes(searchTerm) ||
                pack_created.includes(searchTerm) ||
                pack_updated.includes(searchTerm) ||
                mergeLanguages.includes(searchTerm) ||
                mergeTeams.includes(searchTerm) ||
                mergeFeatures.includes(searchTerm)
            );
        });

        setSearchText(e.target.value);
        setStickersTable(e.target.value ? filteredData : bStickersTable);
    };

    const columns: ColumnsType<StickersPacksTable> = [
        {

            title: 'Image',
            dataIndex: 'pack_configuration',
            key: 'pack_configuration',
            width: '10%',
            render: (pack_configuration: UploadStickersconfig[]) => {
                return (
                    <Fragment>
                        <div className="stickerWrap taskStickerItem">
                            <img
                                src={`${process.env.REACT_APP_BUCKET_BASE_PATH}${defautStickerImg(pack_configuration)}`}
                                alt={
                                    `${process.env.REACT_APP_BUCKET_BASE_PATH}${pack_configuration?.[0]?.sticker_url}`
                                }
                            />
                        </div>
                    </Fragment>
                )
            },

        },
        {
            title: 'Sticker Pack Name',
            dataIndex: 'pack_name',
            key: 'pack_name',
            width: '15%',
            render: (pack_name: string) => {
                return (
                    <Fragment>
                        <div className="postSticker">
                            <p>{pack_name}</p>
                            <span>{stickerPackSize(pack_name)} Stickers</span>
                        </div>
                    </Fragment>
                )
            },
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.pack_name.localeCompare(b.pack_name),
        },
        {
            title: 'Status',
            dataIndex: 'packStatus',
            key: 'packStatus',
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.packStatus.localeCompare(b.packStatus),
            render: (accountStatus: string) => {
                if (accountStatus === 'Active') {
                    return <span className="activeBtn">Active</span>;
                } else if (accountStatus === 'In-active') {
                    return <span className="inactiveBtn">In-active</span>;
                }
                return null;
            },
        },

        {
            title: 'Applicable Features',
            dataIndex: 'featuresArr',
            key: 'featuresArr',

            render: (featuresArr: string[]) => {
                if (featuresArr?.length > 3) {
                    const tooltipContent = (
                        <div>
                            <ul>
                                {featuresArr?.map((feature) => (
                                    <li key={feature}>{feature}</li>
                                ))}
                            </ul>
                        </div>
                    );

                    return (
                        <Tooltip title={tooltipContent}>
                            <span>
                                <Space size={[0, 8]} wrap>
                                    {featuresArr?.slice(0, 3).map((feature) => (
                                        <Tag key={feature} color="#4C5FDC" className="feature-tag">{feature}</Tag>
                                    ))}
                                    <span style={{ color: 'blue' }}>
                                        {featuresArr.length > 3 && ` +${featuresArr.length - 3}`}
                                    </span>
                                </Space>
                            </span>
                        </Tooltip>
                    );
                } else {
                    return (
                        <div>
                            <Space size={[0, 8]} wrap>
                                {featuresArr?.map((feature) => (
                                    <Tag key={feature} color="#4C5FDC" className="feature-tag">{feature}</Tag>
                                ))}
                            </Space>
                        </div>
                    )
                }
            },
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.mergeFeatures.localeCompare(b.mergeFeatures),
        },
        {
            title: 'Language',
            dataIndex: 'languages',
            key: 'languages',
            render: (languages: string[]) => {
                if (languages?.length > 3) {
                    const tooltipContent = (
                        <div>
                            <ul>
                                {languages?.map((language) => (
                                    <li key={language}>{language}</li>
                                ))}
                            </ul>
                        </div>
                    );

                    return (
                        <Tooltip title={tooltipContent}>
                            <span>
                                {languages.slice(0, 3).join(', ')}
                                <span style={{ color: 'blue' }}>
                                    {languages.length > 3 && ` +${languages.length - 3}`}
                                </span>
                            </span>
                        </Tooltip>
                    );
                }

                return <span>{languages ? languages.join(', ') : ''}</span>;
            },
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.mergeLanguages.localeCompare(b.mergeLanguages),
        },
        {
            title: 'Team',
            dataIndex: 'teamsArr',
            key: 'teamsArr',
            render: (teamsArr: string[]) => {
                const tooltipContent = (
                    <div>
                        <ul>
                            {teamsArr?.map((team) => (
                                <li key={team}>{team}</li>
                            ))}
                        </ul>
                    </div>
                );
                if (teamsArr?.length > 3) {
                    return (
                        <Tooltip title={tooltipContent}>
                            <span>
                                <span>{teamsArr[0].slice(0, 5)}...,</span>
                                <span>{teamsArr[1].slice(0, 5)}...,</span>
                                <span>{teamsArr[2].slice(0, 5)}...,</span>
                                <span style={{ color: 'blue' }}>
                                    {teamsArr.length > 3 && ` +${teamsArr.length - 3}`}
                                </span>
                            </span>
                        </Tooltip>
                    );
                }

                return (
                    <Tooltip title={tooltipContent}>
                        <span>{teamsArr[0] ? teamsArr[0].slice(0, 5) : ''}{teamsArr[0].length > 5 ? '...' : ''}{teamsArr[1] ? ',' : ''}</span>
                        <span>{teamsArr[1] ? teamsArr[1].slice(0, 5) : ''}{teamsArr[1] ? '...' : ''}{teamsArr[2] ? ',' : ''}</span>
                        <span>{teamsArr[2] ? teamsArr[2].slice(0, 5) : ''}{teamsArr[2] ? '...' : ''}</span>
                    </Tooltip>
                );
            },
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.mergeTeams.localeCompare(b.mergeTeams),
        },
        {
            title: 'Date Created',
            dataIndex: 'pack_created',
            key: 'pack_created',
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.created_on.localeCompare(b.created_on),
        },
        {
            title: 'Last Modified',
            dataIndex: 'pack_updated',
            key: 'pack_updated',
            defaultSortOrder: 'descend',
            sorter: (a: StickersPacksTable, b: StickersPacksTable) => a.updated_on.localeCompare(b.updated_on),
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'editStickerInfo',
            render: (editStickerInfo: StickersPacksData) => <div><span className="cmnIcon pencil" onClick={() => editStickerPack(editStickerInfo)} ></span></div>,
        },
    ];

    return (
        <Fragment>
            {contextHolder}{loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><ComponentLoader isLoading={loading} children={undefined} /></div>
            ) :
                <Fragment>
                    <div className="manageUsersTableHeading">
                        <span className="itemName">Sticker Packs</span>
                        <div className="addStickerPackWrap searchFilter">
                            <Input
                                size="large"
                                onChange={onSearch}
                                placeholder="Search"
                                value={searchText}
                            />

                            <ul className="actionBtnSec">
                                <li>
                                    <button title="filter" id="filterTaskBtn" className="filterTaskPopover">
                                        <StickersFilter />
                                    </button>
                                </li>
                            </ul>
                            <button className="btnStyle3 addPackBtn" type="button" id="addStickerPack" onClick={() => addNewStickerPack()}>Add Sticker Pack</button>
                        </div>
                    </div>

                    <Spin spinning={tloading}>
                        <div className="userManagementTable">
                            <Table columns={columns} dataSource={stickersTable} pagination={{
                                pageSizeOptions: ["50", "100", "150"],
                                showSizeChanger: true,
                                defaultPageSize: 50,
                                locale: { items_per_page: "" }
                            }} />
                        </div>
                    </Spin>
                </Fragment>
            }
        </Fragment>
    );
};

export default StickersTable;
