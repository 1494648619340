import { FC, Fragment } from "react";
import { message } from "antd";
import templateService from "modules/services/TemplateService/Template.service";
import { useTemplateStore } from "../../../store";
import { useModalStore } from "modules/store/modal.store";
import {
  IBlockDetail,
  ISection,
  IStageDetail,
  ITaskDetail,
} from "modules/models/interfaces/template.interface";

const StageDelete: FC<{
  sectionDetails: ISection;
  stageDetails: IStageDetail;
}> = ({ sectionDetails, stageDetails }) => {
  const { sections, setSections } = useTemplateStore();
  const { modalState, setModalState } = useModalStore();
  const { templateDetails } = useTemplateStore();

  const deleteStageHandler = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              deleteItemDetails: {
                type: "stage",
                id: stageDetails.stage_id,
              },
            },
          };
        }
        return eachSection;
      })
    );

    setModalState({
      modalHeader: (
        <Fragment>
          <h3>Delete Stage</h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => modalCloseHandler()}
          >
            close
          </span>
        </Fragment>
      ),
      modalBody: (
        <Fragment>
          <p>
            Are you sure you want to delete this stage{" "}
            <strong>{stageDetails.stage_name}</strong>?
          </p>
          <p>This will delete the entire column of stage cells.</p>
        </Fragment>
      ),
      modalFooter: (
        <Fragment>
          <button
            className="noStyle cancel_deleteFolder"
            onClick={() => modalCloseHandler()}
          >
            Cancel
          </button>

          <button
            className="deleteBtnStyle"
            onClick={() =>
              (async () => {
                await confirmAction();
              })()
            }
          >
            Delete Stage
          </button>
        </Fragment>
      ),
      shouldShow: true,
    });
  };

  const modalCloseHandler = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          let section = {
            ...eachSection,
          };

          delete section.grid_list_details?.deleteItemDetails;
        }
        return eachSection;
      })
    );

    setModalState({ ...modalState, shouldShow: false });
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const groupGridBlock = (block: any[], task: any[] | null) => {
    let blocks: any[] = [];
    if (task) {
      const taskIds = task.sort((a, b) => a.task_rank - b.task_rank).map((item) => item.task_id);
      taskIds.forEach((ele) => {
        let newBlock = block.filter((item) => item.task_id === ele);
        blocks.push(newBlock);
      });
    } else {
      let newBlock = block.filter((item) => item.task_id === 0);
      blocks.push(newBlock);
    }

    return blocks;
  };

  const confirmAction = async (): Promise<void> => {
    try {
      const response = await templateService.deleteGridStage({
        template_id: templateDetails?.template_id!,
        section_id: +sectionDetails.section_id,
        stage_id: stageDetails.stage_id,
      });

      if (response.messageId === 1) {
        let blockDetails =
          (sectionDetails.grid_list_details?.block_details! || []).flat();
        let newStageDetails = sectionDetails.grid_list_details?.stage_details!;
        let updatedBlockList = (blockDetails || [])?.filter(
          (ele: IBlockDetail) => ele.stage_id !== stageDetails.stage_id
        )!;
        let updatedStageList = (newStageDetails || []).filter(
          (ele: IStageDetail) => ele.stage_id !== stageDetails.stage_id
        )!;

        let newTaskDetails = sectionDetails.grid_list_details?.task_details!;
        let updateTaskDetails = (newTaskDetails || []).map((eleOuter: ITaskDetail) => {
          let completedBlockCount = (updatedBlockList || []).filter(
            (ele: IBlockDetail) =>
              ele.task_id === eleOuter.task_id && ele.block_status_id === 3
          )!;

          let totalBlockCount = (updatedBlockList || []).filter(
            (ele: IBlockDetail) => ele.task_id === eleOuter.task_id
          )!;

          return {
            ...eleOuter,
            total_block_count: totalBlockCount.length,
            completed_block_count: completedBlockCount.length,
          };
        });

        setSections(
          sections?.map((eachSection) => {
            if (eachSection.section_id === sectionDetails.section_id) {
              let sectionDetails = {
                ...eachSection,
                grid_list_details: {
                  ...eachSection.grid_list_details,
                  task_details: updateTaskDetails.length > 0 ? updateTaskDetails : null,
                  stage_details:
                    updatedStageList.length > 0 ? updatedStageList : null,
                  block_details:
                    updatedBlockList.length > 0
                      ? (groupGridBlock(
                          updatedBlockList,
                          updateTaskDetails
                        ) as Array<IBlockDetail[]>)
                      : null,
                },
              };

              delete sectionDetails.grid_list_details.deleteItemDetails;
              return sectionDetails;
            }
            return eachSection;
          })
        );
      } else {
        message.error("Error", 3);
      }

      modalClose();
    } catch (err) {
      message.error("Error", 3);
      modalClose();
    }
  };

  return (
    <span
      className="deleteBin cmnIcon"
      onClick={() => deleteStageHandler()}
    ></span>
  );
};

export default StageDelete;
