import React, { useState } from "react";
import Task from "./Task";
import { RBAC } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import AddTask from "../AddTask";
import { ISection, ISectionDetail } from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../store";

enum TaskColumnSortIds {
  TASK_STATUS = "task_status_id",
  TASK_PRIORITY = "task_priority_id",
  TASK_DUE_DATE = "due_date",
  TASK_NAME = "task_name",
  TASK_ASSIGNEE = "assignee_name",
  TASK_UPDATED_ON = "updated_on",
}

enum TaskColumnSortOrder {
  ASC = "asc",
  DESC = "desc",
}

const TaskListDetails: React.FC<{
  taskDetails?: ISectionDetail[];
  sectionToggleState: "collapsed" | "expanded";
  sectionId: string | number;
  section?: ISection;
}> = ({ taskDetails, sectionToggleState, sectionId, section }) => {
  const { sections, setSections } = useTemplateStore();
  const [sortOptions, setSortOptions] = useState(() => {
    const x = section?.section_details?.filter(
      (eachTask: any) => eachTask.due_date !== null
    )?.length;
    if (!x) {
      return {
        sortOrder: TaskColumnSortOrder.ASC,
        sortColumn: TaskColumnSortIds.TASK_UPDATED_ON,
      };
    }
    return {
      sortOrder: TaskColumnSortOrder.DESC,
      sortColumn: TaskColumnSortIds.TASK_DUE_DATE,
    };
  });

  const getTaskTableBodyJsx = () => {
    if (taskDetails && taskDetails.length > 0) {
      let _taskDetails = taskDetails;
      if (_taskDetails.length > 0) {
        return _taskDetails.map((eachTask) => (
          <Task
            key={eachTask.task_id}
            eachTaskDetail={eachTask}
            sectionId={sectionId}
            section={section}
          />
        ));
      } else {
        let newSection: any = {
          ...section,
        };
        delete newSection.hide_completed_tasks;
        delete newSection.show_own_tasks;
        let existingSections = sections?.filter(
          (ele: any) => ele.section_id !== newSection.section_id
        );
        let updatedSection = [];

        if (existingSections && existingSections?.length > 0) {
          updatedSection = [...[newSection], ...existingSections].sort((a, b) =>
            a.section_rank > b.section_rank ? 1 : -1
          );
        } else {
          updatedSection.push(newSection);
        }

        let newSections = [...updatedSection];
        setSections(newSections);

        return taskDetails.map((eachTask) => (
          <Task
            key={eachTask.task_id}
            eachTaskDetail={eachTask}
            sectionId={sectionId}
            section={section}
          />
        ));
      }
    }
  };

  const sortTasks = (sortColumn: TaskColumnSortIds) => {
    setSortOptions((prev) => ({
      sortColumn: sortColumn,
      sortOrder:
        prev.sortOrder === TaskColumnSortOrder.ASC
          ? TaskColumnSortOrder.DESC
          : TaskColumnSortOrder.ASC,
    }));

    let _sections = sections?.map((eachSection: any) => {
        if (eachSection.section_id === sectionId) {
          let newSection = {
            ...eachSection,
          };
          newSection.section_details?.sort((a: any, b: any) => {
            // equal items sort equally
            if (a[sortColumn] === b[sortColumn]) {
              return 0;
            }

            // nulls sort after anything else
            if (a[sortColumn] === null) {
              return 1;
            }
            if (b[sortColumn] === null) {
              return -1;
            }

            // otherwise, if we're ascending, lowest sorts first
            if (sortOptions.sortOrder === TaskColumnSortOrder.ASC) {
              return a[sortColumn] < b[sortColumn] ? -1 : 1;
            }

            // if descending, highest sorts first
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          });

          return {
            ...newSection,
            sort_column: sortOptions.sortColumn,
            sort_order: sortOptions.sortOrder,
          };
        }
        return eachSection;
      })
    setSections(_sections);
  };

  const getSortIconClass = (
    sortColumn: TaskColumnSortIds,
    sortOrder: TaskColumnSortOrder
  ) => {
    if (sortOptions.sortColumn === sortColumn) {
      if (sortOptions.sortOrder === TaskColumnSortOrder.DESC) {
        return "sort-arrow-up";
      } else {
        return "sort-arrow-down";
      }
    } else {
      return "double-sort-arrow";
    }
  };

  return (
    <div
      className={`tasktable projectView ${
        sectionToggleState === "collapsed" ? "toggle-tasktable" : ""
      }`}
    >
      <table>
        <thead>
          <tr>
          <th onClick={() => sortTasks(TaskColumnSortIds.TASK_NAME)}>
              <div className="th-div">
                Status{" "}
              </div>
            </th>
            <th onClick={() => sortTasks(TaskColumnSortIds.TASK_NAME)}>
              <div className="th-div">
                Task{" "}
                <span
                  className={`cmnIcon ${getSortIconClass(
                    TaskColumnSortIds.TASK_STATUS,
                    TaskColumnSortOrder.DESC
                  )}`}
                />
              </div>
            </th>
            <th >
            <div className="th-div">
                Due Date{" "}
              </div>
            </th>
            <th >
            <div className="th-div">
                Assignee{" "}
              </div>
            </th>
            
            <th></th>
          </tr>
        </thead>
        <tbody>{getTaskTableBodyJsx()}</tbody>
      </table>
      <RBAC
        allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}
      >
        {sectionToggleState === "expanded" || !sectionToggleState ? (
          <AddTask sectionId={sectionId} />
        ) : (
          <></>
        )}
      </RBAC>
    </div>
  );
};

export default TaskListDetails;
