import { FC, Fragment, useState } from "react";
import cryptoRandomString from "crypto-random-string";
import {
  ISection,
  IBlockDetail,
} from "modules/models/interfaces/template.interface";

const Block: FC<{ sectionDetails: ISection }> = ({ sectionDetails }) => {
  const [showDropDown, setShowDropDown] = useState<number>(0);

  const getClassNameByFilter = (
    sectionDetails: ISection,
    blockDetails: IBlockDetail
  ) => {
    if (sectionDetails.show_own_tasks && blockDetails.assignee_id !== null) {
      return true;
    }

    if (
      sectionDetails.hide_completed_tasks &&
      blockDetails.block_status_id === 3
    ) {
      return true;
    }

    if (
      sectionDetails.show_own_tasks === true &&
      sectionDetails.hide_completed_tasks === true &&
      blockDetails.assignee_id !== null &&
      blockDetails.block_status_id === 3
    ) {
      return true;
    }

    return false;
  };

  const isItemToDelete = (blockDetails: IBlockDetail) => {
    if (sectionDetails.grid_list_details?.deleteItemDetails?.type === "task") {
      return (
        sectionDetails.grid_list_details?.deleteItemDetails?.id ===
        blockDetails.task_id
      );
    }

    if (sectionDetails.grid_list_details?.deleteItemDetails?.type === "stage") {
      return (
        sectionDetails.grid_list_details?.deleteItemDetails?.id ===
        blockDetails.stage_id
      );
    }

    return false;
  };

  return (
    <Fragment>
      {sectionDetails.grid_list_details?.block_details?.map((eleOuter: any) => (
        <ul className="subTaskRow" key={cryptoRandomString({ length: 5 })}>
          {/* {eleOuter.grid_list_details} */}
          {eleOuter?.map((ele: IBlockDetail) => (
            <li
              className={`${
                ele.block_id === showDropDown ? "toggle-z-index" : ""
              } ${ele.block_status_id === 3 ? "doneTaskCard" : ""} ${
                getClassNameByFilter(sectionDetails, ele)
                  ? "nonHighlightedTaskCard"
                  : ""
              } ${isItemToDelete(ele) ? "deleteBlockHighlight" : ""}`}
              key={ele.block_id}
            >
              <div className="taskCardDevClass noAssignee statusCard">
                {/* <div className="userWrap d-flex"> */}
                <div className="userBlkWrap">
                  <div className="userAvtr"></div>
                </div>
                <div className="status">
                  <span className="cmnIcon calendar"></span>
                </div>
                {/* </div> */}
              </div>
            </li>
          ))}
        </ul>
      ))}
    </Fragment>
  );
};

export default Block;
