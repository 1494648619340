import React from "react";
import Routes from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithNavigate from "./auth/authProviderWithNavigate";
import Modal from "modules/sharedComponents/components/Modal/Modal";

const App: React.FC<{}> = () => {



  return (
    <Router>
      <Auth0ProviderWithNavigate>
          <Routes />
          <Modal />
      </Auth0ProviderWithNavigate>
    </Router>
  );
};

export default App;
