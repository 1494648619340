import { useEffect } from "react";
import { Tooltip } from "antd";
import { TaskListView } from "modules/models/enums/taskListView";

interface IAssigneeDetails {
  assignee_id?: number | null;
  assignee_name?: string;
  assignee_email?: string;
  assignee_dp?: string;
}

const TaskAssignee: React.FC<{
  assigneeDetails?: IAssigneeDetails;
  taskAssigneeChangeHandler: (user_id: number | null) => void;
  showAssigneeDropdown: boolean;
  setShowAssigneeDropdown: Function;
  viewType?: TaskListView;
}> = ({
  assigneeDetails,
  taskAssigneeChangeHandler,
  showAssigneeDropdown,
  setShowAssigneeDropdown,
  viewType,
}) => {
  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowAssigneeDropdown(false);
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  const getAssigneeJsx = () => {
    return (
      <>
        <div
          className="unassignUserBlkWrap userBlkWrap"
          onClick={() => setShowAssigneeDropdown((prev: any) => !prev)}
        >
          <Tooltip title="Unassigned" color={"#2E364C"} placement="bottom">
            <span className="unassign"></span>
          </Tooltip>
          <div className="userName textMuted">Unassigned</div>
        </div>
        {/* <div
            className={`assigneeList ${
              showAssigneeDropdown &&
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                projectDetails?.associated_role_id
              )
                ? "show"
                : ""
            }`}
          >
            <ul>
              <li
                className="unassignedTask"
                onClick={() => taskAssigneeChangeHandler(null)}
                key={0}
              >
                <div className="userBlkWrap">
                  <div className="userPart">
                    <div className="userAvtr">
                      <span className="cmnIcon unassign"></span>
                    </div>
                    <div className="userName">
                      {" "}
                      {taskListCmsData?.task_assignee_custom_text[0]}
                    </div>
                  </div>
                </div>
              </li>
              {getAssigneeListJsx()}
            </ul>
            <span
              className="closeAssigneeList cmnIcon closeIconn"
              onClick={() => setShowAssigneeDropdown(false)}
            ></span>
          </div> */}
      </>
    );
  };

  if (viewType === TaskListView.CARD) {
    return <>{getAssigneeJsx()}</>;
  }

  return <td className="assignWrap">{getAssigneeJsx()}</td>;
};

export default TaskAssignee;
