import { FC, Fragment } from "react";
import { Tooltip } from "antd";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import {
  ISection,
  ITaskDetail,
} from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../../store";

const TaskNameInputField: FC<{
  sectionDetails: ISection;
  taskDetails: ITaskDetail;
  setTaskName: React.Dispatch<React.SetStateAction<string>>;
  taskName: string;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateTask: (taskName: any, taskId: any) => Promise<void>;
  revertChange: (taskId: number, event: string) => void;
}> = ({
  taskDetails,
  taskName,
  setTaskName,
  submitChange,
  updateTask,
  sectionDetails,
  revertChange,
}) => {
  const { hasPermissions } = useRbac();
  const { sections, setSections } = useTemplateStore();

  const checkIfRevertRequired = (taskDetails: ITaskDetail) =>
    taskDetails.backupName === taskDetails.task_name ||
    taskDetails.task_name === "";

  const onKeyUpEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (taskDetails?.isNew) {
      if (e.key === "Enter") {
        submitChange(taskName, "Enter");
      }

      if (e.key === "Escape") {
        submitChange("", "Escape");
      }
    } else {
      if (e.key === "Enter") {
        if (checkIfRevertRequired(taskDetails)) {
          revertChange(taskDetails.task_id, "Enter");
        } else {
          document.getElementById(`${taskDetails.task_id}_input_field`)?.blur();
        }
      }

      if (e.key === "Escape") {
        revertChange(taskDetails.task_id, "Escape");
      }
    }
  };

  const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taskDetails?.isNew) {
      setTaskName(e.target.value);
    } else {
      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                task_details: getUpdateTaskDetails(
                  eachSection,
                  taskDetails,
                  e.target.value
                )!,
              },
            };
          }
          return eachSection;
        })
      );
    }
  };

  const onBlurEventHandler = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (taskDetails?.isNew) {
      submitChange(taskName, "Blur");
    } else {
      if (checkIfRevertRequired(taskDetails)) {
        revertChange(taskDetails.task_id, "Blur");
      } else {
        updateTask(taskDetails.task_name, taskDetails.task_id);
      }
    }
  };

  const getUpdateTaskDetails = (
    section: ISection,
    taskDetails: ITaskDetail,
    newName: string
  ) => {
    if (section.grid_list_details?.task_details) {
      let task = [{ ...taskDetails }];
      if (!task[0]?.backupName) {
        task[0].backupName = task[0].task_name;
      }
      task[0].task_name = newName;

      let existingTask = section.grid_list_details.task_details.filter(
        (ele: ITaskDetail) => ele.task_id !== task[0].task_id
      );
      return [...existingTask, ...task].sort(
        (a, b) => a.task_rank - b.task_rank
      );
    }
  };

  const makeFieldEditable = (taskDetails: ITaskDetail) => {
    if (hasPermissions([ERbacPermissions.TEMPLATE_VIEW])) {
      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            let task = [{ ...taskDetails }];
            task[0].isEdit = true;
            let existingTask =
              eachSection.grid_list_details?.task_details!.filter(
                (ele: ITaskDetail) => ele.task_id !== task[0].task_id
              ) || [];
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                task_details: [...existingTask, ...task].sort(
                  (a, b) => a.task_rank - b.task_rank
                ),
              },
            };
          }
          return eachSection;
        })
      );
    }
  };

  const getTaskNameFieldJsx = (taskDetails: ITaskDetail) => {
    if (taskDetails.isNew) {
      return (
        <input
          type="text"
          id={`${taskDetails.task_id}_input_field`}
          placeholder="Add New Task Name"
          value={taskName}
          aria-label="sign up / login"
          onChange={(e) => onChangeEventHandler(e)}
          maxLength={100}
          onKeyUp={(e) => onKeyUpEventHandler(e)}
          onBlur={(e) => onBlurEventHandler(e)}
          autoFocus={taskDetails?.isNew ? true : false}
        />
      );
    }

    if (taskDetails.isEdit) {
      return (
        <input
          type="text"
          id={`${taskDetails.task_id}_input_field`}
          placeholder="Add Task Name"
          value={taskDetails.task_name}
          aria-label="sign up / login"
          onChange={(e) => onChangeEventHandler(e)}
          maxLength={100}
          onKeyUp={(e) => onKeyUpEventHandler(e)}
          onBlur={(e) => onBlurEventHandler(e)}
          autoFocus={taskDetails?.isEdit ? true : false}
        />
      );
    }

    return (
      // <span className="taskNameSpan" onClick={() => makeFieldEditable(taskDetails)}> {taskDetails.task_name} </span>

      <Fragment>
        {taskDetails.task_name.length > 30 ? (
          <Tooltip
            title={taskDetails.task_name}
            color={"#2E364C"}
            placement="top"
          >
            <span
              className="taskNameSpan"
              onClick={() => makeFieldEditable(taskDetails)}
            >
              {" "}
              {taskDetails.task_name}{" "}
            </span>
          </Tooltip>
        ) : (
          <span
            className="taskNameSpan"
            onClick={() => makeFieldEditable(taskDetails)}
          >
            {" "}
            {taskDetails.task_name}{" "}
          </span>
        )}
      </Fragment>
    );
  };

  return getTaskNameFieldJsx(taskDetails);
};

export default TaskNameInputField;
