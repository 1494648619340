import { create } from 'zustand';

interface ViewTeamInfo {
  id: number,
  key: string,
  team_name: string,
  accountStatus: string,
  account_created: string,
  active_user: number,
  team_type: string,
  total_project: number,
  created_on: string
};

export interface TeamsTableData {
  key: string,
  team_name: string,
  accountStatus: string,
  account_created: string,
  active_user: number,
  team_type: string,
  total_project: number,
  created_on: string,
  view_team_info: {
    id: number,
    key: string,
    team_name: string,
    accountStatus: string,
    account_created: string,
    active_user: number,
    team_type: string,
    total_project: number,
    created_on: string,
  }
}

interface AppState {
  isDrawer: boolean;
  setIsDrawer: (isDrawer: boolean) => void;
  tloading: boolean;
  setTLoading: (tloading: boolean) => void;
  teamInfo: ViewTeamInfo;
  setTeamInfo: (teamInfo: ViewTeamInfo) => void;
  tableData: TeamsTableData[];
  setTableData: (tableData: TeamsTableData[]) => void;
  btableData: TeamsTableData[];
  setBTableData: (tableData: TeamsTableData[]) => void;

  filterStatus: boolean[];
  setFilterStatus: (filterStatus: boolean[]) => void;
  filterTeamsType: string[];
  setFilterTeamsType: (filterTeamsType: string[]) => void;
  filterStartDate: string;
  setFilterStartDate: (filterStartDate: string) => void;
  filterEndDate: string;
  setFilterEndDate: (filterEndDate: string) => void;  
}

export const useTeamsStore = create<AppState>((set) => ({
  isDrawer: false,
  setIsDrawer: (isDrawer) => set({ isDrawer }),

  tloading: false,
  setTLoading: (tloading) => set({ tloading }),

  teamInfo: {
    id: 0,
    key: '',
    team_name: '',
    accountStatus: '',
    account_created: '',
    active_user: 0,
    team_type: '',
    total_project: 0,
    created_on: ''
  },
  setTeamInfo: (teamInfo) => set({ teamInfo }),

  tableData: [],
  setTableData: (tableData) => set({ tableData }),

  btableData: [],
  setBTableData: (btableData) => set({ btableData }),

  filterStatus: [],
  setFilterStatus: (filterStatus) => set({filterStatus}),

  filterTeamsType: [],
  setFilterTeamsType: (filterTeamsType) => set({filterTeamsType}),

  filterStartDate: '',
  setFilterStartDate: (filterStartDate) => set({filterStartDate}),

  filterEndDate: '',
  setFilterEndDate: (filterEndDate) => set({filterEndDate}),
}));

export { };



// const [tableData, setTableData] = useState<TeamsTableData[]>([]);