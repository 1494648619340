import { FC, Fragment } from "react";
import { message } from "antd";
import { useTemplateStore } from "../../../store";
import templateService from "modules/services/TemplateService/Template.service";
import { useModalStore } from "modules/store/modal.store";
import {
    IBlockDetail,
  ISection,
  ITaskDetail,
} from "modules/models/interfaces/template.interface";

const TaskDelete: FC<{
  sectionDetails: ISection;
  taskDetails: ITaskDetail;
}> = ({ sectionDetails, taskDetails }) => {
  const { sections, setSections } = useTemplateStore();
  const { modalState, setModalState } = useModalStore();
  const { templateDetails } = useTemplateStore();

  const deleteTaskHandler = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              deleteItemDetails: {
                type: "task",
                id: taskDetails.task_id,
              },
            },
          };
        }
        return eachSection;
      })
    );

    setModalState({
      modalHeader: (
        <Fragment>
          <h3>Delete Task</h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => modalCloseHandler()}
          >
            close
          </span>
        </Fragment>
      ),
      modalBody: (
        <Fragment>
          <p>
            Are you sure you want to delete this task{" "}
            <strong>{taskDetails.task_name}</strong>?
          </p>
          <p>This will delete the entire row of task cells.</p>
        </Fragment>
      ),
      modalFooter: (
        <Fragment>
          <button
            className="noStyle cancel_deleteFolder"
            onClick={() => modalCloseHandler()}
          >
            Cancel
          </button>

          <button
            className="deleteBtnStyle"
            onClick={() =>
              (async () => {
                await confirmAction();
              })()
            }
          >
            Delete Task
          </button>
        </Fragment>
      ),
      shouldShow: true,
    });
  };

  const modalCloseHandler = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          let section = {
            ...eachSection,
          };

          delete section.grid_list_details?.deleteItemDetails;
        }
        return eachSection;
      })
    );

    setModalState({ ...modalState, shouldShow: false });
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const groupGridBlock = (block: any[], task: any[] | null) => {
    let blocks: any[] = [];
    if (task) {
      const taskIds = task
        .sort((a, b) => a.task_rank - b.task_rank)
        .map((item) => item.task_id);

      taskIds.forEach((ele) => {
        let newBlock = block.filter((item) => item.task_id === ele);
        blocks.push(newBlock);
      });
    } else {
      let newBlock = block.filter((item) => item.task_id === 0);
      blocks.push(newBlock);
    }

    return blocks;
  };

  const confirmAction = async (): Promise<void> => {
    try {
      const response = await templateService.deleteGridTask({
        template_id: templateDetails?.template_id!,
        section_id: +sectionDetails.section_id,
        task_id: taskDetails.task_id,
      });

      if (response.messageId === 1) {
        let blockDetails =
          (sectionDetails.grid_list_details?.block_details! || []).flat();
        let newTaskDetails = sectionDetails.grid_list_details?.task_details!;
        let updatedBlockList = (blockDetails || [])?.filter(
          (ele: IBlockDetail) => ele.task_id !== taskDetails.task_id
        )!;
        let updatedTaskList = (newTaskDetails || []).filter(
          (ele: ITaskDetail) => ele.task_id !== taskDetails.task_id
        )!;

        let newTaskList = updatedTaskList.length > 0 ? updatedTaskList : null;

        setSections(
          sections?.map((eachSection) => {
            if (eachSection.section_id === sectionDetails.section_id) {
              let sectionDetails = {
                ...eachSection,
                grid_list_details: {
                  ...eachSection.grid_list_details,
                  task_details: newTaskList,
                  block_details:
                    updatedBlockList.length > 0
                      ? (groupGridBlock(updatedBlockList, newTaskList) as Array<
                          IBlockDetail[]
                        >)
                      : null,
                },
              };

              delete sectionDetails.grid_list_details.deleteItemDetails;
              return sectionDetails;
            }
            return eachSection;
          })
        );
      } else {
        message.error("Error", 3);
      }

      modalClose();
    } catch (err) {
      message.error("Error", 3);
      modalClose();
    }
  };

  return (
    <span
      className="deleteBin cmnIcon"
      onClick={() => deleteTaskHandler()}
    ></span>
  );
};

export default TaskDelete;
