import React, { Fragment, useEffect, useState } from 'react';
import { Input, message, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import teamsService from 'modules/services/TeamsService/Teams.service';
import { ComponentLoader } from 'modules/components';
import { useTeamsStore } from './teamsStore';
import { useAuth0 } from '@auth0/auth0-react';
import apiService from 'modules/services/Api.service';
import TeamFilterButton from './TeamFilterButton';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { TeamsTableData } from 'modules/models/interfaces';



const TeamManageTable: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true);
    const [, contextHolder] = message.useMessage();
    const { tloading, setTLoading } = useTeamsStore();
    const { tableData, setTableData } = useTeamsStore();
    const [, setDatas] = useState<TeamsTableData[]>([])
    const { isDrawer, setIsDrawer } = useTeamsStore();
    const { setTeamInfo } = useTeamsStore();
    const { hasPermissions } = useRbac();
    const { setBTableData } = useTeamsStore();
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pgloading, setPgloading] = useState(false);
    const [totalRow, setTotalRow] = useState(100);

    const {filterStatus} = useTeamsStore();
    const {filterTeamsType} = useTeamsStore();
    const {filterStartDate} = useTeamsStore();
    const {filterEndDate} = useTeamsStore();

    interface ViewTeamInfo {
        id: number,
        key: string,
        team_name: string,
        accountStatus: string,
        account_created: string,
        active_user: number,
        team_type: string,
        total_project: number,
        created_on: string

    };

    const getTeamsDetails = async () => {
        try {
            setPgloading(true);
            const getTeamsDetailsPayload = {
                page: page,
                pageSize: pageSize,
                search_text: searchText,
                is_active: filterStatus,
                org_type: filterTeamsType,
                start_date: filterStartDate,
                end_date: filterEndDate,
            }
            const response = await teamsService.getTeamsDetails(getTeamsDetailsPayload);
            const totalData = response?.data?.totalRow;
            setTotalRow(totalData);
            const data: TeamsTableData[] = response?.data?.teams?.map((item: any, index: number) => ({
                key: item.org_key,
                team_name: item.team_name.trim(),
                accountStatus: checkAccountStatus(item.is_active),
                account_created: dateConvert(item.created_on),
                active_user: item.total_active_user,
                team_type: item.org_type,
                total_project: item.total_project,
                created_on: item.created_on.trim(),
                view_team_info: {
                    id: item.id,
                    key: item.org_key,
                    team_name: item.team_name.trim(),
                    accountStatus: checkAccountStatus(item.is_active),
                    account_created: dateConvert(item.created_on),
                    active_user: item.total_active_user,
                    team_type: item.org_type,
                    total_project: item.total_project,
                    created_on: item.created_on.trim(),
                }
            }));
            setTableData(data);
            setDatas(data);
            setBTableData(data);
            if (response) {
                setLoading(false);
                setTLoading(false);
                setPgloading(false);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            setTLoading(false);
            setPgloading(false);
        }
    };

    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (hasPermissions([ERbacPermissions.TEAM_VIEW])) {
            getTeamsDetails();
        }
    }, []);

    useEffect(() => {
        if (hasPermissions([ERbacPermissions.TEAM_VIEW])) {
            getTeamsDetails();
        }
    }, [tloading]);

    const editTeamDrawer = (_view_team_info: ViewTeamInfo) => {
        setIsDrawer(!isDrawer);
        setTeamInfo(_view_team_info);
    };


    const checkAccountStatus = (is_active: boolean) => {
        if (is_active === true) {
            return ("Active")
        }
        else if (is_active === false) {
            return ("In-active")
        }
        return "";
    }

    const dateConvert = (stringDate: string) => {
        const date = new Date(stringDate);

        const normalDate = date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });

        return (normalDate);
    }

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        getTeamsDetails();
    }, [page, pageSize]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPage(1);
            getTeamsDetails();
        }, 600)
        return () => clearTimeout(timer)
    }, [searchText, filterStatus, filterTeamsType, filterStartDate, filterEndDate]);

    const columns: ColumnsType<TeamsTableData> = [
        {
            title: 'Team Name',
            dataIndex: 'team_name',
            filterMode: 'tree',
            key: 'team_name',
            filterSearch: true,
            onFilter: (value, record) => record.team_name.includes(value as string),
            width: '30%',
            sorter: (a: TeamsTableData, b: TeamsTableData) => a.team_name.localeCompare(b.team_name),
        },
        {
            title: 'Account Status',
            dataIndex: 'accountStatus',
            key: 'accountStatus',
            sorter: (a: TeamsTableData, b: TeamsTableData) => a.accountStatus.localeCompare(b.accountStatus),
            render: (accountStatus: string) => {
                if (accountStatus === 'Active') {
                    return <span className="activeBtn">Active</span>;
                } else if (accountStatus === 'In-active') {
                    return <span className="inactiveBtn">In-active</span>;
                }
                return null;
            },
        },
        {
            title: 'Account Created',
            dataIndex: 'account_created',
            key: 'account_created',
            sorter: (a: TeamsTableData, b: TeamsTableData) => a.created_on.localeCompare(b.created_on),
        },
        {
            title: 'Active Users',
            dataIndex: 'active_user',
            key: 'active_user',
            sorter: (a, b) => a.active_user - b.active_user,
        },
        {
            title: 'Projects',
            dataIndex: 'total_project',
            key: 'total_project',
            sorter: (a, b) => a.total_project - b.total_project,
        },
        {
            title: 'Team Type',
            dataIndex: 'team_type',
            key: 'team_type',
            sorter: (a: TeamsTableData, b: TeamsTableData) => a.team_type.localeCompare(b.team_type),
        },

        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'view_team_info',
            render: (view_team_info: ViewTeamInfo) => <div><span className="cmnIcon eye" onClick={() => editTeamDrawer(view_team_info)}></span></div>,
        },
    ];

    return (
        <Fragment>
            {contextHolder}{loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><ComponentLoader isLoading={loading} children={undefined} /></div>
            ) :
                <div className="inner-container">
                    <div className="manageUsersTableHeading">
                        <span className="itemName">Manage Teams</span>
                        <div className="searchFilter">
                            <Input
                                size="large"
                                onChange={onSearch}
                                placeholder="Search Team"
                                value={searchText}
                            />
                            <ul className="actionBtnSec">
                                <li>
                                    <button title="filter" id="filterTaskBtn" className="filterTaskPopover">

                                        <TeamFilterButton />

                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Spin spinning={tloading}>
                        <div className="teamManagementTable userManagementTable">
                            <Table
                                loading={pgloading}
                                columns={columns}
                                dataSource={tableData}
                                pagination={{
                                    // pageSizeOptions: ["50", "100", "150"],
                                    showSizeChanger: true,
                                    total: totalRow,
                                    current: page,
                                    pageSize: pageSize,
                                    onChange: (page, pageSize) => {
                                        setPage(page);
                                        setPageSize(pageSize);
                                    }

                                }} />
                        </div>
                    </Spin>
                </div>
            }
        </Fragment>
    );
};

export default TeamManageTable;