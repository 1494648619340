import React, { Fragment, useEffect, useState } from 'react';
import { Table, Tooltip, message, Spin } from 'antd';
import { UsersData } from 'modules/models/interfaces';
import { useAuth0 } from '@auth0/auth0-react';
import apiService from 'modules/services/Api.service';
import userService from 'modules/services/UsersManageService/User.manage.service';
import { ComponentLoader } from 'modules/components';
import { useStore } from './store';
import type { ColumnsType } from 'antd/es/table';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useRbac } from 'auth/rbac/rbac';

interface User {
  key: number;
  name: string;
  email: string;
  accountStatus_Email: AccountStatusEmail;
  roles: string[];
}

interface EmailNameRoles {
  name: string;
  email: string;
  user_id: number;
  roles: string[];
  status: boolean;
  invite_accepted: boolean;
}

interface AccountStatusEmail {
  accountStatus: string;
  email: string;
}

const UserListTable: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { isDrawer, setIsDrawer } = useStore();
  const { setIUser } = useStore();
  const [messageApi, contextHolder] = message.useMessage();
  const { users, setUsers } = useStore();
  const { tloading, setTLoading } = useStore();
  const { hasPermissions } = useRbac();

  const editUserDrawer = (_email_name_roles: EmailNameRoles) => {

    setIsDrawer(!isDrawer);
    setIUser(_email_name_roles);

  };

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    apiService.setTokenGenerator(getAccessTokenSilently);
    if (hasPermissions([ERbacPermissions.USER_VIEW])) {
      getUsersTable();
    }
  }, []);

  const getUsersTable = async () => {
    try {
      const response = await userService.usersTable();
      setUsers(response.data);
      if (response) {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const invite_success = () => {
    messageApi.open({
      type: "success",
      content: "Invitation resend successfully"
    });
  };

  const invite_error = () => {
    messageApi.open({
      type: "error",
      content: "Failed to resend invitation"
    });
  };

  const resendInviteUser = async (emailId: string) => {
    try {
      setTLoading(true);
      apiService.setTokenGenerator(getAccessTokenSilently);
      const response = await userService.userReInvitation({ emailId: emailId });
      console.log('hello', emailId, response);
      if (response.messageId == '1') {
        invite_success();
        setTLoading(false);
      } else {
        invite_error();
        setTLoading(false);
      }

    } catch (error) {
      invite_error();
      setTLoading(false);
    }
  }


  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a: User, b: User) => a.name.localeCompare(b.name),
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: User, b: User) => a.email.localeCompare(b.email),
      render: (email: string) => {
        if (email.length > 150) {
          return (
            <Tooltip title={email}>
              <span>{email.slice(0, 40)}...</span>
            </Tooltip>
          );
        }

        return <span>{email}</span>;
      },
    },

    {
      title: 'Account Status',
      dataIndex: 'accountStatus_Email',
      key: 'accountStatus',
      sorter: (a: User, b: User) => a.accountStatus_Email.accountStatus.localeCompare(b.accountStatus_Email.accountStatus),
      render: (accountStatus_Email: AccountStatusEmail) => {
        if (accountStatus_Email.accountStatus === 'Active') {
          return <span className="activeBtn">Active</span>;
        } else if (accountStatus_Email.accountStatus === 'In-active') {
          return <span className="inactiveBtn">In-active</span>;
        } else if (accountStatus_Email.accountStatus === 'Invite Sent') {
          return (
            <span>
              <button className="resendInvite">Invite Sent</button>
              <a onClick={() => resendInviteUser(accountStatus_Email.email)}>Resend Invite</a>
            </span>
          );
        }
        return null;
      },
    },

    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',

      render: (roles: string[]) => {
        if (roles?.length > 3) {
          const tooltipContent = (
            <div>
              <ul>
                {roles.map((role, index) => (
                  <li key={role}>{role}</li>
                ))}
              </ul>
            </div>
          );

          return (
            <Tooltip title={tooltipContent}>
              <span>
                {roles.slice(0, 3).join(', ')}
                <span style={{ color: 'blue' }}>
                  {roles.length > 3 && ` +${roles.length - 3}`}
                </span>
              </span>
            </Tooltip>
          );
        }

        return <span>{roles?.join(', ')}</span>;
      },

    },

    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'email_name_roles',
      render: (email_name_roles: EmailNameRoles) => <div><span className="cmnIcon pencil" onClick={() => editUserDrawer(email_name_roles)}></span></div>,
    },
  ];

  const checkAccountStatus = (item: UsersData) => {
    if (!item.invite_accepted) {
      return ("Invite Sent")
    }
    else if (item.is_active) {
      return ("Active")
    }
    else if (!item.is_active && item.invite_accepted) {
      return ("In-active")
    }
    else {
      return ("")
    }
  }

  const data: User[] = users.map((item: any, index: number) => ({
    key: index,
    name: item.full_name,
    email: item.email,
    accountStatus_Email: {
      accountStatus: checkAccountStatus(item),
      email: item.email
    },
    roles: item.roles,
    email_name_roles: {
      name: item.full_name,
      email: item.email,
      user_id: item.user_id,
      roles: item.roles_auth_id,
      status: item.is_active,
      invite_accepted: item.invite_accepted
    }
  }));
  
  function onChange(filters: any, sorter: any, extra: any) {
    console.log("params", filters, sorter, extra);
  }

  return (
    <Fragment>
      {contextHolder}{loading ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}><ComponentLoader isLoading={loading} children={undefined} /></div>
      ) :
        <Spin spinning={tloading}>
          <Table columns={columns} pagination={false} dataSource={data} onChange={onChange} />
        </Spin>
      }
    </Fragment>
  );
};

export default UserListTable;
