import { FC, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import AddTaskCTA from "./AddTaskCTA";
import TaskDelete from "./TaskDelete";
import TaskNameInputField from "./TaskNameInputField";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { RBAC } from "auth/rbac/rbac";
import {
  ISection,
  ITaskDetail,
} from "modules/models/interfaces/template.interface";
import MessageThread from "../../MessageThread";
import { SectionTypeMessages } from "modules/models/enums/messages";

const TaskList: FC<{
  sectionDetails: ISection;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateTask: (taskName: any, taskId: any) => Promise<void>;
  setTaskName: React.Dispatch<React.SetStateAction<string>>;
  revertChange: (taskId: number, event: string) => void;
  taskName: string;
}> = ({
  sectionDetails,
  submitChange,
  taskName,
  setTaskName,
  updateTask,
  revertChange,
}) => {
  const isTaskDone = (taskDetails: ITaskDetail) =>
    taskDetails.total_block_count !== 0 &&
    taskDetails.completed_block_count === taskDetails.total_block_count;

  const isItemToDelete = (taskDetails: ITaskDetail) => {
    if (sectionDetails.grid_list_details?.deleteItemDetails?.type === "task") {
      return (
        sectionDetails.grid_list_details?.deleteItemDetails?.id ===
        taskDetails.task_id
      );
    }

    return false;
  };

  return (
    <Fragment>
      {sectionDetails.grid_list_details?.task_details?.map((ele, index) => (
        <Draggable
          key={ele.task_id}
          draggableId={`${ele.task_id}`}
          index={index}
          isDragDisabled={
            sectionDetails.grid_list_details?.task_details?.length === 1
          }
        >
          {(provided: any) => {
            return (
              <li
                className={`${isTaskDone(ele) ? "doneLeftProgressWrap" : ""} ${
                  isItemToDelete(ele) ? "deleteTaskHighlight" : ""
                }`}
                key={ele.task_id}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TaskNameInputField
                  taskDetails={ele}
                  taskName={taskName}
                  setTaskName={setTaskName}
                  submitChange={submitChange}
                  updateTask={updateTask}
                  sectionDetails={sectionDetails}
                  revertChange={revertChange}
                />
                {!ele?.isNew && (
                  <MessageThread
                    sectionId={sectionDetails.section_id}
                    taskId={ele.task_id}
                    sectionType={SectionTypeMessages.GRID_LIST_TASK}
                    threadName={ele.task_name}
                    sectionName={sectionDetails.section_name!}
                    taskDetails={ele}
                  />
                )}
                {!ele?.isNew && (
                  <Fragment>
                    {/* <TaskProgressBar taskDetails={ele} /> */}
                    <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
                      <TaskDelete
                        taskDetails={ele}
                        sectionDetails={sectionDetails}
                      />
                    </RBAC>
                  </Fragment>
                )}
              </li>
            );
          }}
        </Draggable>
      ))}
      <RBAC allowedPermissions={[ERbacPermissions.TEMPLATE_VIEW]}>
        <AddTaskCTA sectionDetails={sectionDetails} />
      </RBAC>
    </Fragment>
  );
};

export default TaskList;
