import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import React, { Fragment } from "react";
import LeftNav from "../../Dashboard/LeftNav/LeftNav";
import AddStickerPack from "./AddStickerPack";
import StickersTable from "./StickersTable";
import { useStore } from "./store";


const StickerManagement: React.FC<{}> = () => {
    const { isDrawer, setIsDrawer } = useStore();
    const { stickerPack } = useStore();

    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };

    return (
        <RBAC allowedPermissions={[ERbacPermissions.STICKER_VIEW]}>
            <div className={`adminPanelMainWrapper mainWrapper ${isDrawer ? "body-overlay" : ""}`}>
                <LeftNav />
                <div className="adminPanelMainContent mainContent">
                    <Fragment>
                        <div className="contentHeadingPanel">
                            <h2>Sticker Management</h2>
                        </div>
                        <div className="inner-container">
                            <StickersTable />

                            <div className={`addStickerDrawerWrap createNewProjectForm ${isDrawer ? "addToggle" : ""}`} id="AdmincreateNewProjectForm1">
                                <div className="header">
                                    <h2>{stickerPack.pack_id ? "Update " : "New "}Sticker Pack</h2>
                                    <span className="material-icons-outlined close" onClick={handleDrawer}>close</span>
                                </div>
                                <AddStickerPack />
                            </div>
                        </div>
                    </Fragment>
                </div>
            </div>
        </RBAC>
    )
}    
export default withRbac(StickerManagement, { allowedPermissions: [ERbacPermissions.STICKER_VIEW], isAccessFromPage: true });