import { create } from "zustand";
import {
  ISection,
  ITemplateData,
} from "modules/models/interfaces/template.interface";
import { SectionType } from "../../../models/enums/templateEnums";

export interface IOption {
  label: string;
  value: string | number;
}

interface TemplateStore {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  step: number;
  setStep: (step: number) => void;
  templateDetails: ITemplateData;
  setTemplateDetails: (templateDetails: ITemplateData) => void;
  languageOption: IOption[];
  setLanguageOption: (languageOption: IOption[]) => void;
  countryOption: IOption[];
  setCountryOption: (countryOption: IOption[]) => void;
  industryOption: IOption[];
  setIndustryOption: (industryOption: IOption[]) => void;
  teamOption: IOption[];
  setTeamOption: (teamOption: IOption[]) => void;
  organizationFunctionOption: IOption[];
  setOrganizationFunctionOption: (
    organizationFunctionOption: IOption[]
  ) => void;
  sections: ISection[];
  setSections: (sections: ISection[]) => void;
  sectionDetail: ISection | null;
  setSectionDetail: (section: ISection) => void;
  newSection: (sectionType: SectionType) => void;
  showSectionDeleteConfirmation: boolean;
  setShowSectionDeleteConfirmation: (value: boolean) => void;
}

export const useTemplateStore = create<TemplateStore>((set) => ({
  loading: false,
  setLoading: (loading) => set({ loading }),
  step: 0,
  setStep: (step: number) => set({ step }),

  templateDetails: {
    template_id: 0,
    template_name: "",
    template_description: "",
    template_thumbnails: [],
    preview_url: "",
    is_active: false,
    part_of_all_organization: true,
    tags: [],
    teams: [],
    languages: [],
    industries: [],
    organization_functions: [],
    created_on: "",
    updated_on: "",
    sections: [],
    banner_title: "",
    banner_description: "",
    work_type: 0,
    banner: null,
  },
  setTemplateDetails: (templateDetails: ITemplateData) =>
    set({ templateDetails }),

  languageOption: [],
  setLanguageOption: (languageOption: IOption[]) => set({ languageOption }),

  countryOption: [],
  setCountryOption: (countryOption: IOption[]) => set({ countryOption }),

  industryOption: [],
  setIndustryOption: (industryOption: IOption[]) => set({ industryOption }),

  teamOption: [],
  setTeamOption: (teamOption: IOption[]) => set({ teamOption }),

  organizationFunctionOption: [],
  setOrganizationFunctionOption: (organizationFunctionOption: IOption[]) =>
    set({ organizationFunctionOption }),

  sections: [],
  setSections: (sections) => {
    set((state) => {
      return { ...state, sections: [...sections] };
    });
  },

  sectionDetail: null,
  setSectionDetail: (sectionDetail: ISection) => set({ sectionDetail }),

  newSection: (sectionType: SectionType) => {},

  showSectionDeleteConfirmation: false,
  setShowSectionDeleteConfirmation: (value: boolean) =>
    set({ showSectionDeleteConfirmation: value }),
}));
