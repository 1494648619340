import { AES, enc } from "crypto-js";
import { IUserDetails } from "modules/models/interfaces";
import { deviceDetect, isDesktop, isMobile, isTablet } from "react-device-detect";

export const removeStyle = (name: string) => {
  const link = document.getElementById(name);
  if (link) {
    link.remove();
  }
};

export const setStyle = (stylesheet: string, idName: string) => {
  if (stylesheet && stylesheet !== "default") {
    const link = document.createElement("link");
    link.href = `${process.env.REACT_APP_LOCALIZATION_STORAGE_URL}css/${stylesheet}`;
    link.rel = "stylesheet";
    link.id = idName;
    document.head.appendChild(link);
  }
};

export const decryptData = (value: string) => {
  return AES.decrypt(
    value,
    process.env.REACT_APP_DATA_ENCRIPTION_KEY ?? ""
  ).toString(enc.Utf8);
};

export const generateUserAvatar = (userDetails: IUserDetails) => {
  if (!userDetails.profile_picture) {
    let nameSplit = userDetails["full_name"].trim().split(" ");
    if (nameSplit.length === 1) {
      return { key: "string", value: `${nameSplit[0].charAt(0)}` };
    } else {
      return {
        key: "string",
        value: `${nameSplit[0].charAt(0)}${nameSplit[
          nameSplit.length - 1
        ].charAt(0)}`,
      };
    }
  } else {
    return { key: "url", value: userDetails.profile_picture };
  }
};

export const getUserGravatar = (name: string) => {
  let _name = name.trim().split(" ");
  if (_name.length === 1) {
    return _name[0].charAt(0).toUpperCase();
  } else if (_name.length > 1) {
    return _name[0].charAt(0).toUpperCase() + _name[_name.length - 1].charAt(0).toUpperCase();
  } else {
    return name;
  }
}

export const addRemoveOverLay = () => {
  if (document.body.classList.contains('body-overlay')) {
    document.body.classList.remove('body-overlay')
  } else {
    document.body.classList.add('body-overlay')
  }
}

export const checkStatus = (is_active: boolean) => {
  if (is_active) {
    return ("Active");
  }
  if (!is_active) {
    return ("In-active");
  }
  return ("");
}

export const dateConvert = (stringDate: string) => {
  const date = new Date(stringDate);
  const normalDate = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  return (normalDate);
}

export const getDeviceInformation = () => {
  const deviceInformation = deviceDetect(window.navigator.userAgent);
  let deviceType = null;
  if (isDesktop) {
    deviceType = 'desktop';
  }
  else if (isMobile) {
    deviceType = 'mobile';
  }
  else if (isTablet) {
    deviceType = 'tablet';
  }

  return {
    browserName: deviceInformation.browserName ? deviceInformation.browserName : null,
    browserMajorVersion: deviceInformation.browserMajorVersion ? deviceInformation.browserMajorVersion : null,
    browserFullVersion: deviceInformation.browserFullVersion ? deviceInformation.browserFullVersion : null,
    osName: deviceInformation.osName ? deviceInformation.osName : null,
    osVersion: deviceInformation.osVersion ? deviceInformation.osVersion : null,
    userAgent: deviceInformation.userAgent ? deviceInformation.userAgent : window.navigator.userAgent,
    deviceType: deviceType,
  }
}
