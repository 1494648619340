import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, message, Modal, Select, SelectProps, Switch } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { ErrorMessage, Field, Formik, FormikProps } from "formik";
import { ComponentLoader } from "modules/components";
import { AddStickersValue, AddTaskStickerValue } from "modules/models/interfaces";
import apiService from "modules/services/Api.service";
import stickerService from "modules/services/StickerService/Sticker.service";
import taskStickerService from "modules/services/TaskStickerService/TaskSticker.services";
import { Fragment, useEffect, useState } from "react";
import { Stickers, useStore } from "./store";
import UploadSticker from "./UploadSticker";

const AddStickerPack = () => {
    const [status, setStatus] = useState(true);
    const { languages, setLanguage } = useStore();
    const { teams, setTeams } = useStore();
    const { stickers, setStickers } = useStore();
    const [loading, setLoading] = useState(false);
    const { setTLoading } = useStore();
    const [messageApi, contextHolder] = message.useMessage();
    const [selectLanguage, setSelectLanguage] = useState<string[]>([]);
    const [selectTeams, setSelectTeams] = useState<string[]>([]);
    const { isDrawer, setIsDrawer } = useStore();
    const { setSelectedImages } = useStore();
    const { hasPermissions } = useRbac();
    const [selectFeatures_value, setSelectFeatures_value] = useState<any[] | string[]>([]);
    const { features_types, setFeatures_types } = useStore();
    const { stickerPack } = useStore();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const initialValues: AddTaskStickerValue = {
        sticker_pack_name: stickerPack.pack_name || '',
    };

    useEffect(() => {
        setSelectLanguage(stickerPack.languages || []);
        if (stickerPack.part_of_all_organization) {
            setSelectTeams(['all']);
        } else {
            setSelectTeams(stickerPack.teams?.map(team => team.org_key) || ['all']);
        }
        setSelectedImages(stickerPack.pack_configuration);
        setStatus(stickerPack.is_active);
        if (stickerPack.pack_id) {
            setSelectFeatures_value(stickerPack.features || []);
        } else {
            setSelectFeatures_value(features_types);
        }
    }, [stickerPack])

    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };

    const getLangAndTeamAndFeatures = async () => {
        const responseLang = await taskStickerService.getLanguage();
        const langs = responseLang?.data?.map((sdata) => {
            return sdata.language;
        })
        setLanguage(langs);

        const responseTeam = await taskStickerService.getTeams();
        setTeams(responseTeam.data);

        const responseFeature = await stickerService.getFeatures();
        const features = responseFeature?.data?.map((fdata: any) => {
            return fdata.features
        });
        setFeatures_types(features);

        setSelectFeatures_value(features);

        if (responseFeature.messageId == '1') {
            setLoading(false);
        }
    }

    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (hasPermissions([ERbacPermissions.STICKER_VIEW])) {
            getLangAndTeamAndFeatures();
        }

    }, []);

    const options_lang: SelectProps['options'] = [];
    languages.forEach((value: string) => {
        options_lang.push({
            label: value,
            value,
            disabled: false,
        });
    })

    const handleChange_lang = (value: string[]) => {
        if (value.length == 1 && value[0] == '') {
            value.unshift();
        }
        setSelectLanguage(value);
    };


    const options_team: SelectProps['options'] = [];
    options_team.push({
        label: "All Team",
        value: "all",
        disabled: false,
    });

    teams.forEach((tdata) => {
        const value = tdata.name + "(" + tdata.key + ")";
        options_team.push({
            label: value,
            value: tdata.key,
            disabled: false,
        });
    })

    const onChange = (checked: boolean) => {
        setStatus(checked);
    };

    const handleChange_team = (value: string[]) => {
        if (value.length == 1 && value[0] == '') {
            value.unshift();
        }
        setSelectTeams(value);
    };

    const onFeaturesChange = (checkedValues: CheckboxValueType[]) => {
        setSelectFeatures_value(checkedValues)
    };

    const validatePackName = async (value: string) => {
        let error: string | undefined;
        let _value = value.trim();
        if (!value) {
            error = 'Sticker Pack Name  is required';
        }
        else if (_value.length < 4 || _value.length > 50) {
            error = 'Sticker Pack Name must be between 4 and 100 characters';
        } else if ((stickerPack?.pack_id && stickerPack.pack_name !== value) || !stickerPack?.pack_id) {
            try {

                const response = await stickerService.uniqueStickerPackName({ sticker_pack_name: _value });

                const msgId = response.messageId.toString();

                if (msgId === '-1') {
                    error = 'Sticker Pack Name already exist';
                }
            } catch (error) {
                console.error('Error checking sticker pack uniqueness:', error);
            }
        }
        return error;
    };

    const add_success = () => {
        messageApi.open({
            type: "success",
            content: `Sticker pack ${stickerPack.pack_id ? "updated" : "added"} successfully`
        });
    };

    const add_error = () => {
        messageApi.open({
            type: "error",
            content: "Some error occurred. Please try it again!"
        });
    };

    const del_success = () => {
        messageApi.open({
            type: "success",
            content: `Sticker pack deleted successfully`
        });
    };

    const handleOk = async () => {
        deleteSticker();
        setIsModalOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const deleteSticker = async () => {
        setTLoading(true);
        handleDrawer();
        const deletRes = await stickerService.stickersDelete({ pack_id: stickerPack.pack_id });
        if (deletRes.messageId == '1') {
            del_success();
        } else {
            add_error();
        }
        setTLoading(false);
    }


    const handleSubmit = async (values: AddStickersValue, { resetForm }: { resetForm: () => void }) => {
        if (values.sticker_pack_name.length > 0) { handleDrawer(); }
        try {

            const upload_sticker_json = await Promise.all(stickers.map(async (data) => {
                setTLoading(true);
                if (!("sticker_url" in data)) {
                    const urlresponse = await stickerService.uploadStickers({
                        dataURL: (data as Stickers).dataURL,
                        packName: values.sticker_pack_name,
                        pre_bucket_path_suffex: stickerPack?.bucket_path_suffix
                    });

                    const url = urlresponse.message;

                    return ({
                        is_dafault: data.is_dafault,
                        sticker_url: url
                    });
                }
                return data;
            }));

            let part_of_all_organization;
            if (selectTeams.length == 0) {
                part_of_all_organization = true;
            } else {
                if (selectTeams.find(fteam => fteam === "all")) {
                    part_of_all_organization = true;
                } else {
                    part_of_all_organization = false;
                }
            }

            const add_languages = selectLanguage.length == 0 ? languages : selectLanguage;

            const pack_name = values.sticker_pack_name.trim();
            const bucket_path_suffex = pack_name.replace(/\s+/g, '_').toLocaleLowerCase();

            const responseFinal = await stickerService.addStickersPack({
                pack_id: stickerPack?.pack_id,
                pack_name: values.sticker_pack_name,
                pack_configuration: upload_sticker_json,
                is_active: status,
                bucket_path_suffix: stickerPack?.bucket_path_suffix || bucket_path_suffex,
                part_of_all_organization: part_of_all_organization,
                languages: add_languages,
                teams: selectTeams.find(fteam => fteam === "all") ? [] : selectTeams,
                features: selectFeatures_value
            })

            if (responseFinal.messageId == '1') {
                setTLoading(false);
                add_success();
            }
            else {
                setTLoading(false);
                add_error();
            }
            resetForm();

        } catch (error) {
            console.log(error);
            setTLoading(false);
            add_error();
        }
        resetForm();

        setStickers([]);
        setSelectedImages([]);

    }


    return (
        <Fragment>
            {contextHolder}{loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><ComponentLoader isLoading={loading} children={undefined} /></div>
            ) :

                <div className="formContent">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
                        {({ handleSubmit, values, errors, setFieldValue }: FormikProps<AddTaskStickerValue>) => (
                            <form onSubmit={handleSubmit}>
                                <div className="inputHolder">
                                    <label htmlFor="stickerPackName">Sticker Pack Name*</label>
                                    <Field type="text" name="sticker_pack_name" id="stickerPackName" validate={validatePackName} aria-label="Sticker Pack Name" />
                                    <ErrorMessage name="sticker_pack_name" component="div" className="error-message errorColor msgError" />
                                </div>
                                <div className="checkboxWrapper inputHolder">
                                    <label htmlFor="Features">Features</label>
                                    <div className="checkboxItemsHolder">
                                        <Checkbox.Group options={features_types} value={selectFeatures_value} onChange={onFeaturesChange} />
                                    </div>
                                </div>

                                <div className="inputHolder customSelectChip">
                                    <label htmlFor="Languages">Languages</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        allowClear
                                        placeholder="Please select"
                                        value={selectLanguage}
                                        onChange={handleChange_lang}
                                        options={options_lang}
                                    />
                                </div>

                                <div className="inputHolder customSelectChip">
                                    <label htmlFor="Teams">Teams</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        allowClear
                                        placeholder="Please select"
                                        value={selectTeams}
                                        onChange={handleChange_team}
                                        options={options_team}
                                    />
                                </div>

                                <div className="inputHolder uploadedFiles">
                                    <label htmlFor="Upload Stickers" >Upload Stickers*</label>
                                    <p>(Max size 50 KB each & 50X50 pixels)</p>
                                    <UploadSticker />
                                </div>

                                <div className="switchCaseWrapper">
                                    <h3>Status</h3>
                                    <div className="sound-switcher mode-wrapper">
                                        <label >
                                            <span>
                                                <Switch checked={status} onChange={onChange} />
                                            </span>

                                        </label>
                                        {status === true ? (
                                            <div className="switchText">
                                                <p>Active</p>
                                            </div>
                                        ) : (
                                            <div className="switchText">
                                                <p>In active</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <Modal title="Sticker Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                    <p>Are you sure you want to delete?</p>
                                </Modal>

                                <div className="btnPart multipleBtn">
                                    {(stickerPack?.pack_id) ?
                                        (<div className="deleteUser" >
                                            <span className="cmnIcon deleteBin"></span> <span className="delProject" onClick={showModal}>Delete Sticker Pack</span>
                                        </div>) : (<div className="deleteUser" ></div>)
                                    }
                                    <div className="cancelCreate">
                                        <div className="noStyle" onClick={handleDrawer}>Cancel</div>
                                        <button
                                            type="submit"
                                            className="btnStyle1"
                                            disabled={Object.keys(errors).length !== 0 || stickers.length == 0}
                                        >{!stickerPack.pack_id ? 'Add Sticker Pack' : 'Save Changes'}</button>
                                    </div>
                                </div>
                            </form>)}
                    </Formik>
                </div>
            }
        </Fragment>


    )
}

export default AddStickerPack;

