import { FC, useState } from "react";

import EmptyStage from "./EmptyStage";
import StageList from "./StageList";
import { useTemplateStore } from "../../../store";
import templateService from "modules/services/TemplateService/Template.service";
import { eventTypes } from "modules/models/enums/templateEnums";
import {
  ISection,
  IGridListDetails,
  IStageDetail,
} from "modules/models/interfaces/template.interface";

const StageListWrapper: FC<{
  sectionDetails: ISection;
  checkStageList: (sectionDetails: ISection) => boolean;
}> = ({ sectionDetails, checkStageList }) => {
  const { templateDetails } = useTemplateStore();
  const { sections, setSections } = useTemplateStore();

  const [isStageEdit, setIsStageEdit] = useState<boolean>(false);
  const [stageName, setStageName] = useState<string>("");

  const submitChange = async (stageName: string, eventType: string) => {
    if (templateDetails?.template_id) {
      if (stageName.trim() === "") {
        setStageName("");

        if (checkStageList(sectionDetails)) {
          removeEmptyStage();
        } else {
          setIsStageEdit(false);
        }
      } else {
        try {
          const response = await templateService.createNewGridStage({
            template_id: templateDetails?.template_id,
            section_id: sectionDetails.section_id,
            stage_name: stageName.trim(),
          });

          if (response.messageId === 1) {
            updateProjectState(response.data, eventType);
            setStageName("");
            setIsStageEdit(false);
          } else {
            handleFailedOperation();
          }
        } catch (error) {
          setStageName("");
          setIsStageEdit(false);
        }
      }
    }
  };

  const updateStage = async (stageName: string, stageId: number) => {
    if (templateDetails?.template_id) {
      try {
        const response = await templateService.updateGridStage({
          template_id: templateDetails?.template_id,
          section_id: sectionDetails.section_id,
          stage_id: stageId,
          stage_name: stageName.trim(),
        });

        if (response.messageId === 1) {
          updateProjectStateForStageUpdate(response.data);
        } else {
          revertChange(stageId, "Blur");
        }
      } catch (error) {
        revertChange(stageId, "Blur");
      }
    }
  };

  const removeEmptyStage = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              stage_details: eachSection.grid_list_details?.stage_details
                ? filterOutStage(eachSection.grid_list_details?.stage_details)
                : null,
            },
          };
        }
        return eachSection;
      })
    );
  };

  const filterOutStage = (stageDetails: IStageDetail[]) => {
    let filteredList = stageDetails.filter((ele: any) => !ele.isNew);

    return filteredList.length > 0 ? filteredList : null;
  };

  const updateProjectState = (
    data: { grid_list_details: IGridListDetails },
    eventType: string
  ) => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          if (eachSection.grid_list_details) {
            if (eventType === eventTypes.ENTER) {
              return {
                ...eachSection,
                grid_list_details: {
                  ...eachSection.grid_list_details,
                  task_details: data.grid_list_details.task_details,
                  block_details: data.grid_list_details.block_details,
                  stage_details: [
                    ...data.grid_list_details.stage_details!,
                    {
                      stage_id: 99,
                      stage_name: "",
                      stage_rank: 99,
                      section_id: +sectionDetails.section_id,
                      isNew: true,
                    },
                  ],
                },
              };
            } else {
              return {
                ...eachSection,
                grid_list_details: {
                  ...eachSection.grid_list_details,
                  task_details: data.grid_list_details.task_details,
                  block_details: data.grid_list_details.block_details,
                  stage_details: data.grid_list_details.stage_details,
                },
              };
            }
          }
        }
        return eachSection;
      })
    );
  };

  const updateProjectStateForStageUpdate = (data: {
    stage_details: IStageDetail[];
  }) => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              stage_details: getUpdateStageDetails(
                eachSection,
                data.stage_details
              )!,
            },
          };
        }
        return eachSection;
      })
    );
  };

  const handleFailedOperation = () => {
    if (checkStageList(sectionDetails)) {
      removeEmptyStage();
    }
  };

  const revertChange = (stageId: number, event: string) => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          let target = eachSection.grid_list_details?.stage_details?.filter(
            (ele) => ele.stage_id === stageId
          )!;
          let others = eachSection.grid_list_details?.stage_details?.filter(
            (ele) => ele.stage_id !== stageId
          )!;

          target[0].stage_name = target[0].backupName!;
          if (event === eventTypes.BLUR) delete target[0].backupName;

          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              stage_details: [...others, ...target].sort(
                (a, b) => a.stage_rank - b.stage_rank
              ),
            },
          };
        }
        return eachSection;
      })
    );
  };

  const getUpdateStageDetails = (
    section: ISection,
    stageDetails: IStageDetail[]
  ) => {
    if (section.grid_list_details?.stage_details) {
      let stage = [...stageDetails];
      let existingStage = section.grid_list_details.stage_details.filter(
        (ele: IStageDetail) => ele.stage_id !== stage[0].stage_id
      );
      return [...existingStage, ...stage].sort(
        (a, b) => a.stage_rank - b.stage_rank
      );
    }
  };

  const getStageListBodyJsx = () => {
    return checkStageList(sectionDetails) ? (
      <StageList
        sectionDetails={sectionDetails}
        stageName={stageName}
        setStageName={setStageName}
        submitChange={async (stageName, eventType) =>
          await submitChange(stageName, eventType)
        }
        updateStage={async (stageName, stageId) =>
          await updateStage(stageName, stageId)
        }
        revertChange={revertChange}
      />
    ) : (
      <EmptyStage
        isEdit={isStageEdit}
        setIsEdit={setIsStageEdit}
        stageName={stageName}
        setStageName={setStageName}
        submitChange={async (stageName, eventType) =>
          await submitChange(stageName, eventType)
        }
        sectionDetails={sectionDetails}
      />
    );
  };

  return getStageListBodyJsx();
};

export default StageListWrapper;
