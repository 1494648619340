import { Dayjs } from "dayjs";
import { TaskListView } from "modules/models/enums/taskListView";
import { ISectionDetail } from "modules/models/interfaces/template.interface";

const TaskDueDate: React.FC<{
  eachTaskDetail?: ISectionDetail;
  taskDueDateChangeHandler: (dueDate: Dayjs | null) => void;
  showDatePicker: boolean;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  viewType?: TaskListView;
}> = ({
  eachTaskDetail,
  taskDueDateChangeHandler,
  showDatePicker,
  setShowDatePicker,
  viewType,
}) => {
  return <td>None</td>;
};

export default TaskDueDate;
