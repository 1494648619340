import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import React, { Fragment } from "react";
import LeftNav from "../../Dashboard/LeftNav/LeftNav";
import { useTeamsStore } from "./teamsStore";
import TeamManageTable from "./TeamsManageTable";
import ViewTeaminfo from "./ViewTeaminfo";

const TeamManagement: React.FC<{}> = () => {
    const { isDrawer } = useTeamsStore();


    return (
        <RBAC allowedPermissions={[ERbacPermissions.TEAM_VIEW]}>
            <div className={`adminPanelMainWrapper mainWrapper ${isDrawer ? "body-overlay" : ""}`}>
                <LeftNav />
                <div className="adminPanelMainContent mainContent">
                    <Fragment>
                        <div className="contentHeadingPanel">
                            <h2>Team Management</h2>
                        </div>
                        <div className="userManagementTable">

                            <TeamManageTable />

                        </div>
                        <div className={`addTeamsDrawerWrap createNewProjectForm ${isDrawer ? "addToggle" : ""}`} id="TeamMnanagementInfo">
                            <ViewTeaminfo />
                        </div>
                    </Fragment>
                </div>
            </div>
        </RBAC>
    )
}

export default withRbac(TeamManagement, { allowedPermissions: [ERbacPermissions.TEAM_VIEW], isAccessFromPage: true });