import { FC, Fragment } from "react";
import { Tooltip } from "antd";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { useRbac } from "auth/rbac/rbac";
import {
  ISection,
  IStageDetail,
} from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../../store";

const StageNameInputField: FC<{
  sectionDetails: ISection;
  stageDetails: IStageDetail;
  setStageName: React.Dispatch<React.SetStateAction<string>>;
  stageName: string;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateStage: (stageName: any, stageId: any) => Promise<void>;
  revertChange: (stageId: number, event: string) => void;
}> = ({
  stageDetails,
  stageName,
  setStageName,
  submitChange,
  updateStage,
  revertChange,
  sectionDetails,
}) => {
  const { hasPermissions } = useRbac();
  const { sections, setSections } = useTemplateStore();

  const checkIfRevertRequired = (stageDetails: IStageDetail) =>
    stageDetails.backupName === stageDetails.stage_name ||
    stageDetails.stage_name === "";

  const onKeyUpEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (stageDetails?.isNew) {
      if (e.key === "Enter") {
        submitChange(stageName, "Enter");
      }

      if (e.key === "Escape") {
        submitChange("", "Escape");
      }
    } else {
      if (e.key === "Enter") {
        if (checkIfRevertRequired(stageDetails)) {
          revertChange(stageDetails.stage_id, "Enter");
        } else {
          document
            .getElementById(`${stageDetails.stage_id}_input_field`)
            ?.blur();
        }
      }

      if (e.key === "Escape") {
        revertChange(stageDetails.stage_id, "Escape");
      }
    }
  };

  const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stageDetails?.isNew) {
      setStageName(e.target.value);
    } else {
      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                stage_details: getUpdateStageDetails(
                  eachSection,
                  stageDetails,
                  e.target.value
                )!,
              },
            };
          }
          return eachSection;
        })
      );
    }
  };

  const onBlurEventHandler = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    if (stageDetails?.isNew) {
      submitChange(stageName, "Blur");
    } else {
      if (checkIfRevertRequired(stageDetails)) {
        revertChange(stageDetails.stage_id, "Blur");
      } else {
        updateStage(stageDetails.stage_name, stageDetails.stage_id);
      }
    }
  };

  const getUpdateStageDetails = (
    section: ISection,
    stageDetails: IStageDetail,
    newName: string
  ) => {
    if (section.grid_list_details?.stage_details) {
      let stage = [{ ...stageDetails }];
      if (!stage[0]?.backupName) {
        stage[0].backupName = stage[0].stage_name;
      }
      stage[0].stage_name = newName;

      let existingStage = section.grid_list_details.stage_details.filter(
        (ele: IStageDetail) => ele.stage_id !== stage[0].stage_id
      );
      return [...existingStage, ...stage].sort(
        (a, b) => a.stage_rank - b.stage_rank
      );
    }
  };

  const makeFieldEditable = (stageDetails: IStageDetail) => {
    if (hasPermissions([ERbacPermissions.TEMPLATE_VIEW])) {
      setSections(
        sections?.map((eachSection) => {
          if (eachSection.section_id === sectionDetails.section_id) {
            let stage = [{ ...stageDetails }];
            stage[0].isEdit = true;
            let existingStage =
              eachSection.grid_list_details?.stage_details!.filter(
                (ele: IStageDetail) => ele.stage_id !== stage[0].stage_id
              ) || [];
            return {
              ...eachSection,
              grid_list_details: {
                ...eachSection.grid_list_details,
                stage_details: [...existingStage, ...stage].sort(
                  (a, b) => a.stage_rank - b.stage_rank
                ),
              },
            };
          }
          return eachSection;
        })
      );
    }
  };

  const getStageNameFieldJsx = (stageDetails: IStageDetail) => {
    if (stageDetails.isNew) {
      return (
        <input
          type="text"
          id={`${stageDetails.stage_id}_input_field`}
          placeholder="Add New Stage"
          value={stageName}
          aria-label="sign up / login"
          onChange={(e) => onChangeEventHandler(e)}
          maxLength={50}
          onKeyUp={(e) => onKeyUpEventHandler(e)}
          onBlur={(e) => onBlurEventHandler(e)}
          autoFocus={stageDetails?.isNew ? true : false}
        />
      );
    }

    if (stageDetails.isEdit) {
      return (
        <input
          type="text"
          id={`${stageDetails.stage_id}_input_field`}
          placeholder="Add Stage"
          value={stageDetails.stage_name}
          aria-label="sign up / login"
          onChange={(e) => onChangeEventHandler(e)}
          maxLength={50}
          onKeyUp={(e) => onKeyUpEventHandler(e)}
          onBlur={(e) => onBlurEventHandler(e)}
          autoFocus={stageDetails?.isEdit ? true : false}
        />
      );
    }

    return (
      <Fragment>
        {stageDetails.stage_name.length > 9 ? (
          <Tooltip
            title={stageDetails.stage_name}
            color={"#2E364C"}
            placement="top"
          >
            <span
              className="stageNameSpan"
              onClick={() => makeFieldEditable(stageDetails)}
            >
              {" "}
              {stageDetails.stage_name}{" "}
            </span>
          </Tooltip>
        ) : (
          <span
            className="stageNameSpan"
            onClick={() => makeFieldEditable(stageDetails)}
          >
            {" "}
            {stageDetails.stage_name}{" "}
          </span>
        )}
      </Fragment>
    );
  };

  return getStageNameFieldJsx(stageDetails);
};

export default StageNameInputField;
