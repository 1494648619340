import { useAuth0 } from "@auth0/auth0-react";
import { message, Switch, Modal } from "antd";
import { TaskStickerPack } from "modules/models/interfaces/teams.interface";
import apiService from "modules/services/Api.service";
import teamsService from "modules/services/TeamsService/Teams.service";
import { Fragment, useEffect, useState } from "react"
import { useTeamsStore } from "./teamsStore";
import { ComponentLoader } from "modules/components";
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

const ViewTeaminfo: React.FC = () => {
    const { isDrawer, setIsDrawer } = useTeamsStore();
    const { teamInfo } = useTeamsStore();
    const [status, setStatus] = useState<boolean>();
    const { setTLoading } = useTeamsStore();
    const [loading, setLoading] = useState(true);
    const [teamTaskStickerPack, setTeamTaskStickerPack] = useState<TaskStickerPack[]>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const { hasPermissions } = useRbac();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const set_status_success = () => {
        messageApi.open({
            type: "success",
            content: "Team status changed successfully"
        });
    };

    const set_status_error = () => {
        messageApi.open({
            type: "error",
            content: "Failed to change status"
        });
    };

    const handleOk = async () => {
        if (status === true) {
            const response1 = await teamsService.updateTeamStatus({
                team_id: teamInfo.id,
                is_active: false
            });
            if (response1.messageId == '1') {
                setStatus(false);
                setTLoading(true);
                handleDrawer();
                set_status_success();
            } else {
                set_status_error();
            }
        } else {
            const response1 = await teamsService.updateTeamStatus({
                team_id: teamInfo.id,
                is_active: true
            });
            if (response1.messageId == '1') {
                setStatus(true);
                setTLoading(true);
                handleDrawer();
                set_status_success();
            } else {
                set_status_error();
            }
        }
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDrawer = () => {
        setIsDrawer(!isDrawer);
    };

    const onChange = () => {
        showModal();
    };

    const getTeamStickerPack = async () => {
        try {
            setLoading(true);
            const response = await teamsService.getTeamStickerPacks({
                team_id: teamInfo.id
            });
            if (response.data.taskStickerPack != null) {

                setTeamTaskStickerPack(response.data.taskStickerPack)
            } else {
                setTeamTaskStickerPack([])
            }


            setLoading(false);


        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };


    const { getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (hasPermissions([ERbacPermissions.TEAM_VIEW])) {
            getTeamStickerPack();
        }
    }, []);

    useEffect(() => {
        getTeamStickerPack();
        if (teamInfo.accountStatus == 'Active') {
            setStatus(true)
        } else {
            setStatus(false)
        }

    }, [teamInfo])

    return (
        <Fragment>
            {contextHolder}{loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}><ComponentLoader isLoading={loading} children={undefined} /></div>
            ) :
                <Fragment>
                    <div className="header">
                        <h2>View Team Info</h2>
                        <span className="material-icons-outlined close" onClick={handleDrawer}>close</span>
                    </div>

                    <div className="formContent">
                        <div className="contentShower">
                            <h3>Team Name</h3>
                            <p>{teamInfo.team_name}</p>
                        </div>
                        <div className="contentShower">
                            <h3>Team Key</h3>
                            <p>{teamInfo.key}</p>
                        </div>
                        <div className="contentShower">
                            <h3>Account Status</h3>
                            <p>{teamInfo.accountStatus}</p>
                        </div>
                        <div className="contentShower">
                            <h3>Account Created</h3>
                            <p>{teamInfo.account_created}</p>
                        </div>
                        <div className="contentShower">
                            <h3>Team Type</h3>
                            <p>{teamInfo.team_type}</p>
                        </div>
                        <div className="contentShower">
                            <h3>Active Users</h3>
                            <p>{teamInfo.active_user}</p>
                        </div>
                        <div className="contentShower">
                            <h3>Projects</h3>
                            <p>{teamInfo.total_project}</p>
                        </div>

                        <div className="switchCaseWrapper">
                            <h3>Status</h3>
                            <div className="sound-switcher mode-wrapper">
                                <label >
                                    <span>
                                        <Switch checked={status} onChange={onChange} />
                                    </span>

                                </label>
                                {status === true ? (
                                    <div className="switchText">
                                        <p>Active</p>
                                    </div>
                                ) : (
                                    <div className="switchText">
                                        <p>In active</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <Modal title="Change Status" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                            <p>Are you sure you want to change status to {(status === true) ? (<span>In-active</span>) : (<span>Active</span>)}?</p>
                        </Modal>

                        <div className="teamStickerPack">
                            {
                                teamTaskStickerPack?.map((mdata) => {
                                    return (
                                        <div className="teamStickerItem" key={mdata.pack_id}>
                                            <div className="teamSticker">
                                                <img
                                                    src={`${process.env.REACT_APP_BUCKET_BASE_PATH}${mdata?.pack_configuration?.[0].status_value}`}
                                                    alt={
                                                        `${process.env.REACT_APP_BUCKET_BASE_PATH}${mdata?.pack_configuration?.[0].status_label}`
                                                    }
                                                />
                                            </div>
                                            <div className="teamStickerText">{mdata?.pack_name}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="btnPart">
                            <div className="cancelCreate">
                                <button className="btnStyle1" onClick={handleDrawer}>Go Back</button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

export default ViewTeaminfo;