import { useModalStore } from "modules/store/modal.store";
import { FC } from "react";

const Modal: FC<{}> = () => {

    const { modalState } = useModalStore();

    return (
        <div className={`deleteFolderModal modal ${modalState?.shouldShow ? 'show-modal' : ''}`}>
            <div className="modal-content">
                <div className="modal-header">
                   {modalState?.modalHeader}
                </div>
                <div className="modal-body">
                    <div className="content text-center">
                        <div className="createCard">
                            <div className="icon"></div>
                            <div className="text">{modalState?.modalBody}</div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="btnSec">
                        {modalState?.modalFooter}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal