import { FC, Fragment, useEffect, useState } from "react";
import { Tooltip } from "antd";
import GridListDetails from "./GridListDetails";
import templateService from "modules/services/TemplateService/Template.service";
import { ISection, ITemplateData } from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../store";
import MeatBallMenu from "../MeatBallMenu";

const GridList: FC<{
  sectionId: string | number;
  sectionDetails: ISection;
}> = ({ sectionDetails, sectionId }) => {
  const [sectionToggleState, setSectionToggleState] = useState<
    "expanded" | "collapsed"
  >("expanded");
  const [isEditingSectionName, setIsEditingSectionName] =
    useState<boolean>(false);
  const [, setShowSidePanel] = useState<boolean>(false);

  const [sectionName, setSectionName] = useState<string>("");
  const { templateDetails } = useTemplateStore();
  const { sections, setSections } = useTemplateStore();

  useEffect(() => {
    if (typeof sectionId === "string") {
      setIsEditingSectionName(true);
    } else {
      setIsEditingSectionName(false);
    }
  }, [sectionId]);

  useEffect(() => {
    if (isEditingSectionName) {
      setSectionName(sectionDetails?.section_name ?? "");
    }
  }, [isEditingSectionName]);

  useEffect(() => {
    if (!isEditingSectionName) {
      setSectionName(sectionDetails?.section_name);
    }
  }, [sectionDetails.section_name]);

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === "collapsed" ? "expanded" : "collapsed"
    );
  };

  useEffect(() => {
    if (sectionDetails.is_edit_mode) {
      setTimeout(() => {
        setIsEditingSectionName(true)
      }, 1500)
    }
  }, [sectionDetails])

  const createSection = async (
    templateDetals: ITemplateData,
    oldSection: ISection
  ) => {

    const response = await templateService.createNewSection({
      template_id: templateDetals.template_id!,
      section_type_id: 2,
      section_name: sectionName,
      section_rank: oldSection.section_rank,
    });

    if (response.messageId === 1) {
      setSections(sections?.map((eachSection: ISection) => {
        const _section = eachSection;
        if (_section.section_id === sectionId) {
          _section.section_name = sectionName;
          _section.section_id = response.data.section_id;
        }
        return _section;
      }));
    }
  };

  const updateSection = async (
    templateDetals: ITemplateData,
    oldSection: ISection
  ) => {

    const result = await templateService.updateSection({
      template_id: templateDetals.template_id!,
      sections: [
        {
          section_id: +oldSection.section_id,
          section_name: sectionName,
        },
      ],
    });

    if (result.messageId === 1) {
      setSections(sections?.map((eachSection) => {
        const _section = eachSection;
        _section.is_edit_mode = false
        if (_section.section_id === sectionId) {
          _section.section_name = sectionName;
        }
        return _section;
      }));
    }
  };

  const sectionNameOnBlurHandler = async (
    _event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    setIsEditingSectionName(false);
    setSectionName((prev) => prev?.trim());

    if (templateDetails?.template_id && sections) {
      const oldSection = sections.find(
        (eachSection) => eachSection.section_id === sectionId
      );
      if (typeof oldSection?.section_id === "string" && sectionName === "") {
        setSections(sections?.filter(
          (eachSection) => eachSection.section_id !== sectionId
        ));
      } else if (
        typeof oldSection?.section_id === "string" &&
        sectionName !== ""
      ) {
        await createSection(templateDetails, oldSection);
      } else if (
        typeof oldSection?.section_id === "number" &&
        sectionName?.trim() !== "" &&
        oldSection.section_name !== sectionName
      ) {
        await updateSection(templateDetails, oldSection);
      }
    }
  };

  const sectionNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.currentTarget?.value;
    setSectionName(value);
  };

  const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      sectionNameOnBlurHandler(event);
    }
  };

  return (
    <Fragment>
      <div className="createNewSecMainContent">
        <div className="firstTopic">
          <div className="taskNameHeadingPane">
            <div className="itemNameBlk">
              <Tooltip
                title="Toggle task view"
                color={"#2E364C"}
                placement="bottom"
              >
                <span
                  className={`sidePanelIcon ${sectionToggleState === "expanded"
                      ? "arrow-down"
                      : "arrow-right"
                    }`}
                  color={"#2E364C"}
                  onClick={handleSectionToggle}
                />
              </Tooltip>
              {isEditingSectionName ? (
                <input
                  className="section-text-input"
                  placeholder="New Section"
                  onBlur={sectionNameOnBlurHandler}
                  onChange={sectionNameChangeHandler}
                  onKeyDown={saveOnEnter}
                  value={sectionName}
                  autoFocus={true}
                  maxLength={255}
                />
              ) : (
                <span
                  className="section-text-span"
                  onClick={() => {
                    setIsEditingSectionName(true);
                  }}
                >
                  {sectionName?.trim() === ""
                    ? sections?.find(
                      (eachSection) => eachSection.section_id === sectionId
                    )?.section_name
                    : sectionName}
                </span>
              )}
              {!isEditingSectionName && (
                <Fragment>
                  <MeatBallMenu
                    sectionType="grid-list"
                    sectionDetails={sectionDetails}
                    setShowSidePanel={setShowSidePanel}
                  />
                </Fragment>
              )}
            </div>
          </div>
          <GridListDetails
            sectionDetails={sectionDetails}
            sectionToggleState={sectionToggleState}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default GridList;
