export enum RoutesEnum {
    ADMIN_ROLES_DATA = "adminRoles",
    CHECK_UNIQUE_EMAIL = "isUniqueEmail",
    //user
    INVITE_USER = "accountInvitationEmailSend",
    USER_VERIFY = "accountVerify",
    USER_ACTIVATION = "accountActivation",
    USERS_TABLE_DATA = "usersTable",
    USER_UPDATE = "userUpdate",
    RE_INVITE_USER = "accountInvitationResend",
    USER_DETAILS = "userDetails",
    USER_DELETE = "userDelete",

    //task sticker
    STICKER_STATUS_OPTION = "getTaskStatus",
    TEAM_NAMES = "getOrgNames",
    UNIQUE_PACK_NAME = "isUniquePack",
    LANGUAGES = "getLanguges",
    UPLOAD_TASK_STICKER = "uploadTaskSticker",
    ADD_TASK_STICKER_PACK = "addTaskStickerPack",
    TASK_STICKER_PACKS = "getTaskStickersPacks",
    DELETE_TASK_STICKERS = "deleteTaskSticker",

    //sticker
    STICKERS_UNIQUE_PACK_NAME = "stickers/isUniqueStickersName",
    UPLOAD_STICKERS = "stickers/uploadStickers",
    ADD_STICKERS_PACK = "stickers/addStickers",
    STICKERS_PACKS = "stickers/getStickers",
    FEATURES = "stickers/getFeatures",
    DELETE_STICKERS = "stickers/stickersDelete",

    //TEAMS
    TEAM_DETAILS = "teams/getTeamsDetails",
    TEAM_STICKER_PACK = "teams/teamStickerPacks",
    TEAM_STATUS_UPDATE = "teams/teamStatusUpdate",

    //TEMPLATE
    ALL_MASTER_DATA = "masterData/getAllMasterData",
    SAVE_TEMPLATE = "templates/saveTemplate",
    UPLOAD_THUMBNAIL = "templates/uploadThumbnail",
    GET_ALL_TEMPLATES = "templates/getAllTemplates",
    ADD_NEW_SECTION = "templates/addNewSection",
    UPDATE_SECTION = "templates/updateSection",
    ADD_NEW_TASK = "templates/addNewTask",
    UPDATE_TASK = "templates/updateTask",
    REORDER_GRID_TASK = "templates/reOrderGridTask",
    REORDER_GRID_STAGE = "templates/reOrderGridStage",
    ADD_NEW_GRID_TASK = "templates/addGridTask",
    ADD_NEW_GRID_STAGE = "templates/addGridStage",
    UPDATE_GRID_STAGE = "templates/updateGridStage",
    UPDATE_GRID_TASK = "templates/updateGridTask",
    UPDATE_GRID_BLOCK = "templates/updateGridBlock",
    DELETE_GRID_STAGE = "templates/deleteGridStage",
    DELETE_GRID_TASK = "templates/deleteGridTask",
    UPLOAD_MEMOPAD_ASSETS = "templates/uploadMemopadAssets",
    IS_UNIQUE_TEMPLATE_NAME = "templates/isUniqueTemplateName",
    CHANGE_STATUS_PUBLISH_TEMPLATE = "templates/changeStatusPublishTemplate",
    DELETE_TEMPLATE = "templates/deleteTemplate",
    GET_TEMPLATE_BY_ID = "templates/getTemplateById",
    GET_WEBSITE_METADATA = "templates/metascrape",

    LOG = "/log"
}