import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const ComponentLoader: React.FC<{ isLoading: boolean; children: any }> = (
  props
) => {
  return <Spin spinning={props.isLoading} indicator={antIcon}>{props.children}</Spin>;
};

export default ComponentLoader;
