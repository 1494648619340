import React, { FC, Fragment, useEffect } from "react";
import { Dropdown, MenuProps, message } from "antd";
import templateService from "modules/services/TemplateService/Template.service";
import { useTemplateStore } from "../store";
import { useModalStore } from "modules/store/modal.store";
import {
  AllowedSection,
  menuMeatBallItem,
} from "modules/models/enums/templateEnums";
import {
  IChild,
  IMeatBallMenu,
  ISection,
} from "../../../../models/interfaces/template.interface";

interface IMeatBallMenuProps {
  sectionType: string;
  sectionDetails: ISection;
  setShowSidePanel?: React.Dispatch<React.SetStateAction<boolean>>;
}

enum MeatBallMenuOptionKeys {
  MOVE_SECTION = "move_section",
  MOVE_SECTION_TOP = "move_section_top",
  MOVE_SECTION_UP = "move_section_up",
  MOVE_SECTION_DOWN = "move_section_down",
  MOVE_SECTION_BOTTOM = "move_section_bottom",
  DELETE_SECTION = "delete_section",
}

const MeatBallMenu: FC<IMeatBallMenuProps> = ({
  sectionType,
  sectionDetails,
  setShowSidePanel,
}) => {
  const { setModalState, modalState } = useModalStore();
  const { templateDetails } = useTemplateStore();
  const {
    sections,
    setSections,
    sectionDetail: selectedSection,
    setSectionDetail,
    showSectionDeleteConfirmation,
    setShowSectionDeleteConfirmation,
  } = useTemplateStore();

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
    setShowSectionDeleteConfirmation(false);
  };

  const getMenuOptionClassName = (ele: IMeatBallMenu) => {
    return ele.className[0];
  };

  const getMenuOptionLabel = (ele: IMeatBallMenu) => {
    return ele.label[0];
  };

  const isFirstSection = () => {
    return (
      sections?.findIndex(
        (eachSection) => sectionDetails.section_id === eachSection.section_id
      ) === 0
    );
  };

  const isLastSection = () => {
    let lastIndex = sections?.length ? sections?.length - 1 : 0;
    return (
      sections?.findIndex(
        (eachSection) => sectionDetails.section_id === eachSection.section_id
      ) === lastIndex
    );
  };

  const moveToTop = async () => {
    let updatedSections = sections;
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id
      ),
      1
    );
    if (currentSection) {
      updatedSections?.splice(0, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_name: eachSection.section_name,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (templateDetails?.template_id && updateSectionPayload) {
          const updateSectionResponse = await templateService.updateSection({
            template_id: templateDetails.template_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setSections(updatedSections);
          }
        }
      } catch (error) {
        message.error("Error");
      }
    }
  };

  const moveToBottom = async () => {
    let updatedSections = sections;
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id
      ),
      1
    );
    if (currentSection) {
      updatedSections?.splice(updatedSections?.length, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_name: eachSection.section_name,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (templateDetails?.template_id && updateSectionPayload) {
          const updateSectionResponse = await templateService.updateSection({
            template_id: templateDetails.template_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setSections(updatedSections);
          }
        }
      } catch (error) {
        message.error("Error");
      }
    }
  };

  const moveUp = async () => {
    let updatedSections = sections;
    const currentIndex = updatedSections?.findIndex(
      (eachSection) => eachSection.section_id === sectionDetails.section_id
    );
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id
      ),
      1
    );
    if (currentSection && currentIndex !== undefined) {
      updatedSections?.splice(currentIndex - 1, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_name: eachSection.section_name,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (templateDetails?.template_id && updateSectionPayload) {
          const updateSectionResponse = await templateService.updateSection({
            template_id: templateDetails.template_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setSections(updatedSections);
          }
        }
      } catch (error) {
        message.error("Error");
      }
    }
  };

  const moveDown = async () => {
    let updatedSections = sections;
    const currentIndex = updatedSections?.findIndex(
      (eachSection) => eachSection.section_id === sectionDetails.section_id
    );
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id
      ),
      1
    );
    if (currentSection && currentIndex !== undefined) {
      updatedSections?.splice(currentIndex + 1, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_name: eachSection.section_name,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (templateDetails?.template_id && updateSectionPayload) {
          const updateSectionResponse = await templateService.updateSection({
            template_id: templateDetails.template_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setSections(updatedSections);
          }
        }
      } catch (error) {
        message.error("Error");
      }
    }
  };

  let items: MenuProps["items"] = [];
  items = menuMeatBallItem
    ?.map((ele: IMeatBallMenu) => {
      if (
        ele.allowedSection.includes(sectionType as AllowedSection) &&
        ele.permissions === null
      ) {
        let returnObj: any;

        returnObj = {
          key: ele.key,
          label: (
            <div className="customDropDownItem">
              <span className={getMenuOptionClassName(ele)}></span>
              {getMenuOptionLabel(ele)}
            </div>
          ),
        };

        if (ele.hasChildren) {
          if (isFirstSection()) {
            returnObj.children = ele?.children?.filter(
              (eachChild) =>
                eachChild.key !== "move_section_top" &&
                eachChild.key !== "move_section_up"
            );
          }
          if (isLastSection()) {
            returnObj.children = ele?.children?.filter(
              (eachChild) =>
                eachChild.key !== "move_section_down" &&
                eachChild.key !== "move_section_bottom"
            );
          }
          if (!isFirstSection() && !isLastSection()) {
            returnObj.children = ele?.children;
          }
          returnObj.children = returnObj.children?.map((ele: IChild) => {
            return {
              key: ele.key,
              label: ele.hasClass ? (
                <div className="customDropDownItem">
                  <span className={ele.className![0]}></span>
                  {ele.label[0]}
                </div>
              ) : (
                ele.label[0]
              ),
            };
          });
        }

        return returnObj;
      }
      return {};
    })
    .filter((ele: IMeatBallMenu) => {
      if (sections?.length === 1) {
        return ele?.key !== MeatBallMenuOptionKeys.MOVE_SECTION;
      }
      return ele;
    });

  const deleteSection = async () => {
    const result = await templateService.updateSection({
      template_id: templateDetails?.template_id!,
      sections: [
        {
          section_id: +selectedSection?.section_id!,
          section_name: selectedSection?.section_name!,
          section_rank: selectedSection?.section_rank!,
          is_deleted: true,
        },
      ],
    });

    if (result.messageId === 1) {
      setSections(
        sections?.filter((e) => e.section_id !== selectedSection?.section_id)
      );
    }
    modalClose();
  };

  useEffect(() => {
    if (showSectionDeleteConfirmation) {
      setModalState({
        modalHeader: (
          <Fragment>
            <h3> Delete Section</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalClose()}
            >
              close
            </span>
          </Fragment>
        ),
        modalBody: (
          <Fragment>
            <p>
              Are you sure you want to delete the section&nbsp;
              <b>{selectedSection?.section_name}</b>? <br />
              This action cannot be undone.
            </p>
          </Fragment>
        ),
        modalFooter: (
          <Fragment>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalClose()}
            >
              Cancel
            </button>

            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await deleteSection();
                })()
              }
            >
              Delete Section
            </button>
          </Fragment>
        ),
        shouldShow: true,
      });
    }
  }, [showSectionDeleteConfirmation]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case MeatBallMenuOptionKeys.DELETE_SECTION:
        setSectionDetail(sectionDetails);
        setShowSectionDeleteConfirmation(true);
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_TOP:
        moveToTop();
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_UP:
        moveUp();
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_DOWN:
        moveDown();
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_BOTTOM:
        moveToBottom();
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick }}
      placement="bottomLeft"
      trigger={["click"]}
    >
      <span className="cmnIcon blueMore" />
    </Dropdown>
  );
};
export default MeatBallMenu;
