import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import { Forbidden } from "modules/sharedComponents/components/Error/Forbidden";
import { FullPageLoader } from "modules/components";
import { useUserStore } from "modules/store";
import userService from "modules/services/UsersManageService/User.manage.service";
import apiService from "modules/services/Api.service";

const useRbac = () => {
    const {user} = useAuth0();
    const {
        setUserDetails,
        userDetails,
        permissions,
    } = useUserStore();
    const auth0UserDetails = user;
    const [isLoadingRbac, setIsLoadingRbac] = useState(true);
    const dataFetchedRef = useRef(false);
    const {logout} = useAuth0();

    const getUserDetails = useCallback(async () => {
        const userResponseData = await userService.userDetails();
        
        if (userResponseData.messageId === '-3') {
            window.location.replace('/invitation-not-match')
            setTimeout(() => {
                logout()
            }, 2000)
        }
        if (userResponseData.messageId === '-1') {
            window.location.replace('/invitation-not-match')
            setTimeout(() => {
                logout()
            }, 2000)
        } else {
            setUserDetails(
                userResponseData.data,
                userResponseData.data.permissions,
            );
        }
    }, [setUserDetails]);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        apiService.setTokenGenerator(getAccessTokenSilently);
        if (!userDetails) {
            if (!dataFetchedRef.current) {
                dataFetchedRef.current = true;
                getUserDetails();
            }
        } else {
            setIsLoadingRbac(false);
        }
    }, [userDetails, getUserDetails]);

    const hasPermissions = (_permissions: string[]) => {
        return permissions.some((value) => _permissions.includes(value));
    };

    return {
        isLoadingRbac,
        userDetails,
        hasPermissions,
        auth0UserDetails,
    };
};

const withRbac = (
    WrappedComponent: React.ComponentType,
    params?: {
        allowedPermissions?: string[];
        isAccessFromPage?: boolean;
    }
) => {
    return (props: any) => {
        const {isLoadingRbac, userDetails, hasPermissions, auth0UserDetails} =
            useRbac();
        return (
            <React.Fragment>
                {!isLoadingRbac && (
                    <React.Fragment>
                        {params?.allowedPermissions && (
                            <React.Fragment>
                                {hasPermissions(params?.allowedPermissions) && (
                                    <WrappedComponent
                                        {...props}
                                        userDetails={userDetails}
                                        auth0UserDetails={auth0UserDetails}
                                        hasPermissions={hasPermissions}
                                        isLoadingRbac={isLoadingRbac}
                                    />
                                )}
                                {!hasPermissions(params.allowedPermissions) && (
                                    <Forbidden isAccessFromPage={params.isAccessFromPage}/>
                                )}
                            </React.Fragment>
                        )}

                        {(!params || !params.allowedPermissions) && (
                            <WrappedComponent
                                {...props}
                                userDetails={userDetails}
                                auth0UserDetails={auth0UserDetails}
                                hasPermissions={hasPermissions}
                                isLoadingRbac={isLoadingRbac}
                            />
                        )}
                    </React.Fragment>
                )}
                {isLoadingRbac && <FullPageLoader/>}
            </React.Fragment>
        );
    };
};

const RBAC: React.FC<{
    children: React.ReactNode;
    allowedPermissions?: string[];
    allowedRoles?: string[];
}> = ({children, allowedPermissions, allowedRoles}) => {
    const {isLoadingRbac, hasPermissions} = useRbac();

    const checkRoleAndPermission = () => {
        if (isLoadingRbac) {
            return false;
        } else if (
            allowedPermissions &&
            allowedRoles &&
            hasPermissions(allowedPermissions)
        ) {
            return true;
        } else if (
            allowedPermissions &&
            !allowedRoles &&
            hasPermissions(allowedPermissions)
        ) {
            return true;
        } else if (allowedRoles && !allowedPermissions) {
            return true;
        } else {
            return false;
        }
    };

    if (checkRoleAndPermission()) {
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        return null;
    }
};

export {useRbac, withRbac, RBAC};
