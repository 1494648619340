import { IMeatBallMenu } from "../interfaces/template.interface";

export enum SectionType {
  TASKLIST = "task_list",
  GRIDLIST = "grid_list",
  MEMOPAD = "memo_pad",
}

export enum AllowedSection {
  GridList = "grid-list",
  MemoPad = "memo-pad",
  TaskList = "task-list",
}

export enum eventTypes {
  ENTER = "Enter",
  ESCAPE = "Escape",
  BLUR = "Blur"
}

export enum TemplateStep {
  LIST = 0,
  CREATE = 1,
  CONFIGURE = 2,
}

export const menuMeatBallItem: IMeatBallMenu[] = [
  {
    key: "delete_section",
    label: ["Delete Section"],
    allowedSection: [AllowedSection.TaskList, AllowedSection.MemoPad, AllowedSection.GridList],
    hasChildren: false,
    className: ["cmnIcon deleteBin"],
    permissions: null,
  },
  {
    key: "move_section",
    label: ["Move Section"],
    allowedSection: [AllowedSection.TaskList, AllowedSection.MemoPad, AllowedSection.GridList],
    hasChildren: true,
    className: ["cmnIcon addSec"],
    permissions: null,
    children: [
      {
        key: "move_section_top",
        label: ["Top of the page"],
        hasClass: true,
        className: ["cmnIcon moveToTop"]
      },
      {
        key: "move_section_up",
        label: ["Up"],
        hasClass: true,
        className: ["cmnIcon moveTop"]
      },
      {
        key: "move_section_down",
        label: ["Down"],
        hasClass: true,
        className: ["cmnIcon moveBottom"]
      },
      {
        key: "move_section_bottom",
        label: ["Bottom of the page"],
        hasClass: true,
        className: ["cmnIcon moveToBottom"]
      },
    ]
  },
];