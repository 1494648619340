import { FC, Fragment } from "react";
import { useRbac, RBAC } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { ISection } from "modules/models/interfaces/template.interface";
import { useTemplateStore } from "../../../store";

const EmptyTask: FC<{
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  setTaskName: React.Dispatch<React.SetStateAction<string>>;
  taskName: string;
  sectionDetails: ISection;
}> = ({ isEdit, setIsEdit, submitChange, taskName, setTaskName, sectionDetails }) => {
  const { hasPermissions } = useRbac();
  const { sections, setSections } = useTemplateStore();

  const addNewTask = () => {
    setSections(
      sections?.map((eachSection) => {
        if (eachSection.section_id === sectionDetails.section_id) {
          return {
            ...eachSection,
            grid_list_details: {
              ...eachSection.grid_list_details,
              task_details: eachSection.grid_list_details?.task_details
                ? [
                    ...eachSection.grid_list_details.task_details,
                    {
                      task_id: 99,
                      task_name: "",
                      task_rank: 99,
                      section_id: +sectionDetails.section_id,
                      total_block_count: 0,
                      completed_block_count: 0,
                      isNew: true,
                    },
                  ]
                : [
                    {
                      task_id: 99,
                      task_name: "",
                      task_rank: 99,
                      section_id: +sectionDetails.section_id,
                      total_block_count: 0,
                      completed_block_count: 0,
                      isNew: true,
                    },
                  ],
            },
          };
        }
        return eachSection;
      })
    );
  };

  return (
    <div className="leftProgressWrap">
      <li 
        className={"progressCardTop emptyStateAddTask"}
        onClick={() => addNewTask()}
        onKeyDown={() => {}}
      >
          <Fragment>
            <RBAC
              allowedPermissions={[
                ERbacPermissions.TEMPLATE_VIEW,
              ]}
            >
              <div className="addItem item1">
                <a
                  href={void 0}
                  aria-label="add task"
                  onClick={() => addNewTask()}
                >
                  <span className="addplus"></span>
                </a>
              </div>
            </RBAC>
            <h5>Add New Task</h5>
          </Fragment>
      </li>
    </div>
  );
};

export default EmptyTask;
