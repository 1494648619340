import { Fragment, useState } from "react";
import { Popover, Tooltip, message } from "antd";
import {
  BoldOutlined,
  ItalicOutlined,
  LinkOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Editor } from "@tiptap/core";
import { storage } from "utils/firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import Picker from "@emoji-mart/react";
// import BrowserStorageService from "services/browserStorage.service";
import cryptoRandomString from "crypto-random-string";
// import { useProjectDashboardStore } from "stores";
import data from "./github_emojis.json";
import { IAllowedExtensions } from "modules/models/interfaces/editor.interface";

const allowedFileExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "doc",
  "docx",
  "txt",
  "xls",
  "xlsx",
  "pdf",
];

const EditorControls: React.FC<{
  editor: Editor | null;
  sendMessage: any;
  allowedExtensions?: IAllowedExtensions;
  threadId?: string;
  showEmojiPicker?: boolean;
  setShowEmojiPicker?: React.Dispatch<React.SetStateAction<boolean>>;
  pageCmsData?: any;
}> = ({
  editor,
  sendMessage,
  allowedExtensions,
  threadId,
  showEmojiPicker,
  setShowEmojiPicker,
  pageCmsData,
}) => {
  // const browserStorage = BrowserStorageService.getInstance();

  const [linkInputFieldVisible, setLinkInputFieldVisible] = useState(false);

  const [targetUrl, setTargetUrl] = useState("");
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);

  // const { messagingCmsData } = useProjectDashboardStore(
  //   (state: IProjectState) => state
  // );

  if (!editor) {
    return null;
  }

  if (editor.isFocused) {
    if (setShowEmojiPicker && showEmojiPicker === true) {
      setShowEmojiPicker(false);
    }
  }

  const onKeyUpEventHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // cancelled
      if (targetUrl === null) {
        setLinkInputFieldVisible(false);
        return;
      }

      // empty
      if (targetUrl === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        setLinkInputFieldVisible(false);
        return;
      }

      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: checkLinkUrl(targetUrl) })
        .run();

      setTargetUrl("");
      setLinkInputFieldVisible(false);
    }
  };

  const onBlurEventHandler = () => {
    if (targetUrl === null) {
      setLinkInputFieldVisible(false);
      return;
    }

    // empty
    if (targetUrl === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      setLinkInputFieldVisible(false);
      return;
    }

    // update link
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: checkLinkUrl(targetUrl) })
      .run();

    setTargetUrl("");
    setLinkInputFieldVisible(false);
  };

  const selectEmoji = (data: any) => {
    editor.chain().setEmoji(data.id).run();
  };

  const parseMentions = (data: any) => {
    const mentions = (data.content || []).flatMap(parseMentions);
    if (data.type === "mention") {
      mentions.push(data.attrs.id);
    }
    const uniqueMentions = [...new Set(mentions)];

    return uniqueMentions;
  };

  const addAttachment = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.onchange = async () => uploadFile(input.files);
    input.click();
  };

  const uploadFile = async (filesArg: any) => {
    let urls: string[] = [];
    let paths: any = [];
    let notAllowedFileTypeFound = false;
    let files: any = Array.from(filesArg);

    /* refactoring needed in this point  */
    let imageCount = files.filter((ele: any) =>
      ["jpg", "jpeg", "png", "gif"].includes(ele.name.split(".").pop())
    ).length;
    /*************************************/

    if (files.length > 10) {
      return message.info(
        "Maximum of 10 files of 2MB each could be attached at a time",
        2
      );
    }

    if (files.some((ele: any) => ele.size / 1048576 > 2)) {
      message.info(
        "We found one or more file, that exceeds allowed file size ",
        2
      );
      files = files.filter((ele: any) => ele.size / 1048576 <= 2);
    }

    await files.forEach(async (element: any) => {
      let extension = element.name.split(".").pop();

      if (allowedFileExtensions.includes(extension)) {
        const storageRef = ref(
          storage,
          `/${threadId}/${cryptoRandomString({ length: 10 })}`
        );

        await uploadBytesResumable(storageRef, element);
        let downloadURL = await getDownloadURL(storageRef);

        urls.push(downloadURL);
        if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
          let obj = {
            type: "image",
            attrs: {
              src: downloadURL,
            },
          };
          paths.push(obj);
        } else {
          addToEditorField(extension, downloadURL, element.name, element.size);
        }
      } else {
        notAllowedFileTypeFound = true;
      }

      /* refactoring needed in this point  */
      if (imageCount === paths.length) {
        editor.chain().insertContent(paths).run();
        editor.commands.enter();
        editor.commands.focus("end");
      }
      /*************************************/
    });

    setAttachmentUrls(urls);
    if (notAllowedFileTypeFound) {
      message.info("We found one or more file, that are not allowed", 2);
    }
  };

  const addToEditorField = (
    extension: string,
    downloadURL: string,
    name: string,
    size: number
  ) => {
    let linkToAdd;

    linkToAdd = `<a href="${downloadURL}" target="_blank" class="tiptapExtensionElement ${
      extension === "txt" ? "txtFile" : extension
    }Element ${
      extension === "txt" ? "txtFile" : extension
    }">${name} ${formatFileSize(size)}</a>`;

    let editorHtml = editor.getHTML();

    if (editorHtml === `<p class="tiptapExtensionParagraph"></p>`) {
      editorHtml = linkToAdd;
    } else {
      editorHtml += linkToAdd;
    }

    editor.commands.setContent(editorHtml);
  };

  const getAttachmentUrl = (data: any) => {
    let content = data.content;
    let oldUrlList = [...attachmentUrls];
    let newUrls: string[] = [];
    let url: string;

    content.forEach((element: any) => {
      if (element.type === "image") {
        url = element.attrs.src;
        newUrls.push(element.attrs.src);
        let index = oldUrlList.findIndex((ele) => ele === url);
        oldUrlList.splice(index, 1);
        return;
      }

      let ele = element?.content;

      if (ele) {
        if (ele[0].hasOwnProperty("marks") && ele[0].marks[0].type === "link") {
          url = ele[0].marks[0].attrs.href;
          newUrls.push(url);
          let index = oldUrlList.findIndex((el) => el === url);
          oldUrlList.splice(index, 1);
        }
      }
    });

    oldUrlList.forEach((ele) => {
      const desertRef = ref(storage, ele);
      deleteObject(desertRef);
    });

    return newUrls;
  };

  function formatFileSize(bytes: number) {
    if (bytes < 0) {
      return "";
    }

    const units = ["bytes", "KB", "MB"];
    const factors = [1, 1024, 1024 * 1024];

    let index = 0;
    while (index < units.length - 1 && bytes >= factors[index + 1]) {
      index++;
    }

    let convertedSize: string | number = bytes / factors[index];
    if (convertedSize !== Math.round(convertedSize)) {
      convertedSize = (bytes / factors[index]).toFixed(2);
    }

    return `${convertedSize} ${units[index]}`;
  }

  const checkLinkUrl = (url: string) => {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      return url;
    }

    return `https://${url}`;
  };

  const isSendable = (text: string) => {
    console.log(text);
    if (text === "") {
      return false;
    }

    if (/<p class="tiptapExtensionParagraph">\s*<\/p>/.test(text)) return false;

    return true;
  };

  return (
    <Fragment>
      {linkInputFieldVisible ? (
        <input
          value={targetUrl}
          onChange={(e) => setTargetUrl(e.target.value)}
          onKeyUp={(e) => onKeyUpEventHandler(e)}
          onBlur={() => onBlurEventHandler()}
          autoFocus
        />
      ) : null}
      <div className="emojiMsg d-flex">
        <div className="leftActions d-flex">
          {allowedExtensions?.showBoldOutlined && (
            <Tooltip
              title="Bold (ctrl + B)"
              placement="bottom"
              arrow={false}
              color={"#2E364C"}
            >
              <BoldOutlined
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive("bold") ? "is-active" : ""}
              />
            </Tooltip>
          )}

          {allowedExtensions?.showItalicOutlined && (
            <Tooltip
              title="Italic (ctrl + I)"
              placement="bottom"
              arrow={false}
              color={"#2E364C"}
            >
              <ItalicOutlined
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive("italic") ? "is-active" : ""}
              />
            </Tooltip>
          )}

          {allowedExtensions?.showUnorderedListOutlined && (
            <Tooltip
              title="Bulleted List (ctrl + shift + 8)"
              placement="bottom"
              arrow={false}
              color={"#2E364C"}
            >
              <UnorderedListOutlined
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive("bulletList") ? "is-active" : ""}
              />
            </Tooltip>
          )}

          {allowedExtensions?.showCheckList && (
            <Tooltip
              title="Check List (ctrl + shift + 9)"
              placement="bottom"
              arrow={false}
              color={"#2E364C"}
            >
              <span
                className={`cmnIcon checkList ${
                  editor.isActive("taskList") ? "is-active" : ""
                }`}
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                onKeyDown={() => {}}
              ></span>
            </Tooltip>
          )}

          {allowedExtensions?.showEmojiControl && (
            <Popover
              placement="bottomRight"
              trigger={"click"}
              content={
                <Picker
                  data={data}
                  onEmojiSelect={selectEmoji}
                  theme="dark"
                  locale="en"
                />
              }
              open={showEmojiPicker}
              overlayClassName={"messageThreadClass"}
              arrow={false}
            >
              <Tooltip
                title="Emoji"
                placement="bottom"
                arrow={false}
                color={"#2E364C"}
              >
                <span
                  className={`cmnIcon emoji ${
                    showEmojiPicker ? "is-active" : ""
                  }`}
                  onClick={() => {
                    if (setShowEmojiPicker) {
                      setShowEmojiPicker(!showEmojiPicker);
                    }
                  }}
                  onKeyDown={() => {}}
                ></span>
              </Tooltip>
            </Popover>
          )}

          {allowedExtensions?.showUploadOutlined && (
            <Tooltip
              title="Attach file"
              placement="bottom"
              arrow={false}
              color={"#2E364C"}
            >
              <UploadOutlined onClick={() => addAttachment()} />{" "}
            </Tooltip>
          )}
          {allowedExtensions?.showLinkOutlined && (
            <Tooltip
              title="Link"
              placement="bottom"
              arrow={false}
              color={"#2E364C"}
            >
              <LinkOutlined onClick={() => setLinkInputFieldVisible(true)} />
            </Tooltip>
          )}
        </div>
        {allowedExtensions?.showSend && (
          <div
            className={`rightAction ${
              editor.getHTML() === `<p class="tiptapExtensionParagraph"></p>`
                ? "sendButtonDisabled"
                : "sendButtonEnabled"
            }`}
            onClick={() => {
              editor.commands.focus("end");
              editor.commands.enter();
              editor.commands.enter();
              if (
                isSendable(
                  editor
                    .getHTML()
                    .replaceAll('<p class="tiptapExtensionParagraph"></p>', "")
                )
                // editor
                //     .getHTML()
                //     .replaceAll('<p class="tiptapExtensionParagraph"></p>', "") !== ""
              ) {
                let actualAttachment = getAttachmentUrl(editor.getJSON());
                sendMessage(
                  editor.getHTML(),
                  parseMentions(editor.getJSON()),
                  actualAttachment
                );
                if (setShowEmojiPicker) {
                  setShowEmojiPicker(false);
                }
                setAttachmentUrls([]);
                editor.commands.clearContent();
              } else {
                console.log("empty");
                editor.commands.clearContent();
              }
            }}
            onKeyDown={() => {}}
          >
            <span className="cmnIcon send" />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default EditorControls;
